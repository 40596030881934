import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { logOut } from 'actions/authentication';
import Icon from 'atoms/icon';
import { setMobileMenuStatus } from 'store/reducers/ui';

function MobileItems() {
  const dispatch = useDispatch();
  const { token, data } = useSelector((state) => state.user);
  const { isMobileMenuOpen } = useSelector((state) => state.ui);
  const { activePet } = useSelector((state) => state.petAppointments);

  const closeMobileMenu = () => {
    dispatch(setMobileMenuStatus({ status: !isMobileMenuOpen }));
  };

  useEffect(() => {
    if (isMobileMenuOpen) document.body.style.overflow = 'hidden';
    if (!isMobileMenuOpen) document.body.style.overflow = 'unset';
  }, [isMobileMenuOpen]);

  return (
    <div
      className={classNames(
        'mobile-burger',
        isMobileMenuOpen && 'mobile-burger--open',
      )}
    >
      {!isMobileMenuOpen ? (
        <Icon
          name="burger"
          style={{ fill: 'black' }}
          onClick={closeMobileMenu}
        />
      ) : (
        <Icon name="close" fill="white" onClick={closeMobileMenu} />
      )}
      <div
        className={classNames(
          'mobile-burger__container',
          isMobileMenuOpen ? 'mobile-burger__container--open' : '',
        )}
      >
        <div className="mobile-burger__container-items">
          <Link
            onClick={closeMobileMenu}
            to={localStorage.getItem('homepageUrl')}
          >
            Book appointment
          </Link>
          {!token && (
            <>
              <Link
                onClick={closeMobileMenu}
                className="login-button"
                to="/login"
              >
                Log in
              </Link>
              <Link onClick={closeMobileMenu} to="/signup">
                Sign up
              </Link>
            </>
          )}
          {token && (
            <Link
              onClick={closeMobileMenu}
              to={`/user/${data.uuid}/pet/${activePet.uuid}`}
            >
              Profile
            </Link>
          )}
          {token && <button onClick={() => logOut(dispatch)}>Log out</button>}
          <a href="https://try.mypawcare.com/">For groomers</a>
          <a href="https://blog.mypawcare.com/pawcare-for-pet-parents">Blog</a>
        </div>
      </div>
    </div>
  );
}

export default MobileItems;
