import './Toggle.scss';

import { Switch } from '@headlessui/react';
import PropTypes from 'prop-types';

export default function Toggle({ setState, enabled = false }) {
  const onToggle = (state) => {
    setState(state);
  };

  return (
    <Switch
      checked={enabled}
      onChange={onToggle}
      className={`switch switch--${enabled ? 'enabled' : ''}`}
    >
      <span aria-hidden="true" className={enabled ? 'enabled' : ''} />
    </Switch>
  );
}

Toggle.propTypes = {
  enabled: PropTypes.bool,
  setState: PropTypes.func,
};
