import { useSelector } from 'react-redux';
import classNames from 'classnames';
import dayjs from 'dayjs';
import useWindowDimensions from 'helpers/useWindowDimensions';

import { DateInput } from 'atoms/date-input';
import Icon from 'atoms/icon';

export default function DateFilter({ onChange, value, className }) {
  const { width } = useWindowDimensions();
  const isMobile = width < 600;
  const { appointmentDateMin } = useSelector((state) => state.main.search);

  const timeFormat = isMobile ? 'MMM DD, YYYY' : 'MMMM DD, YYYY';
  const onChangeValue = (el) => {
    onChange(el);
  };
  return (
    <div className={classNames('w-fit flex-row', className)}>
      <p className="ml-5">
        {dayjs(value || appointmentDateMin).format(timeFormat)}
      </p>
      <DateInput
        className="search-page-date-picker"
        minDate={appointmentDateMin}
        onChange={(value) => onChangeValue(value)}
        value={value || dayjs()}
      />
      <Icon
        name="arrow-down"
        className="ml-10"
        style={{ width: 9, height: 5 }}
      />
    </div>
  );
}
