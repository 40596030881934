import './InvoiceDetails.scss';

import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { CircularProgress } from '@mui/material';

import Tooltip from 'atoms/tooltip';

export default function InvoiceDetails({ invoice, policy, isLoading }) {
  const disclaimerMessage =
    policy?.price?.text ||
    "Prices may change at groomer's discretion based on size, condition of coat, length and animal temperament.";

  const bookingFeeTooltipText =
    'This helps us run our platform and offer services like payment processing.';

  if (isLoading) {
    return (
      <div className="mt-50 invoice-loader">
        <CircularProgress />
      </div>
    );
  }
  return (
    <>
      <div className="mt-15 w-100">
        {invoice?.transaction?.details?.map((transaction) => (
          <div
            className="flex-row justify-between mb-15"
            key={transaction.description}
          >
            <div className="flex-row">
              <p>{transaction.description}</p>
              {transaction.description === 'Booking fee' && (
                <Tooltip title={bookingFeeTooltipText}>
                  <InfoRoundedIcon sx={{ width: 18 }} className="ml-5" />
                </Tooltip>
              )}
            </div>
            <p>{transaction.price?.localized || 'TBD'}</p>
          </div>
        ))}
      </div>
      <div className="appointment-info-service-price w-100 flex-row justify-between mb-4">
        <p className="bold f18">Est. total</p>
        <h2 className="f18">{invoice?.total?.localized || 'TBD'}</h2>
      </div>
      <p className="f14 text-grey-dark italic mt-8 pb-15">
        {disclaimerMessage}
      </p>
    </>
  );
}
