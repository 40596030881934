import { useSelector } from 'react-redux';

import ListItem from 'organisms/ListItem';
import { capitalizeFirstLetter } from 'util';

export default function AllGroomers({ groomers, selectGroomer }) {
  const { locationUser } = useSelector((state) => state.requestAppointmentForm);

  if (groomers.length === 0) {
    return <p className="mb-20">No groomers were found for this provider</p>;
  }
  return (
    <ul className="no-style-ul flex row">
      <ListItem
        title="Any groomer"
        avatar={null}
        id="any"
        onClick={selectGroomer}
        isActive={locationUser === 'any'}
      />
      {groomers.map((groomer) => (
        <ListItem
          key={groomer.user.uuid}
          title={capitalizeFirstLetter(groomer.user.firstName)}
          avatar={groomer.user?.links?.image?.desktop?.large}
          id={groomer.user?.uuid}
          onClick={selectGroomer}
          item={groomer}
          isActive={locationUser.user?.uuid === groomer.user?.uuid}
        />
      ))}
    </ul>
  );
}
