import './Toast.scss';

import toastLib from 'react-hot-toast';

import Icon from 'atoms/icon';

const toast = ({ title, text }) => {
  toastLib((t) => (
    <div className="flex-row toast-component">
      <div className="flex-column">
        <p className="bold">{title}</p>
        <p>{text}</p>
      </div>
      <Icon name="close" fill="#fff" onClick={() => toastLib.dismiss(t.id)} />
    </div>
  ));
};

export default toast;
