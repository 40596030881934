/* eslint-disable react/jsx-props-no-spreading */
import './Button.scss';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Icon from 'atoms/icon';

export default function Button({
  size = 'large',
  variant = 'default',
  shape = 'round',
  text,
  className,
  icon,
  iconProps,
  type,
  to,
  iconPosition = 'end',
  customElement,
  displayLoaderIcon,
  disableLoadingState,
  disabled,
  ...props
}) {
  const { requestLoading } = useSelector((state) => state.ui);

  const onClick = (e) => {
    if (props.onClick) {
      e.stopPropagation();
      props.onClick();
    }
  };

  const buttonVariant = () => {
    if ((requestLoading && !disableLoadingState) || disabled) {
      if (variant === 'default') {
        return 'disabled';
      }
      if (variant === 'reverse') {
        return 'reverse-disabled';
      }
      if (variant === 'green') {
        return 'green-disabled';
      }
      if (variant === 'link') {
        return 'link-disabled';
      }
      return 'disabled';
    }

    return variant;
  };

  const Element = to ? Link : 'button';

  return (
    <Element
      type={type}
      className={classNames(
        'button flex-row',
        `button--${size}`,
        `button--${shape}`,
        `button--${buttonVariant()}`,
        className,
      )}
      onClick={onClick}
      to={to}
    >
      {icon && iconPosition === 'start' && (
        <Icon name={icon} className="mr-10" {...iconProps} />
      )}
      {customElement && iconPosition === 'start' && customElement}
      {text}
      {icon &&
        iconPosition === 'end' &&
        !displayLoaderIcon &&
        !requestLoading && (
          <Icon name={icon} className="ml-10" {...iconProps} />
        )}
      {displayLoaderIcon && requestLoading && <CircularProgress />}
      {customElement && iconPosition === 'end' && customElement}
    </Element>
  );
}

Button.propTypes = {
  size: PropTypes.oneOf(['large', 'medium', 'small', 'extra-small']),
  shape: PropTypes.oneOf(['round', 'square']),
  variant: PropTypes.oneOf([
    'default',
    'reverse',
    'reverse-white',
    'reverse-orange',
    'reverse-disabled',
    'disabled',
    'transparent',
    'green',
    'green-disabled',
    'white-outlined',
    'reverse-white-green',
    'light-gray',
    'link',
  ]),
  onClick: PropTypes.func,
  text: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.string,
  iconPosition: PropTypes.oneOf(['start', 'end']),
  iconProps: PropTypes.object,
  // Useful for "submit" type
  type: PropTypes.string,
  // Converts button into Link
  to: PropTypes.string,
  displayLoaderIcon: PropTypes.bool,
  disabled: PropTypes.bool,
};
