/* eslint-disable react/jsx-no-useless-fragment */
import './RequestAppointmentModal.scss';

import { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import paymentRedirectionMiddleware from 'helpers/paymentRedirection';

import { loadProviderLocation } from 'actions/providers';
import Button from 'atoms/button';
import { DateInput } from 'atoms/date-input';
import Dropdown from 'atoms/dropdown';
import Icon from 'atoms/icon';
import ServiceBlock from 'atoms/service-block';
import ModalCardsLoader from 'organisms/Loaders/ModalCardsLoader';
import { UserDetails } from 'organisms/UserDetails';
import { editForm } from 'store/reducers/requestAppointmentForm';
import { openRequestModal } from 'store/reducers/ui';
import { getOpenHours } from 'util';
import { weekdays } from 'util/constants';

export default function RequestAppointmentModal({
  isOpen,
  location: selectedLocation,
  disableFirstScreen,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [screen, setScreen] = useState(disableFirstScreen ? 2 : 1);
  const [location, setLocation] = useState();
  const [day, setDay] = useState();
  const [time, setTime] = useState();
  const selectedService = useSelector(
    (state) => state.requestAppointmentForm.service,
  );
  const { appointmentDateMin } = useSelector((state) => state.main.search);
  const canContinue = day && time && selectedService;

  useEffect(() => {
    if (selectedLocation) {
      dispatch(
        loadProviderLocation(selectedLocation?.uuid, [null, null, null]),
      ).then(({ success, data }) => {
        if (success) {
          setLocation(data.data);
        }
      });
    }
    return () => {
      setScreen(1);
      setDay();
      setTime();
    };
  }, []);

  const onSelectService = (service) => {
    dispatch(editForm({ key: 'service', value: service }));
    setScreen(2);
  };

  const openHours = () => {
    if (day) {
      const dayOfWeek = new Date(day).getDay();
      const nameOfDay = weekdays[dayOfWeek];
      const openHoursDay = location?.hours.filter(
        (hour) => hour.dow.value === nameOfDay,
      )[0];
      return getOpenHours(openHoursDay?.time) || [];
    }
    return [];
  };

  const disableValues = (date) => {
    const openDays = location?.hours.map((hour) => hour.dow.id);
    const closedDays = [];
    weekdays.forEach((el, i) => {
      if (!openDays.includes(i)) {
        closedDays.push(i);
      }
    });
    return closedDays.includes(new Date(date).getDay());
  };

  const onRequestAppointment = () => {
    dispatch(editForm({ key: 'provider', value: location }));
    dispatch(
      editForm({ key: 'dateStart', value: `${day}T${time.valueFormatted}` }),
    );
    dispatch(editForm({ key: 'location', value: location?.uuid }));
    closeModal();
    dispatch(paymentRedirectionMiddleware(navigate));
  };

  const closeModal = () => {
    dispatch(openRequestModal(false));
  };

  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={closeModal}
      className="overflow-inherit"
    >
      <div
        className={classNames(
          'flex-row mb-30',
          screen === 1 ? 'justify-end' : 'justify-between',
        )}
      >
        {screen === 2 && (
          <Icon
            name="arrowLeft"
            style={{ width: 20, height: 20 }}
            onClick={() => setScreen(1)}
            className="back-button pointer"
          />
        )}
        <Icon
          name="close"
          style={{ width: 18, height: 18, fill: 'black' }}
          onClick={closeModal}
          className="pointer"
        />
      </div>
      <h2 className="mb-25">Request appointment</h2>
      <UserDetails item={location} />
      <div
        className={classNames(
          'mt-15 request-appointment-modal-services',
          screen === 1 &&
            'overflow-auto modal-services-height request-appointment-modal-services--mobile',
        )}
      >
        {!location ? (
          <ModalCardsLoader />
        ) : screen === 1 ? (
          <>
            {location?.services.map((service) => (
              <ServiceBlock
                actionsOnHover
                onSelectService={onSelectService}
                className="services-service"
                key={service.uuid}
                service={service}
                location={location}
              />
            ))}
          </>
        ) : (
          <>
            <ServiceBlock
              actionsOnHover
              onSelectService={onSelectService}
              className="services-service"
              disableSelect
              disableViewMore
              key={selectedService.uuid}
              service={selectedService}
              location={location}
            />
            <div className="flex-row responsive">
              <DateInput
                className="w-50 mr-10"
                placeholder="02/02/2020"
                minDate={appointmentDateMin}
                disableValues={disableValues}
                onChange={(value) => {
                  setDay(value);
                  setTime(null);
                }}
                value={day}
              />
              <div className="w-50">
                <Dropdown
                  generateKey
                  placeholder="Select a time"
                  className="w-100 mt-0"
                  items={openHours()}
                  disabled={!day}
                  disableDropdown={!day}
                  onClick={(value) => setTime(value)}
                  value={time}
                />
              </div>
            </div>
            <Button
              text="Request appointment"
              variant={canContinue ? 'default' : 'disabled'}
              className="mt-32"
              onClick={onRequestAppointment}
            />
          </>
        )}
      </div>
    </ReactModal>
  );
}
