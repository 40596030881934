function PawFilledReverse({ style }) {
  return (
    <svg width="54" height="54" viewBox="0 0 54 54" fill="none">
      <g clipPath="url(#clip0_5447_51597)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27 0C12.0883 0 0 12.0883 0 27C0 41.9117 12.0883 54 27 54C41.9117 54 54 41.9117 54 27C54 12.0883 41.9117 0 27 0ZM24.0741 20.3307C26.3245 20.6848 28.6518 18.427 29.2724 15.2876C29.893 12.1482 28.5718 9.31619 26.3215 8.96205C24.0711 8.6079 21.7438 10.8658 21.1232 14.0051C20.5026 17.1445 21.8238 19.9765 24.0741 20.3307ZM32.6912 24.258C34.723 25.2679 37.4969 23.7403 38.8868 20.846C40.2767 17.9517 39.7563 14.7867 37.7245 13.7768C35.6926 12.7669 32.9187 14.2946 31.5288 17.1889C30.1389 20.0832 30.6593 23.2482 32.6912 24.258ZM42.651 31.323C41.4155 33.8957 38.8845 35.2211 36.9978 34.2833C35.111 33.3456 34.5831 30.4998 35.8186 27.9271C37.0541 25.3543 39.5851 24.0289 41.4718 24.9667C43.3585 25.9044 43.8865 28.7502 42.651 31.323ZM14.4415 24.3096C16.4662 24.5065 18.4415 22.3849 18.8536 19.5709C19.2656 16.7569 17.9582 14.3161 15.9335 14.1192C13.9089 13.9224 11.9335 16.044 11.5215 18.858C11.1095 21.672 12.4168 24.1128 14.4415 24.3096ZM33.104 37.3566C32.2551 42.5895 30.0702 45.1307 27.2972 45.4652C26.2124 45.5961 24.9924 44.4094 23.6959 43.1484L23.6959 43.1484C22.6404 42.1218 21.5342 41.0459 20.4091 40.5913C19.2848 40.137 17.7463 40.1434 16.277 40.1495C14.4694 40.1569 12.7665 40.164 12.0684 39.3124C10.2978 37.1523 10.4997 33.7997 13.5241 29.4458C16.5485 25.0919 21.7805 21.6299 27.1874 23.8144C32.5942 25.9989 33.9529 32.1237 33.104 37.3566Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_5447_51597">
          <rect width="54" height="54" rx="27" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default PawFilledReverse;
