import { Helmet } from 'react-helmet-async';

import Checkout from 'organisms/Checkout';

import PetInfo from './PetInfo';

export default function PetSelect() {
  return (
    <>
      <Helmet title="Select pet for booking | PawCare" />
      <Checkout className="p-0">
        <PetInfo />
      </Checkout>
    </>
  );
}
