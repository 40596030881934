import ReactModal from 'react-modal';

import { formatHoursAndMinutes, serviceDetails } from 'util';

export default function ServiceViewMoreModal({ isOpen, openModal, service }) {
  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={() => openModal(false)}
    >
      <div className="flex-row align-center justify-between mb-15">
        <div className="flex-column">
          <h2 className="bold service-block-name">{service.name}</h2>
          <p className="f15">{serviceDetails(service)}</p>
          {service.durationMinutes && (
            <p className="italic text-grey-dark f15">{`Duration: ${formatHoursAndMinutes(service.durationMinutes)}`}</p>
          )}
        </div>
        <h2 className="bold service-block-price">{service.price?.localized}</h2>
      </div>
      <p>{service.description}</p>
    </ReactModal>
  );
}
