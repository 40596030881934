import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

function PrivateRoute({ children, adminCheck }) {
  const { token } = useSelector((state) => state.user);

  if (!token) {
    return <Navigate to={localStorage.getItem('homepageUrl')} />;
  }

  if (adminCheck) {
    if (process.env.REACT_APP_MODE !== 'development') {
      return <Navigate to={localStorage.getItem('homepageUrl')} />;
    }
  }
  return children;
}

export default PrivateRoute;
