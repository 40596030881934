import { createSlice } from '@reduxjs/toolkit';

export const SELECTED_PET_UUID_STORAGE_KEY = 'state:selected-pet-uuid';

const updateStoredSelectedPetUuid = (state) => {
  localStorage.setItem(SELECTED_PET_UUID_STORAGE_KEY, state);
};

const petAppointments = createSlice({
  name: 'petAppointments',
  initialState: {
    isLoading: false,
    pet: {},
    activePet: {},
    petAppointments: {
      active: [],
      past: [],
      requests: {
        general: [],
      },
    },
  },
  reducers: {
    petAppointmentsRequest(state) {
      state.isLoading = true;
    },
    petAppointmentsSuccess(state, { payload }) {
      state.petAppointments = payload.data;
      state.isLoading = false;
    },
    petAppointmentsFailure(state) {
      state.isLoading = false;
    },
    setActivePet(state, action) {
      state.activePet = action.payload;
      updateStoredSelectedPetUuid(action.payload?.uuid);
    },
    initializeActivePet(state, action) {
      state.activePet = action.payload;
    },
    loadPetSuccess(state, { payload }) {
      state.pet = payload.data;
    },
  },
});

const { actions, reducer } = petAppointments;

export const {
  petAppointmentsRequest,
  petAppointmentsSuccess,
  petAppointmentsFailure,
  setActivePet,
  initializeActivePet,
  loadPetSuccess,
} = actions;

export default reducer;
