function LargeAnimal({ style }) {
  const { width = 40, height = 56 } = style;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.14684 1.88226C6.74528 0.412761 5.20309 -0.229264 3.80448 0.0735537H3.80428C3.684 0.0995231 3.56509 0.132717 3.44852 0.172747C5.12796 1.73737 6.70583 5.09191 7.57374 9.09881C7.77701 10.038 7.93166 10.9665 8.03592 11.8739V11.8741C9.0173 10.9556 10.1022 10.1544 11.2686 9.4868C11.2882 9.20524 11.3294 8.92546 11.392 8.65013C11.3686 8.52751 11.344 8.40664 11.3184 8.28949C10.7621 5.71829 9.63589 3.44339 8.14684 1.88226Z"
        fill="white"
      />
      <path
        d="M31.6365 1.88224C30.1474 3.44332 29.0212 5.7181 28.4651 8.288C28.4264 8.46646 28.3907 8.64649 28.3579 8.82595L28.7832 9.64428V9.64408C29.8477 10.2801 30.8414 11.0275 31.7474 11.8741C31.8517 10.9668 32.0063 10.0383 32.2096 9.09888C33.0775 5.09217 34.6552 1.73739 36.3348 0.172813V0.172618C36.2182 0.13259 36.0995 0.0995928 35.979 0.0734249C34.5804 -0.229813 33.038 0.412593 31.6365 1.88213L31.6365 1.88224Z"
        fill="white"
      />
      <path
        d="M36.7281 39.1631C35.551 34.2993 35.2171 29.2698 35.741 24.2931L35.817 23.5693C35.5719 19.5245 33.7914 15.726 30.8394 12.9502C30.6344 12.7569 30.4245 12.5691 30.2097 12.3869L31.4941 14.8559C31.8942 15.6249 31.8639 16.5469 31.414 17.2881C30.9644 18.0291 30.1605 18.4817 29.2937 18.4817H22.9754C22.9458 18.4817 22.9171 18.4805 22.8884 18.479H22.8864C22.8524 18.4803 22.8185 18.4817 22.7843 18.4817H16.7144H16.7146C15.2672 18.4801 13.8795 17.9043 12.8559 16.881C11.8324 15.8574 11.2568 14.4698 11.2552 13.0223V11.1386C9.1439 12.4878 7.38243 14.3184 6.11512 16.4802C4.84807 18.6419 4.11117 21.0731 3.96531 23.5742C4.00709 24.0317 4.04849 24.4894 4.08949 24.9477C4.50188 29.4967 4.2002 34.0822 3.19519 38.538L3.05206 39.1695C3.02824 39.599 3.0216 40.0272 3.03175 40.4539C7.37547 35.497 13.4289 32.6885 19.8917 32.6885C26.3534 32.6885 32.4063 35.4971 36.7491 40.4534C36.7598 40.0271 36.7536 39.6009 36.7303 39.1744L36.7281 39.1631ZM12.6616 26.0761C12.6616 26.4643 12.3469 26.779 11.9587 26.779C11.5706 26.779 11.2558 26.4643 11.2558 26.0761V24.0782C11.2558 23.6901 11.5706 23.3753 11.9587 23.3753C12.3469 23.3753 12.6616 23.6901 12.6616 24.0782V26.0761ZM28.5273 26.0761C28.5273 26.4643 28.2125 26.779 27.8244 26.779C27.4362 26.779 27.1215 26.4643 27.1215 26.0761V24.0782C27.1215 23.6901 27.4362 23.3753 27.8244 23.3753C28.2125 23.3753 28.5273 23.6901 28.5273 24.0782V26.0761Z"
        fill="white"
      />
      <path
        d="M19.8916 34.0935C13.3904 34.0935 7.32607 37.135 3.20819 42.4492C4.37602 50.1951 11.1075 56 18.9844 56H20.7972C28.6726 56 35.4047 50.1956 36.5749 42.4502C32.4577 37.1351 26.3932 34.0935 19.8919 34.0935H19.8916ZM16.3411 46.5596C15.4386 47.3367 13.6867 46.7818 12.4281 45.3201C11.1694 43.8584 10.8805 42.0436 11.783 41.2665C12.6855 40.4893 14.4373 41.0443 15.6961 42.506C16.9546 43.9675 17.2434 45.7824 16.3411 46.5596ZM27.3556 45.3201C26.0967 46.7818 24.3449 47.3367 23.4424 46.5596C22.5399 45.7824 22.8289 43.9675 24.0875 42.506C25.3461 41.0444 27.0982 40.4893 28.0007 41.2665C28.903 42.0436 28.6142 43.8585 27.3556 45.3201Z"
        fill="white"
      />
      <path
        d="M2.18198 0.939758C0.284646 2.67114 -0.484495 6.47325 0.310789 10.4257C0.326214 10.5022 0.343202 10.5811 0.360189 10.6588C0.376591 10.7332 0.393383 10.8097 0.41154 10.8865C1.05162 13.6568 2.36941 16.0691 4.01076 17.5312C4.70332 15.965 5.62497 14.5107 6.74557 13.2158C6.65809 11.931 6.47552 10.6544 6.19941 9.39649C5.23817 4.95885 3.52436 1.91449 2.18198 0.939758Z"
        fill="white"
      />
      <path
        d="M37.6008 0.939733C36.2583 1.91407 34.5444 4.95874 33.5834 9.39643V9.39623C33.3073 10.6541 33.1249 11.9307 33.0372 13.2155H33.0374C34.1578 14.5104 35.0795 15.9647 35.772 17.5307C37.4138 16.0678 38.7314 13.6562 39.3709 10.8885C39.3894 10.81 39.406 10.7345 39.4226 10.6589C39.4396 10.581 39.4564 10.5027 39.472 10.4254C40.2675 6.47315 39.4982 2.67098 37.6008 0.939453L37.6008 0.939733Z"
        fill="white"
      />
      <path
        d="M27.0942 9.4442C26.9044 9.10855 26.7275 8.75998 26.5682 8.40873C26.5635 8.39838 26.559 8.38784 26.5549 8.37729H26.5547C26.2581 7.6199 25.7394 6.96988 25.0666 6.51255C24.3938 6.05525 23.5985 5.81214 22.785 5.81509H16.7152C15.6407 5.81626 14.6105 6.24368 13.8505 7.00362C13.0908 7.76336 12.6633 8.79358 12.6622 9.86809V13.0225C12.6633 14.0972 13.0908 15.1274 13.8505 15.8871C14.6104 16.6469 15.6406 17.0743 16.7152 17.0757H22.785C22.7999 17.0757 22.8147 17.0751 22.8293 17.0745L22.8778 17.0731C22.8828 17.0729 22.8881 17.0729 22.8934 17.0729C22.9035 17.0729 22.9139 17.0731 22.924 17.0735L22.9406 17.0743C22.9523 17.0749 22.9641 17.0757 22.9758 17.0757H29.2941L29.2943 17.0755C29.6697 17.0755 30.0181 16.8796 30.213 16.5586C30.4076 16.2376 30.4209 15.8381 30.2475 15.505L27.0942 9.4442Z"
        fill="white"
      />
    </svg>
  );
}

export default LargeAnimal;
