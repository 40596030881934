import { useSelector } from 'react-redux';
import useWindowDimensions from 'helpers/useWindowDimensions';

import Button from 'atoms/button';
import TermsLabel from 'organisms/AddNewCard/TermsLabel';
import { DefaultPaymentMethod } from 'organisms/DefaultPaymentMethod';
import { isNotEmptyArray } from 'util';

export function DefaultCard({
  onChangePaymentMethod,
  onSubmit,
  primaryButtonText,
  hideDiscount,
  hasSignedWaiver,
}) {
  const { width } = useWindowDimensions();
  const isMobile = width <= 600;

  return (
    <>
      <div className="mobile-page-container">
        <div className="flex-row justify-between mb-15">
          <p className="bold f18">Default payment method</p>
          <button
            className="text-orange pointer f14 no-style-btn"
            onClick={onChangePaymentMethod}
          >
            Change
          </button>
        </div>
        <DefaultPaymentMethod hideDiscount={hideDiscount} />
        {isMobile && <TermsLabel hasSignedWaiver={hasSignedWaiver} />}
      </div>
      <div className="mobile-main-action-btn">
        <Button
          onClick={onSubmit}
          className="mt-32 w-100"
          size="medium"
          text={primaryButtonText}
          variant={hasSignedWaiver ? 'default' : 'disabled'}
        />
        {!isMobile && <TermsLabel hasSignedWaiver={hasSignedWaiver} />}
      </div>
    </>
  );
}
