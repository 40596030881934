import './TermsOfService.scss';

import { Link } from 'react-router-dom';

import Section from './Section';

/* eslint-disable react/no-unescaped-entities */
export default function TermsOfService() {
  return (
    <div className="p-50 terms-service">
      <div className="terms-service-container">
        <h1>TERMS OF SERVICE</h1>
        <p className="bold">Last updated: January 30th, 2024</p>
        <h3 className="mb-20 mt-30">PawCare Terms of Service</h3>
        <p>
          This Terms of Service ("TOS") is a legal agreement between you
          ("User," "you," or "your") and PawCare ("we," "our," or "us"). By
          accessing or using the PawCare platform and its services, you agree to
          comply with and be bound by these TOS. If you do not agree with these
          terms, please do not use our platform.
        </p>
        <h3 className="mb-20 mt-30">1. Use of the PawCare Platform</h3>
        <Section title="1.1. Eligibility: ">
          You must be at least 18 years old or the legal age of majority in your
          jurisdiction to use PawCare. By using our platform, you represent and
          warrant that you meet this eligibility requirement. In addition, if
          you are a pet care provider using PawCare's services, you also affirm
          that you are a legitimate business entity providing pet care services.
        </Section>
        <Section title="1.2. User Accounts: ">
          You agree to provide accurate and up-to-date information when creating
          your PawCare account. You are responsible for maintaining the security
          of your account credentials.
        </Section>
        <Section title="1.3. User Obligations: ">
          You agree to use the PawCare platform in compliance with all
          applicable laws and regulations. You will not engage in any unlawful
          or fraudulent activities while using our services.
        </Section>
        <h3 className="mb-20 mt-30">2. Services for Pet Parents</h3>
        <Section title="2.1. Service Listings: ">
          PawCare allows pet care providers to list their services, pricing and
          calendar avaibility. Pet parents can book these services based on the
          listings.
        </Section>
        <Section title="2.2. Booking and Payments: ">
          When you book a service, you agree to pay the fees as specified in the
          service listing. PawCare may facilitate payments, but payment
          processing is handled by third-party providers. You acknowledge and
          agree to their terms.
        </Section>
        <Section title="2.3. Service Fee: ">
          Please note that PawCare charges a small service fee for using our
          platform. This fee is built into the total price of the service and
          may differ from the price displayed by the provider. The service fee
          helps us maintain and improve our platform for the benefit of our
          users.
        </Section>
        <Section title="2.4. No-Show Fee: ">
          In case of a no-show by the pet parent, a fee equal to 50% of the cost
          of the appointment, with a minimum charge of $50, will be applicable.
          This fee compensates pet care providers for their time and resources.
        </Section>
        <Section title="2.5. Cancellation Policy: ">
          If a booked service is canceled within 24 hours of the scheduled
          appointment, a cancellation fee of 50% of the cost of the appointment,
          with a minimum charge of $50, will be applied. This fee helps offset
          the impact on pet care providers who allocate time and resources for
          scheduled appointments.
        </Section>
        <Section title="2.6. Pet Care Provider's Obligations: ">
          Pet care providers are responsible for the services they offer.
          PawCare does not guarantee the quality or safety of these services.
        </Section>
        <Section title="2.7. Pricing Changes and Additional Fees: ">
          PawCare reserves the right to modify its pricing structure at any
          time. Pet care providers and pet parents will be notified of any
          changes to the pricing policies through the platform or via email. In
          addition, PawCare may introduce a booking fee in certain cases, and a
          small margin may be added to the service price displayed on the
          platform. These fees contribute to the operational costs associated
          with maintaining and enhancing the PawCare platform. It is the
          responsibility of users to review and acknowledge the updated pricing
          information.
        </Section>
        <h3 className="mb-20 mt-30">3. Services for Pet Care Providers</h3>
        <Section title="3.1. Service Listings: ">
          You, as a pet care provider, agree to provide accurate information in
          your service listings, including service descriptions, availability,
          and pricing.
        </Section>
        <Section title="3.2. Booking Confirmation: ">
          Pet care providers acknowledge that once a booking is made through
          PawCare, they are committed to honoring the booking and providing the
          services as described in their listing. Cancellations and rescheduling
          should adhere to the policies specified in the service listing.
        </Section>
        <Section title="3.3. Cancellations and Rescheduling: ">
          Providers can manage appointments through the PawCare provider portal
          at{' '}
          <Link to="https://provider.mypawcare.com">
            https://provider.mypawcare.com
          </Link>{' '}
          or by contacting our team at{' '}
          <a href="mailto:hello@mypawcare.com">hello@mypawcare.com</a> or{' '}
          <a href="mailto:bookings@mypawcare.com">bookings@mypawcare.com</a>.
          Ensuring timely updates to the availability calendar is crucial for
          maintaining a reliable service.
        </Section>
        <Section title="3.4. Platform Sustainability Margin: ">
          To support the continued operation and improvement of PawCare, a small
          margin may be added to the service price displayed on the platform.
          This margin is separate from the amount agreed upon with the pet care
          provider and is intended to cover the platform's operational expenses.
        </Section>
        <h3 className="mb-20 mt-30">4. Service Provider Expectations</h3>
        <p className="mb-20">
          PawCare works with pet care providers who offer their services on our
          platform. To ensure a seamless experience for our users, providers are
          expected to adhere to the following guidelines:
        </p>
        <Section title="4.1. Calendar Updates: ">
          Providers must keep their availability calendars on the PawCare
          platform updated to facilitate booking and scheduling.
        </Section>
        <Section title="4.2. Cost Disclosure: ">
          Providers should not disclose the costs they have agreed upon with
          PawCare to customers who use our platform. Pricing information for
          services offered through PawCare should be consistent with the
          platform's listings.
        </Section>
        <Section title="4.3. No Direct Marketing: ">
          Providers are prohibited from directly marketing their services to
          PawCare's customers outside of the PawCare platform, ensuring a
          consistent and secure experience for users.
        </Section>
        <Section title="4.4. Encouraging App Usage: ">
          Providers should encourage PawCare's customers to book their services
          through the PawCare app for a streamlined and secure experience.
        </Section>
        <p>
          Violation of these expectations is considered a breach of our policy
          and may result in appropriate actions, as outlined in our Terms of
          Service.
        </p>
        <h3 className="mb-20 mt-30">5. Privacy and Data Use</h3>
        <Section title="5.1. Privacy Policy: ">
          Your use of PawCare is also governed by our Privacy Policy, available
          at{' '}
          <Link to="https://mypawcare.com/privacy.html">
            https://mypawcare.com/privacy
          </Link>
          . By using PawCare, you consent to the collection, use, and sharing of
          your personal information as described in the Privacy Policy.
        </Section>
        <h3 className="mb-20 mt-30">6. Intellectual Property</h3>
        <Section title="6.1. Ownership: ">
          PawCare retains all intellectual property rights associated with the
          platform, including its logo, content, and software.
        </Section>
        <h3 className="mb-20 mt-30">7. Termination</h3>
        <Section title="7.1. Grounds for Termination: ">
          We reserve the right to terminate or suspend your account if you
          engage in any of the following actions, including but not limited to:
        </Section>
        <ol>
          <li className="mb-20">Violation of these TOS.</li>
          <li className="mb-20">
            Unlawfuo or fraudulent activities on the PawCare platform.
          </li>
          <li className="mb-20">
            Repeated instances of late cancellations or no-shows for booked
            services.
          </li>
          <li className="mb-20">
            Disruptive or disrespectful behavior towards other users or pet care
            providers.
          </li>
          <li className="mb-20">
            Any activity that poses a risk to the safety or well-being of users
            or pet care providers.
          </li>
          <li className="mb-20">
            Misuse or abuse of our platform, including any fraudulent or harmful
            conduct.
          </li>
          <li className="mb-20">Providing false or misleading information.</li>
        </ol>
        <Section title="7.2. Notice of Termination: ">
          If we decide to terminate or suspend your account, we will provide you
          with reasonable notice whenever possible, unless the situation
          necessitates immediate action for the safety or security of our users
          or the platform.
        </Section>
        <p className="mb-20">
          These additions provide more clarity about the reasons for account
          termination, which can help users better understand the consequences
          of their actions. Please consult with legal counsel to ensure that
          these changes align with your platform's legal requirements.
        </p>
        <Section title="7.3. Notice of Termination and Fee Adjustments: ">
          If we decide to terminate or suspend your account, we will provide you
          with reasonable notice whenever possible, unless the situation
          necessitates immediate action for the safety or security of our users
          or the platform. Additionally, any outstanding fees or adjustments
          related to pricing changes will be communicated during the termination
          notice.
        </Section>
        <p>
          These additions integrate the information seamlessly into the existing
          sections, providing clarity on pricing changes and additional fees
          while ensuring users are informed and consenting to any modifications.
          Please review these updates with your legal team to ensure alignment
          with your platform's legal requirements and communication strategy.
        </p>
        <h3 className="mb-20 mt-30">8. Limitation of Liability</h3>
        <Section title="8.1. Disclaimer: ">
          We provide the PawCare platform "as is" and make no warranties or
          representations regarding its quality or fitness for a particular
          purpose.
        </Section>
        <h3 className="mb-20 mt-30">9. Governing Law</h3>
        <Section title="9.1. Jurisdiction: ">
          These TOS are governed by the laws of CA. Any disputes arising from
          these TOS will be subject to the exclusive jurisdiction of the CA
          courts.
        </Section>
        <p>
          Certainly, to address the protection of pet care providers from
          liabilities arising from pet parents, I recommend adding a new
          section:
        </p>
        <h3 className="mb-20 mt-30">
          10. Provider Liability and Indemnification
        </h3>
        <Section title="10.1. Limitation of PawCare's Liability: ">
          PawCare does not assume any liability for the actions, behavior, or
          well-being of pets under the care of pet care providers using our
          platform. Pet care providers acknowledge that they are solely
          responsible for the services they offer, and PawCare is not a party to
          any agreements between providers and pet parents.
        </Section>
        <Section title="10.2. Indemnification: ">
          Pet care providers agree to indemnify and hold PawCare harmless from
          any claims, damages, liabilities, or expenses (including attorneys'
          fees) arising from disputes with pet parents or any third parties
          related to the services provided. This indemnification extends to any
          claims regarding the quality, safety, or outcomes of the services
          rendered by the provider.
        </Section>
        <Section title="10.3. No Intervention in Disputes: ">
          PawCare will not intervene in disputes between pet care providers and
          pet parents. Any disagreements or issues regarding the services
          provided should be resolved directly between the parties involved.
        </Section>
        <h3 className="mb-20 mt-30">11. Dispute Resolution</h3>
        <Section title="11.1. Mediation: ">
          In the event of a dispute between a pet care provider and a pet
          parent, both parties agree to attempt to resolve the matter through
          mediation before pursuing legal action.
        </Section>
        <Section title="11.2. PawCare's Limited Role: ">
          PawCare's role is limited to providing a platform for facilitating
          connections between pet care providers and pet parents. PawCare does
          not act as an arbitrator, mediator, or adjudicator in disputes and is
          not liable for any resolutions or lack thereof.
        </Section>
        <p>
          These additions emphasize that PawCare does not assume responsibility
          for the actions or outcomes of pet care providers and reinforces the
          platform's role as a facilitator rather than an active participant in
          provider-pet parent relationships. Please consult with your legal team
          to ensure these additions align with your platform's legal
          requirements and preferences.
        </p>
        <h3 className="mb-20 mt-30">
          12. Grooming Safety and Well-being of Pets
        </h3>
        <Section title="12.1. Commitment to Pet Safety: ">
          Our groomers prioritize the safety and well-being of all pets during
          grooming sessions. If, during an appointment, a pet exhibits
          aggression, behavioral issues, or any other concerns that prevent
          completion of the grooming, the pet parent will still be charged for
          the services performed. Minor injuries, such as nicks from grooming
          tools, may occur if the pet does not remain still.
        </Section>
        <Section title="12.2. Disclosure of Medical and Behavioral Issues: ">
          Pet parents are responsible for informing groomers of any medical
          conditions or behavioral concerns their pets may have, including
          allergies, epilepsy, diabetes, arthritis, separation anxiety, or
          asthma. Pet parents assume all liabilities for their pet's behavior
          and health during grooming sessions.
        </Section>
        <Section title="12.3. Anal Gland Expression: ">
          Groomers perform external expression of anal glands. If persistent
          irritation occurs, pet parents are advised to consult with their
          veterinary staff.
        </Section>
        <Section title="12.4. Aggravation of Medical Conditions: ">
          Grooming may occasionally exacerbate existing medical conditions. Pet
          parents waive any liability for skin irritations, stress, or problems
          related to neglected coats or integuments arising during or after
          grooming.
        </Section>
        <Section title="12.5. Behavior/Temperament: ">
          In cases of anxiety or aggression preventing completion of grooming,
          the scheduled service will still be charged. However, if a pet is
          challenging to groom but can be worked with to build confidence, we
          embrace the challenge and provide tips for improvement. A temperament
          fee may apply in such cases.
        </Section>
        <h3 className="mb-20 mt-30">13. Acceptance of Terms</h3>
        <p className="mb-20">
          By signing this agreement, I acknowledge and accept the following
          terms:
        </p>
        <Section title="Pet's Health: " />
        <p className="mb-20">
          I understand that it is my responsibility to disclose any preexisting
          medical conditions or behavioral issues that my pet may have.
        </p>
        <Section title="Pre Existing Conditions: " />
        <p className="mb-20">
          I acknowledge that I am aware of any preexisting medical conditions
          that my pet may have, and I have disclosed this information to the
          groomers.
        </p>
        <Section title="Rabies Vaccination: " />
        <p className="mb-20">
          I confirm that my pet is up to date with their rabies vaccination.
        </p>
        <Section title="Emergency Medical Consent: " />
        <p className="mb-20">
          In the event of a medical emergency during grooming hours, I authorize
          PawCare and the pet care provider business to perform any necessary
          medical treatments to stabilize and/or treat my pet. I understand that
          initial treatments may range from $300 to $950, and I will be
          contacted for further consent if additional treatment is required.
        </p>
        <Section title="Medical Consent: " />
        <p className="mb-20">
          I release PawCare, its providers, groomers, owners, and affiliates
          from any liabilities, expenses, and costs resulting from any service
          provided or unintentional injury to my pet while under their care or
          afterward. I grant permission to groomers associated with PawCare to
          groom my pet(s).
        </p>
        <Section title="Provider Specific Terms: " />
        <p className="mb-20">
          I acknowledge that I’ve read through any provider specific terms of
          service on their website and I consent to the same.
        </p>
        <h3 className="mb-20 mt-30">14. Contact Information</h3>
        <Section title="14.1. Contact Us: ">
          If you have any questions or concerns regarding these TOS, please
          contact us at{' '}
          <a href='mailto:"hello@mypawcare.com"'>hello@mypawcare.com</a>.
        </Section>
      </div>
    </div>
  );
}
