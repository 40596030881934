import './Checkout.scss';
import 'organisms/Landing/Landing.scss';

import useWindowDimensions from 'helpers/useWindowDimensions';

import { InfoBlock } from 'atoms/info-block';

import AppointmentInfo from './AppointmentInfo';

export default function Checkout({
  children,
  mobileTitle,
  mobileSubtitle,
  hideInvoice = true,
  showProgressBar,
  progressBarData,
}) {
  const { width } = useWindowDimensions();
  const isMobile = width < 600;

  if (hideInvoice && isMobile)
    return (
      <div className="flex-row w-100 checkout-container">
        <div className="page-container checkout">{children}</div>
      </div>
    );

  return (
    <div className="flex-row w-100 checkout-container">
      <InfoBlock>
        <AppointmentInfo
          showProgressBar={showProgressBar}
          progressBarData={progressBarData}
          mobileTitle={mobileTitle}
          mobileSubtitle={mobileSubtitle}
        />
      </InfoBlock>
      <div className="page-container checkout">{children}</div>
    </div>
  );
}
