import './ListItem.scss';

import classNames from 'classnames';

import DefaultAvatar from 'atoms/default-avatar';

export default function ListItem({
  subtitle,
  avatar,
  id,
  onClick,
  title,
  item,
  isActive,
  isDisabled,
}) {
  return (
    <li key={id} className="mb-10">
      <button
        onClick={() => onClick(item)}
        className={classNames(
          'no-style-btn w-100 flex-row pointer list-item',
          isActive && 'list-item--active',
          isDisabled && 'list-item--disabled',
        )}
        disabled={isDisabled}
      >
        <DefaultAvatar size="small" className="mr-15" avatar={avatar} />
        <div className="flex-column align-start text-left">
          <h4 className="f15">{title}</h4>
          <p>{subtitle}</p>
        </div>
      </button>
    </li>
  );
}
