import './ConsumerLandingDeepLink.scss';

import { Link } from 'react-router-dom';

import Button from 'atoms/button';
import Icon from 'atoms/icon';
import TradeMark from 'atoms/trade-mark';
import LandingImage from 'images/landing-page-deep-link.png';

function ConsumerLandingDeepLink() {
  return (
    <div className="consumer-landing-deep-link">
      <div className="flex-column">
        <div className="consumer-landing-deep-link-nav">
          <Icon
            name="pawcare"
            className="logo"
            tyle={{ variant: '#00B383', width: 180 }}
          />
          <Button
            to="https://provider.mypawcare.com"
            size="medium"
            variant="transparent"
            text="Provider portal"
          />
        </div>
        <div className="flex-row consumer-landing-deep-link-content">
          <img
            src={LandingImage}
            alt="decorative"
            className="consumer-landing-deep-link-content__image"
          />
          <div className="flex-column consumer-landing-deep-link-content__info">
            <h1>To view your appointment details please open your app</h1>
            <p>
              Currently all appointment management happens on your Android app.
            </p>
            <div className="flex-row mt-25 actions">
              <Link
                to="https://apps.apple.com/app/pawcare/id6446097633"
                className="mr-15"
              >
                <Icon name="apple-store" />
              </Link>
              <Link to="https://play.google.com/store/apps/details?id=com.mypawcare">
                <Icon name="google-play" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConsumerLandingDeepLink;
