import './SearchBar.scss';

import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Input from 'atoms/input';
import { trackUserSearch } from 'util/tracking/events';

export default function SearchBar({
  placeholder = 'Search services or providers',
  onSearch,
  value,
  className,
  variant = 'white',
  size = undefined,
}) {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const onKeyDown = (str) => {
    dispatch(trackUserSearch());
    onSearch(str);
  };

  return (
    <div className={classNames(className, `searchbar-${variant}`)}>
      <Input
        placeholder={placeholder}
        icon="search"
        iconColor={variant === 'transparent' ? 'white' : 'grey'}
        onKeyDown={(str) => onKeyDown(str)}
        value={value || searchParams.get('query')}
        displaySubmitButton={variant === 'transparent'}
        submitButtonProps={{
          text: 'Search',
          shape: 'round',
          size: 'small',
          variant: 'default',
        }}
        autoComplete="off"
        size={size}
      />
    </div>
  );
}

SearchBar.propTypes = {
  className: PropTypes.string,
  onSearch: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  variant: PropTypes.string,
};
