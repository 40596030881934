/* eslint-disable camelcase */
import { mobileGroomerId } from 'util/constants';

export default function paymentRedirectionMiddleware(navigate) {
  return (dispatch, getState) => {
    const { token } = getState().user;
    const { service } = getState().requestAppointmentForm;
    const isExperiment = null; // No experiments atm. Leave so we can use in the future
    const isMobileService = service.type.id === mobileGroomerId;

    if (!token) {
      const paymentPage = isExperiment ? '' : '/signup/user-details';
      return navigate(paymentPage);
    }

    if (token) {
      if (isMobileService) return navigate('/signup/address?appointment=true');
      return navigate('/booking-payment');
    }
  };
}
