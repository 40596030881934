import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { loadBootstrap } from 'actions';
import { adminSignUp } from 'actions/authentication';
import { loadUser } from 'actions/user';
import Button from 'atoms/button';
import Input from 'atoms/input';
import daycareImage from 'images/daycare.webp';
import { requestFulfilled, requestOngoing } from 'store/reducers/ui';

export default function CreateAccount({ setAccountCreated }) {
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const canContinue = firstName && lastName && email;

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!canContinue) return;

    dispatch(requestOngoing());
    const userCreated = await dispatch(
      adminSignUp({ firstName, lastName, email }, null, [null, null, null]),
    );
    if (userCreated.success) {
      const { token: userToken, uuid: userUuid } = userCreated.data.data;

      sessionStorage.setItem('overrideLoginActions', true);
      localStorage.setItem('token', userToken);
      dispatch(loadBootstrap());

      // Load user
      await dispatch(loadUser(userUuid));
      setAccountCreated(true);
    }
    dispatch(requestFulfilled());
  };

  return (
    <>
      <img
        src={daycareImage}
        alt="decorative"
        className="daycare-modal-container-avatar"
      />
      <h2>Daycare coming soon</h2>
      <p>Sign up below and we’ll be in touch</p>
      <form onSubmit={onSubmit} className="w-100 mt-30 flex-column">
        <div className="flex-row mb-20">
          <Input
            id="firstName"
            label="First name"
            className="mr-20 w-100"
            onChange={(value) => setFirstName(value)}
            value={firstName}
            autoFocus
          />
          <Input
            id="lastName"
            label="Last name"
            className="w-100"
            onChange={(value) => setLastName(value)}
            value={lastName}
          />
        </div>
        <Input
          id="email"
          label="Email"
          type="email"
          onChange={(value) => setEmail(value)}
          value={email}
        />
        <Button
          text="Submit"
          type="submit"
          variant={!canContinue ? 'disabled' : 'default'}
        />
      </form>
    </>
  );
}
