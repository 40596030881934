import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DateCalendar } from '@mui/x-date-pickers';
import classNames from 'classnames';
import dayjs from 'dayjs';

import { newAppointmentFinder } from 'actions/appointments';
import { SlotsByDay } from 'organisms/SlotsByDay';
import {
  providerCalendarFailure,
  providerCalendarRequest,
  providerCalendarSuccess,
} from 'store/reducers/providerCalendar';
import { editForm } from 'store/reducers/requestAppointmentForm';
import { isEmpty, isNotEmptyArray } from 'util';
import { weekdays } from 'util/constants';

import { getMonthStartAndEndDate } from './util';

export default function SlotsCalendar({
  locationUuid,
  onSelectSlot,
  disablePetCreation,
  dateStart,
  displayHeader = true,
  className,
  ignoreLocationUser,
}) {
  const dispatch = useDispatch();
  const { locationUser, service } = useSelector(
    (state) => state.requestAppointmentForm,
  );
  const location = useSelector((state) => state.location);
  const { calendar, isLoading } = useSelector(
    (state) => state.providerCalendar,
  );
  const { hours, name } = location;
  const hasSetInitialDateStart = useRef(false);
  const [date, setDate] = useState(
    dateStart
      ? dayjs(dateStart).format('YYYY-MM-DD')
      : dayjs().format('YYYY-MM-DD'),
  );

  useEffect(() => {
    if (dateStart && !hasSetInitialDateStart.current) {
      setDate(dayjs(dateStart).format('YYYY-MM-DD'));
      hasSetInitialDateStart.current = true;
    }
  }, [dateStart]);

  const [monthDates, setMonthDates] = useState(getMonthStartAndEndDate(date));
  const availableSlots = calendar.filter((el) => el.date === date)[0];
  const openDays = hours?.map((day) => day.dow.value);
  const openDaysOfWeek = weekdays
    .map((day, i) => {
      if (openDays?.includes(day)) {
        return `${i}`;
      }
      return null;
    })
    .filter(Boolean);

  useEffect(() => {
    dispatch(
      newAppointmentFinder(
        {
          dateStart: monthDates.startDate,
          dateEnd: monthDates.endDate,
          luid: locationUuid,
          groomer: ignoreLocationUser ? null : locationUser.user?.uuid,
          suid: service?.uuid,
        },
        [
          providerCalendarRequest,
          providerCalendarSuccess,
          providerCalendarFailure,
        ],
      ),
    ).then(({ success, data }) => {
      if (!dateStart && success && !isEmpty(data)) {
        const calendar = data?.data?.calendar;
        if (isNotEmptyArray(calendar) && isNotEmptyArray(calendar[0].time)) {
          const nextAvailableDateStart = `${calendar[0].date}T${calendar[0].time[0]}`;
          dispatch(
            editForm({
              key: 'dateStart',
              value: nextAvailableDateStart,
            }),
          );
        }
      }
    });
  }, [monthDates, locationUuid]);

  const onChangeDate = (date) => {
    setDate(dayjs(date).format('YYYY-MM-DD'));
  };

  const onMonthChange = (date) => {
    const { startDate, endDate } = getMonthStartAndEndDate(
      dayjs(date).format('YYYY-MM-DD'),
    );
    onChangeDate(startDate);
    setMonthDates({ startDate, endDate });
  };

  const shouldDisableDate = (date) => {
    const formattedDate = dayjs(date).format('YYYY-MM-DD');
    const dayWithSlots = calendar.filter((el) => el.date === formattedDate)[0];
    return (
      !openDaysOfWeek.includes(`${new Date(date).getDay()}`) ||
      !dayWithSlots ||
      date.isBefore(new Date(), 'day')
    );
  };

  return (
    <>
      {displayHeader && (
        <div className="mb-25">
          <h2 className="f26">{name}</h2>
          <p>Select an appointment date and time</p>
        </div>
      )}
      <div className={classNames('flex-row align-start flex-wrap', className)}>
        <div className="calendar-view mr-30 mb-30">
          <DateCalendar
            value={dayjs(date)}
            onChange={onChangeDate}
            onMonthChange={onMonthChange}
            shouldDisableDate={shouldDisableDate}
          />
        </div>
        <div className="slots-by-day-container flex-row">
          <SlotsByDay
            isLoading={isLoading}
            startDate={date}
            key={locationUuid}
            item={location}
            selectSlot={onSelectSlot}
            availableSlots={availableSlots}
            displayDate={false}
            disablePetCreation={disablePetCreation}
          />
        </div>
      </div>
    </>
  );
}
