export const getLastAction = (appointment) => {
  const lastAction = Array.isArray(appointment.actions)
    ? appointment.actions[appointment.actions.length - 1]?.action
    : undefined;

  return lastAction;
};

export const isAppointmentCompleted = (appointment) => {
  const lastAction = getLastAction(appointment);
  return !!lastAction && lastAction?.value !== 'arrived';
};
