import { Route, Routes } from 'react-router-dom';

import AboutUs from 'pages/AboutUs';
import AddAddress from 'pages/AddAddress';
import AdditionalPetDetails from 'pages/AdditionalPetDetails';
import AdminSearch from 'pages/AdminSearch';
import AdminSignup from 'pages/AdminSignup';
import Blog from 'pages/Blog';
import BookingConfirmation from 'pages/BookingConfirmation';
import BookingSignup from 'pages/BookingSignup';
import ConsumerLandingDeepLink from 'pages/ConsumerLandingDeepLink';
import CreatePet from 'pages/CreatePet';
import FinishSignup from 'pages/FinishSignup';
import FinishSignupMFA from 'pages/FinishSignupMFA';
import GroomerSelect from 'pages/GroomerSelect';
import Home from 'pages/Home';
import LinkMobileRedirect from 'pages/LinkMobileRedirect';
import { LocationService } from 'pages/LocationService';
import Login from 'pages/Login';
import MFA from 'pages/MFA';
import NewPassword from 'pages/NewPassword';
import NotFound from 'pages/NotFound';
import Payment from 'pages/Payment';
import PetProfile from 'pages/PetProfile';
import PetSelect from 'pages/PetSelect';
import PhoneVerification from 'pages/PhoneVerification';
import ProviderProfile from 'pages/ProviderProfile';
import { ProviderSlots } from 'pages/ProviderSlots';
import ResetPassword from 'pages/ResetPassword';
import Search from 'pages/Search';
import SettingsPage from 'pages/Settings';
import Signup from 'pages/Signup';
import SlotSelect from 'pages/SlotSelect';
import TermsOfService from 'pages/TermsOfService';
import UploadPetAvatar from 'pages/UploadPetAvatar';

import PrivateRoute from './PrivateRoute';

const getRoutes = () => (
  <Routes>
    {/* General. */}
    <Route path="*" element={<NotFound />} />
    <Route path="link" element={<LinkMobileRedirect />} />
    <Route path="terms" element={<TermsOfService />} />
    <Route path="openapp" element={<ConsumerLandingDeepLink />} />
    <Route path="about" element={<AboutUs />} />
    <Route path="blog" element={<Blog />} />

    {/* Admin */}
    <Route
      path="search-consumer"
      element={
        <PrivateRoute adminCheck>
          <AdminSearch />
        </PrivateRoute>
      }
    />
    <Route path="admin/_signup" element={<AdminSignup />} />

    {/* Home */}
    <Route path="/" element={<Home />} />
    <Route path="/advanced-search" element={<Home />} />

    {/* Regular signup */}
    <Route path="login" element={<Login />} />
    <Route path="signup" element={<Signup />} />
    <Route path="signup-mfa" element={<MFA />} />

    {/* Booking signup */}
    <Route path="signup/address" element={<AddAddress />} />
    <Route path="signup/finish-signup" element={<FinishSignup />}>
      <Route path=":code" element={<FinishSignup />} />
    </Route>
    <Route path="signup/phone-verification" element={<PhoneVerification />} />
    <Route path="signup/mfa" element={<FinishSignupMFA />} />
    <Route
      path="signup/additional-pet-details"
      element={<AdditionalPetDetails />}
    />

    {/* Password reset */}
    <Route path="trigger-password-reset" element={<ResetPassword />} />
    <Route path="password-reset" element={<NewPassword />}>
      <Route path=":code" element={<NewPassword />} />
    </Route>

    {/* Providers */}
    <Route path=":city/:type/:slug" element={<ProviderProfile />} />
    <Route path=":city/:type/:slug/:serviceId" element={<LocationService />} />
    <Route path="provider/:luid/slots" element={<ProviderSlots />} />
    <Route path="pet/groomers/chicago" element={<Search />}>
      <Route path=":neighborhood" element={<Search />} />
      <Route path="type/:providerType" element={<Search />} />
    </Route>

    {/* Create pet */}
    <Route path="create-pet" element={<CreatePet />} />
    <Route path="upload-avatar" element={<UploadPetAvatar />} />
    <Route path="user/:userId/pet" element={<PetProfile />}>
      <Route path=":petId" element={<PetProfile />} />
    </Route>

    {/* Logged in user checkout */}
    <Route path="booking-select-pet" element={<PetSelect />} />
    <Route path="booking-select-groomer" element={<GroomerSelect />} />
    <Route path="booking-select-slot" element={<SlotSelect />} />
    <Route path="booking-payment" element={<Payment />} />
    <Route path="booking-confirmation" element={<BookingConfirmation />} />

    {/* Signup checkout */}
    <Route path="signup/pet-details" element={<BookingSignup />} />
    <Route path="signup/user-details" element={<BookingSignup />} />
    <Route path="signup/booking-payment" element={<Payment />} />
    <Route
      path="signup/booking-confirmation"
      element={<BookingConfirmation />}
    />

    {/* Settings */}
    <Route path="settings" element={<SettingsPage />} />
  </Routes>
);

export default getRoutes;
