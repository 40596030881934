import { useDispatch } from 'react-redux';

import Dropdown from 'atoms/dropdown';
import { editForm } from 'store/reducers/createPetForm';

export default function PetFilter({ core, type }) {
  const dispatch = useDispatch();

  const dropdownItems = core.type.map(({ id, value }) => ({
    id,
    value: `${value === 'Dog' ? '🐶' : '🐱'} ${value}`,
  }));

  return (
    <Dropdown
      placeholder="Select animal type"
      className="pet-filter"
      size="small"
      shape="square"
      items={dropdownItems}
      onClick={(value) => dispatch(editForm({ key: 'type', value }))}
      value={type}
    />
  );
}
