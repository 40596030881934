/* eslint-disable default-param-last */
import dayjs from 'dayjs';

import {
  hardInventoryFailure,
  hardInventoryRequest,
  hardInventorySuccess,
} from 'store/reducers/hardInventory';
import { requestFulfilled, requestOngoing } from 'store/reducers/ui';
import { addDays, getProviderType } from 'util';

import { fetchData } from './fetchData';

export const appointmentFinder =
  (data = {}, actions) =>
  (dispatch, getState) => {
    const { advancedSearch } = getState();
    const { location } = data;
    const latitude = data.lat || advancedSearch.latitude;
    const longitude = data.lng || advancedSearch.longitude;

    const today = data.dateStart || dayjs().format('YYYY-MM-DD');
    const radius = `radius=${data.radius || 25}`;
    const dateStart = `&date_start=${today}`;
    const dateEnd = `&date_end=${
      data.dateEnd || dayjs(addDays(today, 14)).format('YYYY-MM-DD')
    }`;
    const query = data.query ? `&query=${data.query}` : '';
    const includeAllProviders = data.includeAllProviders
      ? '&show_hi_only=False'
      : '';
    const lat = latitude ? `&latitude=${latitude}` : '';
    const lng = longitude ? `&longitude=${longitude}` : '';
    const providerType = getProviderType(data.providerType)
      ? `&provider_type=${getProviderType(data.providerType)}`
      : '';
    const time = data.displayAllTime
      ? ''
      : advancedSearch.time
        ? `&time_of_day=${advancedSearch.time}`
        : '';

    const queries = `${radius}${dateStart}${dateEnd}${includeAllProviders}${providerType}${
      location ? `&location_uuid=${location}` : query
    }${lat}${lng}${time}`;

    return fetchData({
      endpoint: `appointment/finder?${queries}`,
      actions: actions || [
        hardInventoryRequest,
        hardInventorySuccess,
        hardInventoryFailure,
      ],
      httpMethod: 'get',
    })(dispatch, getState);
  };

export const newAppointmentFinder =
  ({ dateStart, dateEnd, groomer, luid, suid }, actions) =>
  (dispatch, getState) => {
    const dateQuery = `?date_start=${dateStart}&date_end=${dateEnd}`;
    const serviceQuery = suid ? `&service_uuid=${suid}` : '';
    const userQuery = groomer ? `&user_uuid=${groomer}` : '';

    const queries = `${dateQuery}${serviceQuery}${userQuery}`;

    return fetchData({
      endpoint: `location/${luid}/availability/consumer${queries}`,
      actions,
      httpMethod: 'get',
    })(dispatch, getState);
  };

export const locationFinder =
  ({ postCode, breed, size, type }) =>
  (dispatch, getState) => {
    const state = getState();
    const { activePet } = state.petAppointments;

    // Use activePet values as fallbacks
    const fallbackBreed = activePet?.breed;
    const fallbackSize = activePet?.size;
    const fallbackType = activePet?.type;
    const breedId = breed?.id
      ? `&breed_id=${breed?.id}`
      : fallbackBreed
        ? `&breed_id=${fallbackBreed.id}`
        : '';
    const sizeId = size?.id
      ? `&size_id=${size?.id}`
      : fallbackSize
        ? `&size_id=${fallbackSize.id}`
        : '';
    const typeId = type?.id
      ? `&animal_type_id=${type.id}`
      : fallbackType
        ? `&animal_type_id=${fallbackType.id}`
        : '';

    // Construct the query string
    const queries = `${typeId}&postal_code=${postCode}${sizeId}${breedId}`;

    return fetchData({
      endpoint: `provider/location/finder?${queries}`,
      actions: [null, null, null],
      httpMethod: 'get',
    })(dispatch, getState);
  };

export const localitySearch =
  ({ service = 'groomers', market = 'chicago', locality = 'all' }) =>
  (dispatch, getState) =>
    fetchData({
      endpoint: `search/market/${service}/${market}/${locality}`,
      actions: [
        hardInventoryRequest,
        hardInventorySuccess,
        hardInventoryFailure,
      ],
      httpMethod: 'get',
    })(dispatch, getState);

export const requestAppointment = () => (dispatch, getState) => {
  const { location, dateStart, service, locationUser } =
    getState().requestAppointmentForm;
  const { promotionCode } = getState().invoice;
  const state = getState();
  // UUID fix for new users
  let animalUuid;

  const { activePet } = state.petAppointments;
  const userAnimals = state.user.data.animals;
  const advancedSearchPetUuid = state.advancedSearch.petUuid;

  if (activePet && activePet.uuid) {
    animalUuid = activePet.uuid;
  } else if (userAnimals?.length) {
    animalUuid = userAnimals[0].uuid || advancedSearchPetUuid;
  }

  return fetchData({
    endpoint: 'appointment/consumer',
    actions: [null, null, null],
    httpMethod: 'post',
    body: {
      uuid: {
        animal: animalUuid,
        location,
        service: service?.uuid,
        locationUser: locationUser.user.uuid,
      },
      dateStart: `${dateStart}.000Z`,
      note: '',
      promotionCode,
    },
  })(dispatch, getState);
};

export const getAppointment = (appointmentId) => (dispatch, getState) =>
  fetchData({
    endpoint: `appointment/${appointmentId}/consumer`,
    actions: [null, null, null],
    httpMethod: 'get',
  })(dispatch, getState);

export const cancelAppointment = (appointmentId) => (dispatch, getState) =>
  fetchData({
    endpoint: `appointment/${appointmentId}/consumer`,
    actions: [null, null, null],
    httpMethod: 'delete',
  })(dispatch, getState);

export const patchAppointment = (appointmentId, body) => (dispatch, getState) =>
  fetchData({
    endpoint: `appointment/${appointmentId}/consumer`,
    actions: [null, null, null],
    httpMethod: 'patch',
    body,
  })(dispatch, getState);

export const makeGeneralRequest = (body) => (dispatch, getState) =>
  fetchData({
    endpoint: 'appointment/consumer/general/request',
    actions: [null, null, null],
    httpMethod: 'post',
    body,
  })(dispatch, getState);

export const patchGeneralRequest =
  (body, appointmentId) => (dispatch, getState) =>
    fetchData({
      endpoint: `appointment/consumer/general/request/${appointmentId}`,
      actions: [requestOngoing, requestFulfilled, requestFulfilled],
      bypassImpersonation: true,
      httpMethod: 'patch',
      body,
    })(dispatch, getState);
