import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import petCreationMiddleware from 'helpers/petCreation';
import useWindowDimensions from 'helpers/useWindowDimensions';

import { appointmentFinder } from 'actions/appointments';
import ProviderCard from 'atoms/provider-card';
import HomeLoader from 'organisms/Loaders/HomeLoader';
import HomeLoaderMobile from 'organisms/Loaders/HomeLoaderMobile';
import RequestAppointmentModal from 'pages/Search/RequestAppointmentModal';
import ViewMoreModal from 'pages/Search/ViewMoreModal';
import { clearForm, editForm } from 'store/reducers/requestAppointmentForm';
import { chicagoLatLng } from 'util/constants';

export function ProvidersList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const isMobile = width < 600;
  const { providers, calendar, isLoading } = useSelector(
    (state) => state.hardInventory,
  );
  const { isRequestModalOpen } = useSelector((state) => state.ui);
  const [selectedSlot, selectSlot] = useState();
  const [isViewMoreModalOpen, setIsViewMoreModalOpen] = useState();
  const [viewMoreLocation, setViewMoreLocation] = useState();

  useEffect(() => {
    dispatch(
      appointmentFinder({
        lat: chicagoLatLng.lat,
        lng: chicagoLatLng.lng,
        displayAllTime: true,
        radius: 50,
      }),
    );
  }, []);

  const onRequestAppointment = (data) => {
    if (!selectedSlot && !data) return;
    const { provider, slot } = data || selectedSlot;
    const { date, time } = slot;
    dispatch(editForm({ key: 'provider', value: provider }));
    dispatch(editForm({ key: 'dateStart', value: `${date}T${time}` }));
    dispatch(editForm({ key: 'location', value: provider.uuid }));
    navigate(`/chicago/p/${provider.slug}`);
  };

  const onSelectSlot = (data) => {
    selectSlot(data);
    onRequestAppointment(data);
  };

  const onViewMore = (location) => {
    dispatch(clearForm());
    if (isMobile) {
      navigate(`/provider/${location.uuid}/slots`);
    } else {
      setViewMoreLocation(location);
      setIsViewMoreModalOpen(true);
    }
  };

  const onClickCard = (cardLink, item) => {
    dispatch(editForm({ key: 'dateStart', value: null }));
    navigate(cardLink);
  };

  const providersList = providers.map((item) => (
    <ProviderCard
      key={item.uuid}
      item={item}
      selectSlot={onSelectSlot}
      onViewMore={onViewMore}
      calendar={calendar}
      maxSlots="3"
      stopPropagation
      cardLink={`/${item.locality.replace(/ /g, '-').toLowerCase()}/p/${
        item.slug
      }`}
      onClickCard={onClickCard}
    />
  ));

  const clonedProvidersList = providersList.map((el) => el).slice(0, 8);
  const Loader = isMobile ? HomeLoaderMobile : HomeLoader;

  return (
    <div className="home-providers-list">
      <div className="flex-row justify-between">
        <h2 className="mb-25 mt-25">Recommended</h2>
        <Link to="/pet/groomers/chicago">View all</Link>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="home-providers-list-container">
          {clonedProvidersList}
        </div>
      )}
      <ViewMoreModal
        isOpen={isViewMoreModalOpen}
        setIsOpen={setIsViewMoreModalOpen}
        locationUuid={viewMoreLocation?.uuid}
        onSelectSlot={onSelectSlot}
        onRequestAppointment={() => {
          setIsViewMoreModalOpen(false);
          dispatch(petCreationMiddleware());
        }}
      />
      {isRequestModalOpen && (
        <RequestAppointmentModal
          isOpen={isRequestModalOpen}
          location={viewMoreLocation}
        />
      )}
    </div>
  );
}
