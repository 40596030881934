import './Signup.scss';

import { Helmet } from 'react-helmet-async';
import { useSearchParams } from 'react-router-dom';

import Checkout from 'organisms/Checkout';
import Landing from 'organisms/Landing';

import SignupDetails from './SignupDetails';

export default function Signup() {
  const [searchParams] = useSearchParams();
  const showAppointmentPanel = searchParams.get('appointment');

  const LayoutComponent = showAppointmentPanel ? Checkout : Landing;
  return (
    <>
      <Helmet title="Signup | PawCare">
        <link rel="canonical" href="https://mypawcare.com/signup" />
      </Helmet>
      <LayoutComponent removeOutterPadding>
        <SignupDetails showAppointmentPanel={showAppointmentPanel} />
      </LayoutComponent>
    </>
  );
}
