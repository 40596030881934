import {
  promoCodeFailure,
  promoCodeRequest,
  promoCodeSuccess,
} from 'store/reducers/invoice';

import { fetchData } from './fetchData';

export const fetchPromoCodeData = (promoCode) => (dispatch, getState) => {
  dispatch(promoCodeRequest());

  return fetchData({
    endpoint: `payment/promotion_code/${promoCode}`,
    actions: [promoCodeRequest, promoCodeSuccess, promoCodeFailure],
    httpMethod: 'get',
    showAPIerror: true,
  })(dispatch, getState)
    .then((response) => {
      // Handle the success case
      const promoData = response.data;
      sessionStorage.setItem('promoBannerText', promoData?.data?.bannerCopy);
      dispatch(promoCodeSuccess(promoData));
    })
    .catch((error) => {
      // Handle the error case
      console.error('Error fetching promo code data:', error);
      dispatch(promoCodeFailure(error));
    });
};
