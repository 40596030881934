import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { logOut } from 'actions/authentication';

export default function Actions() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.user);

  return (
    <div className="flex-column align-start mobile-actions-section w-100">
      <button
        className="f17 underline text-center pointer pl-10 mb-32 no-style-btn text-orange fw-100"
        onClick={() => navigate('/settings')}
      >
        Settings
      </button>
      {token && (
        <button
          className="f17 underline logout mb-20 pointer mt-17 no-style-btn text-orange fw-100"
          onClick={() => logOut(dispatch)}
        >
          Log out
        </button>
      )}
    </div>
  );
}
