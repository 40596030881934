import { useSelector } from 'react-redux';

import { isEmpty } from 'util';

import ProviderInfo from './ProviderInfo';
import SummaryInfo from './SummaryInfo';

export default function AppointmentDrawer() {
  const { appointment } = useSelector((state) => state.ui.drawer.data) || {};

  if (!appointment || isEmpty(appointment)) return null;

  return (
    <div className="appointment-drawer flex-column align-end">
      <div className="w-100">
        <div className="flex-row justify-between mb-25">
          <h2 className="f26">Appointment details</h2>
        </div>
        <ProviderInfo appointment={appointment} />
        <SummaryInfo appointment={appointment} />
      </div>
    </div>
  );
}
