import './RebookBanner.scss';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Button from 'atoms/button';
import DefaultAvatar from 'atoms/default-avatar';
import { clearForm } from 'store/reducers/requestAppointmentForm';

export default function RebookBanner({ location }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const goToProviderPage = () => {
    dispatch(clearForm());
    const providerPageLink = `/${location.locality?.toLowerCase()}/p/${location.slug}`;
    navigate(providerPageLink);
  };

  if (!location) return null;

  return (
    <div className="rebook-banner flex-row w-100 justify-between p-24 margin-center b-green radius-12">
      <div className="flex-row">
        <DefaultAvatar
          className="mr-12 border-white border-2"
          size="medium"
          avatar={location.links?.image?.desktop?.small}
        />
        <div className="flex-column align-start">
          <h3 className="f16">
            Book your next appointment with {location.name}
          </h3>
          <p className="f13">Your pet deserves another pampering session.</p>
        </div>
      </div>
      <Button
        text="Book now"
        size="medium"
        variant="reverse-white-green"
        onClick={goToProviderPage}
      />
    </div>
  );
}
