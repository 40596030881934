function KnobRight() {
  return (
    <svg
      width="6"
      height="12"
      viewBox="0 0 6 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1L4.91964 5.76444C4.94512 5.79536 4.96533 5.83208 4.97911 5.8725C4.9929 5.91292 5 5.95624 5 6C5 6.04376 4.9929 6.08708 4.97911 6.1275C4.96533 6.16792 4.94512 6.20464 4.91964 6.23556L1 11"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default KnobRight;
