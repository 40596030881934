import dayjs from 'dayjs';

import {
  locationFailure,
  locationRequest,
  locationSuccess,
} from 'store/reducers/location';
import {
  providerLocationFailure,
  providerLocationRequest,
  providerLocationSuccess,
} from 'store/reducers/provider';
import {
  providersFailure,
  providersRequest,
  providersSuccess,
} from 'store/reducers/providers';

import { fetchData } from './fetchData';

export const loadProvider = (id, actions) => (dispatch, getState) =>
  fetchData({
    endpoint: `provider/${id}`,
    actions: actions || [
      providerLocationRequest,
      providerLocationSuccess,
      providerLocationFailure,
    ],
  })(dispatch, getState);

export const loadProviderLocation =
  (luid, actions, params = { displayErrorToast: true }) =>
  (dispatch, getState) => {
    const { user, createPetForm, petAppointments } = getState();

    // Select active pet first
    const { activePet } = petAppointments;
    const activeBreed = activePet?.breed?.id;
    const activeSize = activePet?.size?.id;
    const activeType = activePet?.type?.id;
    // Fall back to default animal
    const defaultAnimal = user.data?.animals?.[0] || createPetForm.data || {};

    // Use activePet values if available, otherwise use defaultAnimal values
    const breedQuery = activeBreed
      ? `&breed_id=${activeBreed}`
      : defaultAnimal.breed?.id
        ? `&breed_id=${defaultAnimal.breed.id}`
        : '';
    const sizeQuery = activeSize
      ? `&size_id=${activeSize}`
      : defaultAnimal.size?.id
        ? `&size_id=${defaultAnimal.size.id}`
        : '';
    const typeQuery = activeType
      ? `&animal_type_id=${activeType}`
      : defaultAnimal.type?.id
        ? `&animal_type_id=${defaultAnimal.type.id}`
        : '';

    const query =
      activeType || defaultAnimal.type
        ? `?${typeQuery}${breedQuery}${sizeQuery}`
        : '';

    return fetchData({
      endpoint: `provider/location/${luid}${query}`,
      actions: actions || [locationRequest, locationSuccess, locationFailure],
      displayErrorToast: params?.displayErrorToast,
    })(dispatch, getState);
  };

export const searchGeo = (keyword) => (dispatch, getState) =>
  fetchData({
    endpoint: `search/geo?lat=41.88453153469004&long=-87.65234883796981&day=${dayjs().format(
      'YYYY-MM-DD',
    )}`,
    actions: [providersRequest, providersSuccess, providersFailure],
  })(dispatch, getState);
