import './Navbar.scss';

import { useState } from 'react';
import ReactModal from 'react-modal';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import useWindowDimensions from 'helpers/useWindowDimensions';

import Icon from 'atoms/icon';
import SearchBar from 'atoms/search-bar';
import DaycareModal from 'organisms/DaycareModal';
import { checkoutFlowURLs } from 'util/constants';

import DesktopItems from './NavbarItems/DesktopItems';
import MobileItems from './NavbarItems/MobileItems';

function Navbar() {
  const location = useLocation();
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.user);
  const { isMobileMenuOpen } = useSelector((state) => state.ui);
  const [isDaycareModalOpen, openDaycareModal] = useState(false);
  const { pathname } = location;
  const { width } = useWindowDimensions();
  const isMobile = token ? width < 900 : width < 1100;
  const isHomepage = pathname === '/';
  const isProviderProfile = pathname.includes('/p/');
  const isCheckoutPage =
    checkoutFlowURLs.includes(pathname) || isProviderProfile;
  const hideSearchBarUrls = ['/signup/finish-signup', '/login'];
  const displaySearchBar =
    !hideSearchBarUrls.includes(pathname) && !isCheckoutPage && !isMobile;
  const transparentVariant = isHomepage && !isMobile;

  const onSearch = (query) => {
    navigate(`/pet/groomers/chicago?query=${query}`);
  };

  return (
    <>
      <div
        className={classNames(
          'navbar flex-row justify-center w-100 b-white',
          transparentVariant ? 'navbar--transparent pos-absolute' : 'pos-fixed',
          isMobileMenuOpen && 'b-blue',
        )}
      >
        {!isCheckoutPage && !isMobileMenuOpen && (
          <Link
            to={localStorage.getItem('homepageUrl')}
            className="navbar-logo pos-absolute"
          >
            <Icon
              name="pawcare"
              style={{
                width: 125,
                height: '100%',
                outterColor: transparentVariant ? 'white' : '#150828',
                variant: transparentVariant ? '#150828' : 'white',
              }}
            />
          </Link>
        )}
        {!token && !isMobile && isHomepage && (
          <button
            className="daycare flex-row pointer pos-absolute f16 text-white"
            onClick={() => openDaycareModal(true)}
          >
            Daycare{' '}
            <span className="coming-soon ml-10 b-white text-black">
              Coming soon
            </span>
          </button>
        )}
        {displaySearchBar && (
          <SearchBar
            onSearch={onSearch}
            value=""
            className="navbar-searchbar"
            variant={transparentVariant ? 'transparent' : ''}
          />
        )}
        {!isMobile ? <DesktopItems /> : <MobileItems />}
      </div>
      <ReactModal
        ariaHideApp={false}
        isOpen={isDaycareModalOpen}
        onRequestClose={() => openDaycareModal(false)}
        className="daycare-modal"
      >
        <DaycareModal openModal={openDaycareModal} />
      </ReactModal>
    </>
  );
}

export default Navbar;
