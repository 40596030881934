export default function Section({ title, children }) {
  return (
    <div className="mb-20">
      <p>
        <strong>{title}</strong>
        {children}
      </p>
    </div>
  );
}
