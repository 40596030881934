import './StripeWidget.scss';

import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import classNames from 'classnames';
import useWindowDimensions from 'helpers/useWindowDimensions';

import { paymentInit, paymentMethod } from 'actions/payment';
import Button from 'atoms/button';
import toast from 'atoms/toast';
import AddDiscount from 'organisms/AddDiscount';
import TermsLabel from 'organisms/AddNewCard/TermsLabel';
import { requestFulfilled, requestOngoing } from 'store/reducers/ui';

export default function StripeWidget({
  onSubmit,
  primaryButtonText,
  preSubmissionAction,
  hideCopy,
  hideDiscount,
  hasSignedWaiver,
  canContinueWithoutCard,
}) {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const isMobile = width < 600;
  const { requestLoading } = useSelector((state) => state.ui);
  const { data: userData } = useSelector((state) => state.user);
  const clientSecret = useSelector(
    (state) => state.main.paymentInitData?.stripe?.intent?.secret,
  );
  const cardOptions = {
    hidePostalCode: true,
    iconStyle: 'solid',
    disableLink: true,
    style: {
      base: {
        fontSize: '18px',
        fontFamily: 'Poppins, Helvetica, Arial, sans-serif',
        fontWeight: 200,
        '::placeholder': {
          color: '#9191A8',
        },
      },
    },
  };
  const [cardEmpty, setCardEmpty] = useState(true);
  const [cardComplete, setCardComplete] = useState(false);
  const isSubmitDisabled = requestLoading || !cardComplete || !hasSignedWaiver;
  // Payment init flag to prevent duplicates
  const [paymentInitCalled, setPaymentInitCalled] = useState(false);

  const canContinueWithoutPayment = canContinueWithoutCard && cardEmpty;

  const handleSubmit = async (e) => {
    e.preventDefault();

    // We had an instance of stripe not loading on prod
    // Leave so we can see logs on hotjar
    console.log('requestLoading', requestLoading);
    console.log('cardComplete', cardComplete);

    if (!stripe || !elements || isSubmitDisabled) return;

    dispatch(requestOngoing());
    if (preSubmissionAction) {
      const preSubmission = await preSubmissionAction();
      if (!preSubmission.success) {
        dispatch(requestFulfilled());
        return;
      }
      continueWithSubmission(true, preSubmission.data);
    } else continueWithSubmission(false);
  };

  const continueWithSubmission = async (callPaymentInit, preSubmissionData) => {
    console.log('preSubmission', preSubmissionData);
    const { error: submitError } = await elements.submit();
    if (submitError) {
      dispatch(requestFulfilled());
      return;
    }
    const cardElement = elements.getElement(CardElement);

    let secret = clientSecret;
    if (callPaymentInit && !paymentInitCalled) {
      const paymentData = await dispatch(paymentInit());
      secret = paymentData.data.data.stripe.intent.secret;
      setPaymentInitCalled(true);
    }

    const result = await stripe.confirmCardSetup(secret, {
      return_url: window.location.origin,
      payment_method: {
        card: cardElement,
      },
    });
    if (result.error) {
      console.log('confirmCardSetup failed');
      toast({ text: result.error.message });
      dispatch(requestFulfilled());
      setPaymentInitCalled(false);
    } else {
      console.log('confirmCardSetup successful');
      onSubmit();
      console.log('trigger from checkout form.js');
      if (!paymentInitCalled) {
        dispatch(paymentInit());
        setPaymentInitCalled(true);
      }
      dispatch(paymentMethod(userData.uuid || preSubmissionData.uuid));
    }
  };

  const onChange = (e) => {
    setCardEmpty(e.empty);
    if (e.complete) setCardComplete(true);
  };

  return (
    <form onSubmit={handleSubmit} className="stripe-widget">
      <div className="mobile-page-container mb-15">
        <div className="cardElement-container">
          <CardElement options={cardOptions} onChange={onChange} />
        </div>
        {!hideDiscount && <AddDiscount />}
        {!hideCopy && isMobile && (
          <TermsLabel hasSignedWaiver={hasSignedWaiver} />
        )}
      </div>
      <div className="mobile-main-action-btn flex-column align-center">
        {canContinueWithoutPayment ? (
          <Button
            variant={
              requestLoading || !hasSignedWaiver ? 'disabled' : 'default'
            }
            className="mt-32 w-100"
            displayLoaderIcon
            text="Continue without payment"
            size="medium"
            onClick={onSubmit}
          />
        ) : (
          <Button
            type="submit"
            variant={isSubmitDisabled ? 'disabled' : 'default'}
            className="submit-payment mt-32 w-100"
            displayLoaderIcon
            text={primaryButtonText}
            size="medium"
          />
        )}
        {!hideCopy && !isMobile && (
          <TermsLabel hasSignedWaiver={hasSignedWaiver} />
        )}
      </div>
    </form>
  );
}
