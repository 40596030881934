import './Slot.scss';

import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import { prettifyHour } from 'util';
import { trackSlotSelected } from 'util/tracking/events';

export default function Slot({
  date,
  time,
  location,
  onClick,
  id,
  displayDate = true,
  mobileStyling,
  stopPropagation,
  className,
  emptySlotText,
  emptySlotAction,
}) {
  const dispatch = useDispatch();

  const handleClick = (e) => {
    if (stopPropagation) {
      e.stopPropagation();
    }
    if (emptySlotAction) {
      emptySlotAction(location);
    } else {
      dispatch(trackSlotSelected(time, location));
      onClick({ slot: { date, time }, provider: location, id });
    }
  };

  return (
    <button
      className={classNames(
        'slot-button',
        className,
        emptySlotText && 'slot-button--empty',
        mobileStyling && 'slot-button--mobile',
      )}
      onClick={(e) => handleClick(e)}
    >
      {!emptySlotText
        ? `${
            displayDate ? `${dayjs(date).format('MMM DD')}, ` : ''
          }${prettifyHour(time)}`
        : emptySlotText || 'Request appointment'}
    </button>
  );
}

Slot.propTypes = {
  className: PropTypes.string,
  date: PropTypes.string,
  // Display date inside slot
  displayDate: PropTypes.bool,
  // Slot that doesn't have a date or time. Used for general actions Ex; View more
  emptySlotAction: PropTypes.func,
  emptySlotText: PropTypes.string,
  id: PropTypes.string,
  location: PropTypes.object,
  mobileStyling: PropTypes.bool,
  onClick: PropTypes.func,
  stopPropagation: PropTypes.bool,
  time: PropTypes.string,
};
