import './ProviderCard.scss';

import classNames from 'classnames';
import useWindowDimensions from 'helpers/useWindowDimensions';
import PropTypes from 'prop-types';

import DefaultAvatar from 'atoms/default-avatar';
import { Slots } from 'organisms/Slots';
import { UserDetails } from 'organisms/UserDetails';

export default function ProviderCard({
  item,
  selectSlot,
  onViewMore,
  calendar,
  maxSlots,
  className,
  cardLink,
  stopPropagation,
  onClickCard,
}) {
  const { width } = useWindowDimensions();
  const isMobile = width < 600;
  return (
    <div
      className={classNames(
        'provider-card flex-column align-center',
        className,
      )}
    >
      <button
        className="pointer b-transparent border-none"
        onClick={() => onClickCard(cardLink, item)}
      >
        {isMobile ? (
          <UserDetails item={item} />
        ) : (
          <div>
            <div>
              <div className="provider-card-header flex-row justify-center">
                <DefaultAvatar
                  className="user-details-avatar"
                  size="extra-large"
                  avatar={item.links?.image?.desktop?.large}
                />
              </div>
            </div>
            <div className="flex-column provider-card-details">
              <h4 className="text-black f20 mt-20">{item.name}</h4>
              <p className="user-details-address">{item.address1}</p>
            </div>
          </div>
        )}
      </button>
      {!isMobile && (
        <div className="provider-card-slots w-100">
          <Slots
            calendar={calendar}
            item={item}
            selectSlot={selectSlot}
            onViewMore={onViewMore}
            maxSlots={maxSlots}
            stopPropagation={stopPropagation}
          />
        </div>
      )}
    </div>
  );
}

ProviderCard.propTypes = {
  calendar: PropTypes.array,
  cardLink: PropTypes.string,
  className: PropTypes.string,
  item: PropTypes.shape({
    address1: PropTypes.string,
    links: PropTypes.shape({
      image: PropTypes.shape({
        desktop: PropTypes.shape({
          large: PropTypes.string,
        }),
      }),
    }),
    name: PropTypes.string,
  }),
  maxSlots: PropTypes.string,
  onClickCard: PropTypes.func,
  onViewMore: PropTypes.func,
  selectSlot: PropTypes.func,
  stopPropagation: PropTypes.bool,
};
