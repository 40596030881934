import './BlogLoader.scss';

import classNames from 'classnames';

function BlogLoader({ variant = 'default' }) {
  return (
    <div className="blog-loader">
      {[1, 2, 3].map((el) => (
        <div
          key={el}
          className={classNames(`row flex-row mb-10 row-${variant}`)}
        >
          <span className="row-avatar" />
          <div className="flex-column w-100 content">
            <span className="title " />
            <span className="description mt-5" />
            <span className="description mt-5" />
            <span className="description mt-5" />
            <span className="description mt-5" />
            <span className="description mt-5" />
            <span className="cta mt-5" />
          </div>
        </div>
      ))}
    </div>
  );
}

export default BlogLoader;
