import { Typeahead } from 'react-bootstrap-typeahead';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { createPet } from 'actions/pet';
import { loadProviderLocation } from 'actions/providers';
import { loadUser } from 'actions/user';
import Button from 'atoms/button';
import Dropdown from 'atoms/dropdown';
import Icon from 'atoms/icon';
import Input from 'atoms/input';
import SelectButton from 'atoms/select-button';
import { editForm } from 'store/reducers/createPetForm';
import { openCreatePetModal, openRequestModal } from 'store/reducers/ui';
import { trackPetInfo } from 'util/tracking/events';

export default function CreatePetModal() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const paramsSlug = location.pathname.split('p/')[1];
  const { token, data: userData } = useSelector((state) => state.user);
  const { breed, name, size, type } = useSelector(
    (state) => state.createPetForm.data,
  );
  const { core } = useSelector((state) => state.main);
  const breedOptions = core.breed.filter((el) => el.animalTypeId === type?.id);
  const { createPetModal } = useSelector((state) => state.ui);
  const userHasNoPets = token && userData.animals.length === 0;
  const canSubmit = size && type;

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!canSubmit) return;

    if (token) {
      const body = {
        name,
        breedId: breed?.id,
        sizeId: size?.id,
        typeId: type?.id,
      };

      if (userHasNoPets) {
        const petCreated = await dispatch(createPet(body));
        if (petCreated.success) {
          await dispatch(loadUser());
          continueFlow();
        }
      } else {
        closeModal();
      }
    } else {
      continueFlow();
    }
  };

  const continueFlow = () => {
    // User wanted to request an appointment, open modal
    if (createPetModal.openRequestModal) {
      dispatch(openRequestModal(true));
      closeModal();
      return;
    }

    if (createPetModal.navigateTo) {
      navigate(createPetModal.navigateTo);
    } else {
      dispatch(loadProviderLocation(paramsSlug));
    }
    closeModal();
  };

  const closeModal = () => {
    dispatch(openCreatePetModal({ open: false, data: null }));
  };

  function startsWithFilter(option, props) {
    const optionWords = option[props.labelKey].toLowerCase().split(/\s+/); // Split option label into words
    const inputWords = props.text.toLowerCase().split(/\s+/); // Split user input into words

    return inputWords.every((inputWord) =>
      optionWords.some((optionWord) => optionWord.startsWith(inputWord)),
    );
  }

  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={createPetModal.isCreatePetModalOpen}
      onRequestClose={closeModal}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      className="create-animal-modal"
    >
      <div className="flex-column">
        {/* {!userHasNoPets && (
          <Icon
            name="close"
            style={{ fill: "black" }}
            onClick={closeModal}
            className="mb-10 p-10 align-self-end"
          />
        )} */}
        <h2 className="mb-30">Tell us about your pet</h2>
        <form onSubmit={onSubmit}>
          {userHasNoPets && (
            <Input
              id="name"
              label="Pet name"
              className="mb-25"
              onChange={(value) => dispatch(editForm({ key: 'name', value }))}
              value={name}
            />
          )}
          <h3 className="mb-15">Pet type</h3>
          <div className="flex-row mb-30">
            {core.type.map((el, i) => (
              <SelectButton
                key={el.value}
                className={i === 0 ? 'w-100 mr-15' : 'w-100'}
                onClick={(value, e) => {
                  e.preventDefault();
                  dispatch(editForm({ key: 'type', value }));
                  dispatch(editForm({ key: 'breed', value: null }));
                }}
                isActive={type?.value === el.value}
                value={el}
              >
                <p>
                  {el.value === 'Dog' ? '🐶' : '🐱'} {el.value}
                </p>
              </SelectButton>
            ))}
          </div>
          <Dropdown
            label="Size"
            placeholder="Select"
            className="w-100 mt-25"
            items={core.size}
            onClick={(value) => dispatch(editForm({ key: 'size', value }))}
            value={size?.value}
          />
          {userHasNoPets && (
            <>
              <h3 className="mt-25 mb-15">Breed</h3>
              <Typeahead
                id="breed"
                labelKey="value"
                options={breedOptions}
                placeholder="Select"
                filterBy={startsWithFilter}
                onChange={(value) =>
                  dispatch(editForm({ key: 'breed', value: value[0] }))
                }
                selected={breed ? [breed] : []}
                className="w-100 input-field"
              />
            </>
          )}
          <Button
            text="Next"
            type="submit"
            className="mt-25 w-fit"
            variant={canSubmit ? 'default' : 'disabled'}
          />
        </form>
      </div>
    </ReactModal>
  );
}
