import { openCreatePetModal, openRequestModal } from 'store/reducers/ui';

export default function petCreationMiddleware() {
  return (dispatch, getState) => {
    const userHasPets = getState().user.data?.animals?.length;
    const petSize = getState().createPetForm.data.size;
    if (!userHasPets && !petSize) {
      dispatch(openCreatePetModal({ open: true, openRequestModal: true }));
    } else dispatch(openRequestModal(true));
  };
}
