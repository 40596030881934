import './InfoBlock.scss';

import classNames from 'classnames';
import PropTypes from 'prop-types';

export function InfoBlock({ children, custom }) {
  return (
    <div className={classNames('info-block', custom && 'info-block--custom')}>
      {children}
    </div>
  );
}

InfoBlock.propTypes = {
  children: PropTypes.node,
  custom: PropTypes.bool,
};
