/* eslint-disable react/jsx-pascal-case */
import './Icon.scss';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import iconComponents from './Icons';

export default function Icon({
  name,
  size = 14,
  className,
  alt,
  onClick,
  style = {},
  ...props
}) {
  if (!name) return null;

  const SvgComponent = iconComponents[name];

  return (
    <div
      role="button"
      tabIndex={0}
      className={classNames(
        'icon',
        `icon-${name}`,
        className,
        onClick && 'pointer',
      )}
      onClick={onClick}
    >
      {alt && <span className="accessible-hide">{alt}</span>}
      {SvgComponent && <SvgComponent.default style={style} {...props} />}
      {!SvgComponent && (
        <span style={{ display: 'inline-block', width: size, height: size }} />
      )}
    </div>
  );
}

Icon.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.oneOf(Object.keys(iconComponents)),
  onClick: PropTypes.func,
  size: PropTypes.number,
  style: PropTypes.object,
};
