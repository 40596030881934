import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { mfaInit } from 'actions/authentication';
import Button from 'atoms/button';
import Dropdown from 'atoms/dropdown';
import Input from 'atoms/input';
import { editForm } from 'store/reducers/signupForm';

export default function PhoneVerification() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const countryIdOptions = useSelector((state) => state.main.geo.countryCode);
  const signupFormData = useSelector((state) => state.signupForm.data);
  const { countryCodeId, phoneNumber } = signupFormData;
  const userData = useSelector((state) => state.user.data);

  useEffect(() => {
    if (userData.phone) {
      dispatch(
        editForm({ key: 'countryCodeId', value: userData.phone.country.code }),
      );
      dispatch(editForm({ key: 'phoneNumber', value: userData.phone.number }));
    }
  }, []);

  const onPhoneValidation = (e) => {
    e.preventDefault();
    if (!phoneNumber) return;

    dispatch(mfaInit({ countryCodeId: countryCodeId.id, phoneNumber }));
    navigate('/signup/mfa');
  };

  return (
    <>
      <h1 className="mb-0 mobile-page-title">Phone verification</h1>
      <form onSubmit={onPhoneValidation} className="h-100">
        <div className="phone-container flex-row mobile-page-container">
          <Dropdown
            id="countryCode"
            label="Phone"
            className="country-code-input"
            items={countryIdOptions}
            onClick={(value) =>
              dispatch(editForm({ key: 'countryCodeId', value }))
            }
            value={countryCodeId}
          />
          <Input
            id="phone"
            className="mt-42 w-100"
            onChange={(value) =>
              dispatch(editForm({ key: 'phoneNumber', value }))
            }
            value={phoneNumber}
            isNumber
          />
        </div>
        <div className="mobile-main-action-btn">
          <Button
            text="Next"
            type="submit"
            className="mt-35"
            variant={!phoneNumber ? 'disabled' : 'default'}
          />
        </div>
      </form>
    </>
  );
}
