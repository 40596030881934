import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { editPet } from 'actions/pet';
import { loadUser } from 'actions/user';
import Button from 'atoms/button';
import { DateInput } from 'atoms/date-input';
import Dropdown from 'atoms/dropdown';
import { editForm } from 'store/reducers/createPetForm';
import { setActivePet } from 'store/reducers/petAppointments';
import { generateKey, isNotEmptyArray } from 'util';

export default function CreatePetDetailsInfo() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { gender, type, uuid } = useSelector(
    (state) => state.user.data?.animals?.[0],
  );
  const { data } = useSelector((state) => state.createPetForm);
  const userData = useSelector((state) => state.user.data);
  const { core } = useSelector((state) => state.main);
  const { dateOfBirth, gender: formGender, isSterilized, vaccinations } = data;
  const canContinue = isSterilized && dateOfBirth && (gender || formGender);
  const vaccinationForAnimalType = core.vaccination.filter(
    (el) => el.animalTypeId === type?.id,
  );

  const vaccinationIdsList = vaccinations
    .map((vaccination) =>
      vaccination?.id && vaccination?.value ? vaccination.id : null,
    )
    .filter((vaccination) => !!vaccination);

  const onAddVaccine = () => {
    dispatch(editForm({ key: 'vaccinations', value: [...vaccinations, {}] }));
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!canContinue) return;

    dispatch(
      editPet(
        {
          dateOfBirth,
          genderId: formGender?.id,
          isSterilized: isSterilized?.id,
          vaccinations,
        },
        uuid,
      ),
    ).then(({ success }) => {
      if (success) {
        dispatch(loadUser());
        dispatch(setActivePet(userData?.animals?.[0]));
        navigate('/upload-avatar');
      }
    });
  };

  return (
    <div className="create-pet">
      <h1 className="mb-15 f-14">Additional pet details</h1>
      <form onSubmit={onSubmit}>
        <div className="flex-row align-end">
          <div className="flex-column w-100 mr-10">
            <h3 className="mb-15">Date of birth</h3>
            <DateInput
              placeholder="02/02/2020"
              maxDate={new Date()}
              onChange={(value) =>
                dispatch(editForm({ key: 'dateOfBirth', value }))
              }
              value={dateOfBirth}
            />
          </div>
          {!gender && (
            <Dropdown
              label="Gender"
              placeholder="Select"
              className="w-100 mr-10"
              items={core.gender}
              onClick={(value) => dispatch(editForm({ key: 'gender', value }))}
              value={formGender?.value}
            />
          )}
          <Dropdown
            label="Spay/Neuter"
            placeholder="Select"
            className="w-100"
            items={[
              { id: true, value: 'Yes' },
              { id: false, value: 'No' },
            ]}
            onClick={(value) =>
              dispatch(editForm({ key: 'isSterilized', value }))
            }
            value={isSterilized?.value}
          />
        </div>
        {(type?.id === 1 || type?.id === 2) && (
          <div className="vaccination-form-container flex-column mt-32 mb-25">
            <h3 className="f26 mb-15 mt-10">Vaccinations</h3>

            {isNotEmptyArray(vaccinations) && (
              <div className="flex-row gap-12 mt-12">
                <h4 className="type-title f18 bold">Type</h4>
                <h4 className="f18 bold">Expiration date</h4>
              </div>
            )}

            {vaccinations.map((vaccination, index) => (
              <div className="flex-row gap-12 mt-12" key={generateKey()}>
                <Dropdown
                  placeholder="Vaccination"
                  className="vaccination-select"
                  items={vaccinationForAnimalType.filter(
                    (vaccinationOption) =>
                      vaccinationOption?.id === vaccination?.id ||
                      !vaccinationIdsList.includes(vaccinationOption?.id),
                  )}
                  size="medium"
                  onClick={(value) => {
                    const updatedVaccination = {
                      ...vaccinations[index],
                      ...value,
                    };
                    const vaccinationsCopy = vaccinations.slice(0);
                    vaccinationsCopy[index] = updatedVaccination;
                    return dispatch(
                      editForm({
                        key: 'vaccinations',
                        value: vaccinationsCopy,
                      }),
                    );
                  }}
                  value={
                    vaccination?.id && vaccination?.value ? vaccination : null
                  }
                />
                <DateInput
                  className="expiration-date-input"
                  placeholder="Expiration date"
                  onChange={(date) => {
                    const updatedVaccination = {
                      ...vaccinations[index],
                      expirationDate: date,
                    };
                    const vaccinationsCopy = vaccinations.slice(0);
                    vaccinationsCopy[index] = updatedVaccination;
                    return dispatch(
                      editForm({
                        key: 'vaccinations',
                        value: vaccinationsCopy,
                      }),
                    );
                  }}
                  value={
                    vaccination?.expirationDate
                      ? vaccination.expirationDate
                      : null
                  }
                  size="medium"
                />
              </div>
            ))}
            <div className="flex-row mt-12">
              <Button
                className="add-vaccine-button fw-400"
                text="Add vaccine"
                size="large"
                variant="light-gray"
                icon="add"
                iconProps={{ style: { fill: '#63637b' } }}
                iconPosition="start"
                onClick={onAddVaccine}
                type="button"
              />
            </div>
          </div>
        )}
        <Button
          variant={canContinue ? 'default' : 'disabled'}
          text="Next"
          type="submit"
          className="mt-25"
        />
      </form>
    </div>
  );
}
