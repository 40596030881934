import '../UserSearch.scss';

import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { searchProviders } from 'actions';
import DefaultAvatar from 'atoms/default-avatar';
import Input from 'atoms/input';

function ProviderSearch({ onSelect, searchParams, forceFirstSerch }) {
  const dispatch = useDispatch();
  const [value, setValue] = useState('');
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [searchResults, setSearchResults] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (forceFirstSerch) {
      startSearch();
    }
  }, []);

  const handleSearch = (search) => {
    if (!search) setSearchResults(null);
    setValue(search);
    if (typingTimeout) {
      clearTimeout(typingTimeout);
      setIsLoading(false);
    }
    if (search) {
      startSearch(search);
    }
  };

  const startSearch = (search) => {
    setIsLoading(true);
    setSearchResults(null);
    setTypingTimeout(
      setTimeout(() => {
        dispatch(searchProviders(search, searchParams)).then(
          ({ success, data }) => {
            if (success) {
              const providersWithLocations = data.data.providers.filter(
                (el) => el.locations.length > 0,
              );
              setSearchResults(providersWithLocations);
            }
            setIsLoading(false);
          },
        );
      }, 500),
    );
  };

  const onProviderClick = (provider) => {
    if (onSelect) {
      return onSelect(provider);
    }
    window.location.assign(`/services/${provider.slug}`);
  };

  const renderList = () => {
    if (isLoading) return 'Loading';
    if (!searchResults) return '';
    if (searchResults && !searchResults.length) return '0 results found';
    if (searchResults?.length) {
      return searchResults.map((el) => (
        <div
          key={el.uuid}
          className="flex-row provider"
          role="button"
          tabIndex={0}
          onClick={() => onProviderClick(el)}
        >
          <DefaultAvatar
            size="small"
            avatar={el.locations[0].links?.image?.desktop?.large}
          />
          <div className="ml-10">
            <p className="bold">{el.name}</p>
            <p className="text-grey-dark">{el.locations[0]?.address1}</p>
          </div>
        </div>
      ));
    }
  };

  return (
    <div className="w-100 user-search flex-column align-center">
      <div className="user-search-box">
        <h2 className="mb-15">Provider search</h2>
        <Input
          placeholder="ABC Pets"
          icon="search"
          onChange={handleSearch}
          value={value}
          autoFocus
        />
      </div>
      <div className="providers-list">{renderList()}</div>
    </div>
  );
}

export default ProviderSearch;
