import { getLastAction } from 'organisms/Appointments/util';
import SingleTextLoader from 'organisms/Loaders/SingleTextLoader';
import { capitalizeFirstLetter } from 'util';

export default function CheckoutSummary({
  actions,
  isLoading,
  service,
  summary,
}) {
  const lastAction = getLastAction(actions);
  const isCompletedWithoutService = [3, 4].includes(lastAction?.id); // No show or cancelled with fee

  if (!summary) return null;

  return (
    <>
      {isCompletedWithoutService ? (
        service?.price && (
          <div className="flex-row justify-between mb-12 f13">
            <p>Subtotal</p>
            {isLoading ? (
              <SingleTextLoader />
            ) : (
              <p>{service.price.localized}</p>
            )}
          </div>
        )
      ) : (
        <>
          {summary.subTotal && (
            <div className="flex-row justify-between mb-12 f13">
              <p>Subtotal</p>
              {isLoading ? (
                <SingleTextLoader />
              ) : (
                <p>{summary.subTotal.localized}</p>
              )}
            </div>
          )}
          {summary.discount && (
            <div className="flex-row justify-between mb-12 f13">
              <p>{summary.discount.description}</p>
              {isLoading ? (
                <SingleTextLoader />
              ) : (
                <p>{summary.discount.price?.localized}</p>
              )}
            </div>
          )}
          {summary.tip && (
            <div className="flex-row justify-between mb-12 f13">
              <p>{summary.tip.description}</p>
              {isLoading ? (
                <SingleTextLoader />
              ) : (
                <p>{summary.tip.price?.localized}</p>
              )}
            </div>
          )}
          {summary.taxes && (
            <div className="flex-row justify-between mb-12 f13">
              <p>Taxes</p>
              {isLoading ? (
                <SingleTextLoader />
              ) : (
                <p>{summary.taxes.localized}</p>
              )}
            </div>
          )}
          {summary.serviceFee && (
            <div className="flex-row justify-between mb-12 f13">
              <p>{summary.serviceFee.description}</p>
              {isLoading ? (
                <SingleTextLoader />
              ) : (
                <p>{summary.serviceFee.price?.localized}</p>
              )}
            </div>
          )}
        </>
      )}

      <div className="flex-row justify-between f16 pt-15 border-top-light-purple pt-12">
        <p className="bold">
          {isCompletedWithoutService
            ? capitalizeFirstLetter(lastAction?.value)
            : 'Total'}
        </p>

        {isLoading ? (
          <SingleTextLoader />
        ) : (
          <p className="bold">{summary.total.localized}</p>
        )}
      </div>
    </>
  );
}
