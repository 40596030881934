import { isEmpty, prettifyDate } from 'util';

import CheckoutSummary from './CheckoutSummary';

export default function SummaryInfo({ appointment }) {
  const { actions, dateStart, isLoading, service, summary } = appointment;

  if (!summary || isEmpty(summary)) return null;

  return (
    <div className="flex-column mb-12 p-24 border-light-purple radius-24">
      <h4 className="mb-8">{service.name ?? 'Service summary'}</h4>
      {!!dateStart && (
        <p className="flex-row mb-15 f13">{prettifyDate(dateStart)}</p>
      )}
      <CheckoutSummary
        actions={actions}
        isLoading={isLoading}
        service={service}
        summary={summary}
      />
    </div>
  );
}
