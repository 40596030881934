/* eslint-disable camelcase */
import {
  invoiceFailure,
  invoiceRequest,
  invoiceSuccess,
} from 'store/reducers/invoice';
import {
  bootstrapFailure,
  bootstrapRequest,
  bootstrapSuccess,
} from 'store/reducers/main';
import { userSetCredentials } from 'store/reducers/user';

import { logOut } from './authentication';
import { fetchData } from './fetchData';

export const loadBootstrap = () => (dispatch, getState) =>
  fetchData({
    endpoint: 'bootstrap',
    actions: [bootstrapRequest, bootstrapSuccess, bootstrapFailure],
  })(dispatch, getState).then(({ success, data, status }) => {
    if (success) {
      if (data.data.user.credentials.token) {
        const { token, uuid } = data.data.user.credentials;
        dispatch(userSetCredentials({ token, uuid }));
        localStorage.setItem('homepageUrl', '/');
        localStorage.setItem('token', token);
      }
      return { success, data, isLoggedIn: !!data.data.user.credentials.token };
    }
    if (status === 401) {
      logOut(dispatch);
    }
    return { success: false };
  });

export const sendAppDownloadSMS = (body) => (dispatch, getState) =>
  fetchData({
    endpoint: 'download/link',
    actions: [null, null, null],
    httpMethod: 'post',
    body,
  })(dispatch, getState);

export const searchConsumers = (keyword) => (dispatch, getState) =>
  fetchData({
    endpoint: `search/consumers?q=${keyword}`,
    actions: [null, null, null],
    bypassImpersonation: true,
  })(dispatch, getState);

export const searchProviders = (data, searchParams) => (dispatch, getState) => {
  const params = searchParams
    ? `&lat=${searchParams.lat}&long=${searchParams.long}`
    : '';
  const keyword = data ? `q=${data}` : '';
  return fetchData({
    endpoint: `search/providers?${keyword}${params}`,
    actions: [null, null, null],
    bypassImpersonation: true,
  })(dispatch, getState);
};

export const getBlogPosts = () => (dispatch, getState) =>
  fetchData({
    endpoint: 'cms/posts',
    actions: [null, null, null],
  })(dispatch, getState);

export const servicePriceCheck = ({ suid, discount }) => {
  const promoCode = discount || sessionStorage.getItem('promoCode');
  const promoQuery = promoCode ? `?promotion_code=${promoCode}` : '';
  return (dispatch, getState) =>
    fetchData({
      endpoint: `service/${suid}/price/check${promoQuery}`,
      actions: [invoiceRequest, invoiceSuccess, invoiceFailure],
    })(dispatch, getState);
};

export const storeAddress = () => (dispatch, getState) => {
  const { latitude, longitude, locality, region, postalCode, address_1 } =
    getState().advancedSearch;
  const body = {
    country_id: 238,
    latitude,
    longitude,
    locality,
    region,
    postalCode,
  };

  if (address_1 !== 'invalid_address') {
    body.address = address_1;
  }
  return fetchData({
    endpoint: `search/address`,
    actions: [null, null, null],
    httpMethod: 'post',
    body,
  })(dispatch, getState);
};
