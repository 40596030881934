import Icon from 'atoms/icon';

export default function PlaceholderImage({
  placeholder,
  defaultIconSize,
  cameraVariant,
}) {
  return (
    <Icon
      name={cameraVariant ? 'camera' : placeholder}
      style={{ width: defaultIconSize, height: defaultIconSize }}
    />
  );
}
