import { useState } from 'react';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { loadBootstrap } from 'actions';
import { logIn } from 'actions/authentication';
import Button from 'atoms/button';
import Input from 'atoms/input';
import Link from 'atoms/link';
import toast from 'atoms/toast';
import useQuery from 'hooks/useQuery';
import { clearForm } from 'store/reducers/requestAppointmentForm';
import { checkoutFlowURLs } from 'util/constants';

const loggedOutFlowURLs = [
  ...checkoutFlowURLs,
  '/booking-select-groomer',
  '/booking-select-slot',
];

export default function LoginInfo() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const query = useQuery();
  const requestAppointmentForm = useSelector(
    (state) => state.requestAppointmentForm,
  );
  const [isOpen, openModal] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPasswordl] = useState('');
  const homepageWithQueryParams = `/${query.size > 0 ? `?${query.toString()}` : ''}`;
  const locationStatePathname = location.state?.from?.pathname;
  const fromSignup =
    locationStatePathname?.includes('signup') ||
    loggedOutFlowURLs?.includes(locationStatePathname);
  const { providerPath } = requestAppointmentForm;
  const fromHomepageCheck =
    locationStatePathname === '/' || fromSignup
      ? homepageWithQueryParams
      : locationStatePathname;
  const from =
    fromSignup && !!providerPath
      ? providerPath
      : fromHomepageCheck || homepageWithQueryParams; // default to home page if no referrer

  const onLogIn = async (e) => {
    e.preventDefault();
    // User was requesting an appointment
    if (requestAppointmentForm.dateStart && requestAppointmentForm.service) {
      sessionStorage.setItem(
        'requestAppointmentForm',
        JSON.stringify(requestAppointmentForm),
      );
    }
    const { success, data, status } = await dispatch(
      logIn({ email, password }, { displayErrorToast: false }),
    );

    if (success) {
      if (fromSignup) dispatch(clearForm());
      const { token } = data.data;
      localStorage.setItem('token', token);
      dispatch(loadBootstrap());
      navigate(from);
      return;
    }
    if (!success) {
      if (status === 404) {
        openModal(true);
      }
      if (status === 401) {
        toast({
          text: 'Wrong combination of user and password! Please try again.',
        });
      }
    }
  };

  return (
    <div className="login">
      <h1 className="mr-0 mb-20">Log in</h1>
      <form onSubmit={onLogIn}>
        <Input
          id="email"
          label="Email"
          type="email"
          onChange={(value) => setEmail(value)}
          value={email}
          autoFocus
        />
        <div className="flex-row justify-between align-center mb-15 mt-35">
          <p className="label">Password</p>
          <Link to="/trigger-password-reset" className="forgot-password">
            Forgot password?
          </Link>
        </div>
        <Input
          id="password"
          type="password"
          onChange={(value) => setPasswordl(value)}
          value={password}
        />
        <span className="no-account-text fw-100">
          Don&apos;t have an account?
          <Link
            to={
              location.search.includes('appointment-signup')
                ? '/signup/pet-details'
                : `/signup${location.search}`
            }
          >
            Sign up.
          </Link>
        </span>
        <Button className="mb-35" type="submit" text="Log in" />
      </form>

      <ReactModal
        className="user-not-found-modal"
        ariaHideApp={false}
        isOpen={isOpen}
        onRequestClose={() => openModal(false)}
      >
        <div className="flex-column align-center text-center">
          <h2 className="f26">User does not exist</h2>
          <p className="mt-10">
            We cannot find an account with that email address.
          </p>
          <p> Please create a new account to get started.</p>
          <div className="flex-row mt-30">
            <Button
              size="medium"
              onClick={() => navigate('/signup')}
              text="Sign up"
              className="mr-10"
            />
            <Button
              size="medium"
              onClick={() => openModal(false)}
              text="Close"
              variant="reverse"
            />
          </div>
        </div>
      </ReactModal>
    </div>
  );
}
