function ArrowLeft({ style }) {
  const { fill = 'black', width = 16, height = 16 } = style || {};
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill={fill}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6847 9.24946L14.6847 6.58281L5.83807 6.58281L9.19727 3.17783L7.33702 1.29223L0.826093 7.89189L2.68634 9.77749L2.68679 9.77704L7.3381 14.4917L9.19835 12.6061L5.88681 9.24946L14.6847 9.24946Z"
        fill="#150828"
      />
    </svg>
  );
}

export default ArrowLeft;
