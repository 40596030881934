import './Signup.scss';

import { Helmet } from 'react-helmet-async';

import Landing from 'organisms/Landing';

import SignupDetails from './SignupDetails';

export default function Signup() {
  return (
    <>
      <Helmet title="Signup | PawCare" />
      <Landing>
        <SignupDetails />
      </Landing>
    </>
  );
}
