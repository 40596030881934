import { paymentInitSuccess } from 'store/reducers/main';
import { paymentMethodSuccess } from 'store/reducers/user';

import { fetchData } from './fetchData';

export const paymentMethod = (uuid) => (dispatch, getState) =>
  fetchData({
    endpoint: `user/${uuid}/payment/methods`,
    actions: [null, paymentMethodSuccess, null],
  })(dispatch, getState);

export const paymentInit = () => (dispatch, getState) =>
  fetchData({
    endpoint: 'payment/card/init',
    actions: [null, paymentInitSuccess, null],
    httpMethod: 'post',
  })(dispatch, getState);
