import './GroomerSelect.scss';

import { Helmet } from 'react-helmet-async';

import Checkout from 'organisms/Checkout';

import GroomerInfo from './GroomerInfo';

export default function GroomerSelect() {
  return (
    <>
      <Helmet title="Select a groomer for your booking | PawCare" />
      <Checkout className="p-0">
        <GroomerInfo />
      </Checkout>
    </>
  );
}
