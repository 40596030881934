import './Login.scss';

import { Helmet } from 'react-helmet-async';
import { useSearchParams } from 'react-router-dom';

import Checkout from 'organisms/Checkout';
import Landing from 'organisms/Landing';

import LoginInfo from './LoginInfo';

export default function Login() {
  const [searchParams] = useSearchParams();
  const showAppointmentPanel =
    searchParams.get('appointment') || searchParams.get('appointment-signup');

  const LayoutComponent = showAppointmentPanel ? Checkout : Landing;

  return (
    <>
      <Helmet title="Login | PawCare">
        <link rel="canonical" href="https://mypawcare.com/login" />
      </Helmet>
      <LayoutComponent>
        <LoginInfo showAppointmentPanel={showAppointmentPanel} />
      </LayoutComponent>
    </>
  );
}
