import './DefaultPaymentMethod.scss';

import { useSelector } from 'react-redux';
import classNames from 'classnames';

import Icon from 'atoms/icon';
import AddDiscount from 'organisms/AddDiscount';
import { getCardIcon } from 'util';

export function DefaultPaymentMethod({ isAddingDetails, hideDiscount }) {
  const { cards } = useSelector((state) => state.user.paymentMethod);
  const lastCard = cards?.[0] || {};

  return (
    <>
      <div
        className={classNames(
          'default-payment-method flex-row',
          isAddingDetails && 'default-payment-method--disabled',
        )}
      >
        <Icon name={getCardIcon(lastCard.brand)} />
        <div className="flex-column">
          <p className="f13">{`**** **** **** ${lastCard.last4}`}</p>
          <p className="grey-text f11">{`Expires ${lastCard.expiration.month}/${lastCard.expiration.year}`}</p>
        </div>
      </div>
      {!hideDiscount && <AddDiscount />}
    </>
  );
}
