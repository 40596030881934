function Close({ style }) {
  const { width = 20, height = 20, fill = '#e8e6e6' } = style || {};
  return (
    <svg width={width} height={height} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0711 3.75735L12.2426 0.92892L8 5.17156L3.75736 0.92892L0.928932 3.75735L5.17157 7.99999L0.928932 12.2426L3.75736 15.0711L8 10.8284L12.2426 15.0711L15.0711 12.2426L10.8284 7.99999L15.0711 3.75735Z"
        fill={fill}
      />
    </svg>
  );
}

export default Close;
