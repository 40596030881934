import './UploadPetAvatar.scss';

import { Helmet } from 'react-helmet-async';

import Landing from 'organisms/Landing';

import UploadPetAvatarDetails from './UploadPetAvatarDetails';

export default function UploadPetAvatar() {
  return (
    <>
      <Helmet title="Upload pet avatar | PawCare">
        <link rel="canonical" href="https://mypawcare.com/upload-avatar" />
      </Helmet>
      <Landing removeOutterPadding>
        <UploadPetAvatarDetails />
      </Landing>
    </>
  );
}
