export const identifyUser = (user) => {
  if (!user) return;

  const userData = {
    first_name: user.firstName,
    last_name: user.lastName,
    email: user.email,
    phone: user.phone?.localized,
  };

  // Klavyio
  if (process.env.REACT_APP_DOMAIN !== 'http://localhost:3000') {
    window.klaviyo.push(['identify', userData]);
  }
};
