import { createSlice } from '@reduxjs/toolkit';

import dummyData from './dummyMarketData.json';

const main = createSlice({
  name: 'main',
  initialState: {
    isLoading: null,
    paymentInitData: null,
    calendar: {
      dayOfWeek: [],
    },
    core: {
      age: [],
      breed: [],
      gender: [],
      hair: {
        length: [],
      },
      size: [],
      sterilization: [],
      type: [],
      vaccination: [],
    },
    geo: {
      country: [],
      countryCode: [],
      state: [],
      timeZone: [],
    },
    provider: {
      service: {
        tag: [],
      },
      type: [],
    },
    market: {
      default: {},
      illinois: {},
      chicago: {},
    },
    search: {
      appointmentDateMin: null,
    },
  },
  reducers: {
    bootstrapRequest(state) {
      state.isLoading = true;
    },
    bootstrapSuccess(state, { payload }) {
      const { calendar, core, geo, provider, market, search } = payload.data;

      const UStimeZones = [374, 519, 528, 498];
      const timeZones = geo.timeZone.reduce((acc, element) => {
        if (UStimeZones.includes(element.id)) {
          return [element, ...acc];
        }
        return [...acc, element];
      }, []);
      const countryCodes = geo.countryCode.map((el) => ({
        ...el,
        value: el.country,
      }));
      const unitedStates = countryCodes.find(
        (el) => el.country === 'United States',
      );
      const fromIndex = countryCodes.indexOf(unitedStates);
      const toIndex = 0;
      const element = countryCodes.splice(fromIndex, 1)[0];
      countryCodes.splice(toIndex, 0, element);

      state.market = market;
      state.calendar = calendar;
      state.core = {
        ...core,
        type: core.type.map((item, index) => core.type[(index + 1) % 2]),
      };
      state.geo = geo;
      state.geo.countryCode = countryCodes;
      state.geo.timeZone = timeZones;
      state.provider = provider;
      state.isLoading = false;
      state.search = search;
    },
    bootstrapFailure(state) {
      state.isLoading = false;
    },
    paymentInitSuccess(state, { payload }) {
      state.paymentInitData = payload.data;
    },
  },
});

const { actions, reducer } = main;

export const {
  bootstrapRequest,
  bootstrapSuccess,
  bootstrapFailure,
  paymentInitSuccess,
} = actions;

export default reducer;
