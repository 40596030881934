import './ViewMoreModal.scss';

import { useEffect } from 'react';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { loadProviderLocation } from 'actions/providers';
import SlotsCalendar from 'organisms/SlotsCalendar';

export default function ViewMoreModal({
  isOpen,
  setIsOpen,
  locationUuid,
  onSelectSlot,
  dateStart,
  disablePetCreation,
}) {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.location);

  useEffect(() => {
    if (isOpen) {
      dispatch(loadProviderLocation(locationUuid));
    }
  }, [locationUuid]);

  if (!locationUuid || isLoading) return;

  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isOpen}
      className="view-more-modal"
      onRequestClose={() => {
        setIsOpen(false);
        document.body.classList.remove('ReactModal__Body--open');
      }}
    >
      <SlotsCalendar
        ignoreLocationUser
        locationUuid={locationUuid}
        onSelectSlot={onSelectSlot}
        dateStart={dateStart}
        disablePetCreation={disablePetCreation}
        className="view-more-content"
      />
    </ReactModal>
  );
}

ViewMoreModal.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  locationUuid: PropTypes.string,
  onSelectSlot: PropTypes.func,
  dateStart: PropTypes.string,
  /* Disable pet creation modal when clicking on a slot. See Slot component for more info. */
  disablePetCreation: PropTypes.bool,
};
