export function getMonthStartAndEndDate(dateString) {
  // Parse the input date as a UTC date to avoid local time zone adjustments
  const givenDate = new Date(`${dateString}T00:00:00Z`);

  // Start date of the month as UTC
  const startDate = new Date(
    Date.UTC(givenDate.getUTCFullYear(), givenDate.getUTCMonth(), 1),
  );

  // End date of the month as UTC
  // Setting the day to 0 gets the last day of the previous month, so by setting the month to "month + 1"
  // and the day to 0, we get the last day of the "current" month.
  const endDate = new Date(
    Date.UTC(givenDate.getUTCFullYear(), givenDate.getUTCMonth() + 1, 0),
  );

  // Convert to YYYY-MM-DD format without considering time zones, since we're working in UTC
  const formatAsUTCDate = (date) => date.toISOString().split('T')[0];

  return {
    startDate: formatAsUTCDate(startDate),
    endDate: formatAsUTCDate(endDate),
  };
}
