const { createSlice } = require('@reduxjs/toolkit');

const lastUserAppointment = createSlice({
  name: 'lastUserAppointment',
  initialState: {
    isLoading: false,
    location: {},
    locationUser: {},
  },
  reducers: {
    lastUserAppointmentRequest(state) {
      state.isLoading = true;
    },
    lastUserAppointmentSuccess(state, { payload }) {
      return {
        ...payload.data,
        isLoading: false,
      };
    },
    lastUserAppointmentFailure(state) {
      state.isLoading = false;
    },
  },
});

const { actions, reducer } = lastUserAppointment;

export const {
  lastUserAppointmentRequest,
  lastUserAppointmentSuccess,
  lastUserAppointmentFailure,
} = actions;

export default reducer;
