import { Helmet } from 'react-helmet-async';

import Landing from 'organisms/Landing';

import FinishSignupMFAInfo from './FinishSignupMFAInfo';

export default function FinishSignupMFA() {
  return (
    <>
      <Helmet title="Phone verification | PawCare" />
      <Landing removeOutterPadding>
        <FinishSignupMFAInfo />
      </Landing>
    </>
  );
}
