function Search({ style }) {
  const { width = 16, height = 18, fill = '#63637B' } = style || {};
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.70335 14.3987C8.73091 14.3992 9.74648 14.1782 10.681 13.7507L13.3198 17.4913C13.5667 17.8418 13.9818 18.0338 14.4087 17.9951C14.8355 17.9564 15.2093 17.6929 15.3892 17.3036C15.5691 16.9145 15.5278 16.4589 15.2808 16.1085L12.669 12.4039C13.878 11.248 14.6468 9.70716 14.8434 8.04584C15.04 6.3845 14.6522 4.70671 13.7465 3.30036C12.8408 1.89406 11.4739 0.847196 9.88047 0.339426C8.28694 -0.168145 6.56646 -0.104852 5.01491 0.518509C3.46306 1.14197 2.17669 2.28649 1.3768 3.75566C0.576859 5.22465 0.313302 6.92657 0.631434 8.56889C0.949541 10.2112 1.82952 11.6916 3.12006 12.7554C4.41061 13.8192 6.03126 14.4003 7.7035 14.3987L7.70335 14.3987ZM7.70335 2.4C8.97583 2.4 10.1962 2.90562 11.0961 3.8057C11.996 4.70578 12.5013 5.92657 12.5013 7.19959C12.5013 8.4726 11.9958 9.69327 11.0961 10.5935C10.1963 11.4937 8.97593 11.9992 7.70335 11.9992C6.43077 11.9992 5.21052 11.4935 4.31063 10.5935C3.41073 9.69339 2.90541 8.4726 2.90541 7.19959C2.90541 5.92657 3.41086 4.70591 4.31063 3.8057C5.2104 2.9055 6.43077 2.4 7.70335 2.4Z"
        fill={fill}
      />
    </svg>
  );
}

export default Search;
