import { useLocalStorage } from 'react-use';

export const EXTRA_SIGNUP_STEP_STORAGE_KEY = 'extra-signup-step';

export default function useSignupSteps() {
  const [extraSignupStep, setExtraSignupStep, deleteExtraSignupStep] =
    useLocalStorage(EXTRA_SIGNUP_STEP_STORAGE_KEY, false);

  const numberOfSteps = extraSignupStep ? 6 : 5;

  return [numberOfSteps, setExtraSignupStep, deleteExtraSignupStep];
}
