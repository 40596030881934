import './Banner.scss';

import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import Icon from 'atoms/icon';
import { displayPromoBanner } from 'store/reducers/invoice';

export default function Banner({ text }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const hideBannerUrls = ['/about', '/blog', '/privacy.html', '/terms'];

  if (hideBannerUrls.includes(location?.pathname)) {
    return null;
  }

  return (
    <div className="w-100 banner flex-row f13 justify-center">
      <p>{text}</p>
      <Icon
        name="close"
        onClick={() => dispatch(displayPromoBanner(false))}
        style={{ fill: 'black', width: 12, height: 12 }}
        className="ml-10 pointer"
      />
    </div>
  );
}

Banner.propTypes = {
  text: PropTypes.string,
};
