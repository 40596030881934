import './Loader.scss';

import classNames from 'classnames';

function Loader({ variant = 'default' }) {
  return (
    <div className="loader">
      {[1, 2, 3, 4].map((el) => (
        <div
          key={el}
          className={classNames(`row flex-row mb-10 row-${variant}`)}
        >
          {variant !== 'services' && <span className="row-avatar" />}
          <div className="flex-column w-100">
            <span className="description" />
            <span className="description mt-5" />
            <span className="description mt-5" />
          </div>
        </div>
      ))}
    </div>
  );
}

export default Loader;
