import { Helmet } from 'react-helmet-async';

import Landing from 'organisms/Landing';

import NewPasswordInfo from './NewPasswordInfo';

export default function NewPassword() {
  return (
    <>
      <Helmet title="New password | PawCare">
        <link rel="canonical" href="https://mypawcare.com/password-reset" />
      </Helmet>
      <Landing>
        <NewPasswordInfo />
      </Landing>
    </>
  );
}
