import { Helmet } from 'react-helmet-async';
import { useSearchParams } from 'react-router-dom';

import Checkout from 'organisms/Checkout';
import Landing from 'organisms/Landing';

import AddAddressDetails from './AddAddressDetails';

export default function AddAddress() {
  const [searchParams] = useSearchParams();
  const showAppointmentPanel = searchParams.get('appointment');

  const LayoutComponent = showAppointmentPanel ? Checkout : Landing;
  return (
    <>
      <Helmet title="Create pet | PawCare">
        <link rel="canonical" href="https://mypawcare.com/signup/address" />
      </Helmet>
      <LayoutComponent>
        <AddAddressDetails showAppointmentPanel={showAppointmentPanel} />
      </LayoutComponent>
    </>
  );
}
