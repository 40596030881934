import { setAdminData } from 'store/reducers/adminUser';
import {
  lastUserAppointmentFailure,
  lastUserAppointmentRequest,
  lastUserAppointmentSuccess,
} from 'store/reducers/lastUserAppointment';
import { requestFulfilled, requestOngoing } from 'store/reducers/ui';
import {
  userFailure,
  userRequest,
  userSetCredentials,
  userSuccess,
} from 'store/reducers/user';

import { fetchData } from './fetchData';

export const loadUser = (userId, actions) => (dispatch, getState) => {
  const { uuid, token } = getState().user;
  return fetchData({
    endpoint: `user/${userId || uuid}`,
    actions: actions || [userRequest, userSuccess, userFailure],
  })(dispatch, getState).then(({ success, data }) => {
    if (success) {
      const { isAdmin, name } = data.data;
      const { uuid: adminUuid } = getState().adminUser;
      // Set admin data if user is admin & there's no other admin data stored (this
      // would mean you're impersonating an admin)
      if (isAdmin && !adminUuid) dispatch(setAdminData({ uuid, token, name }));
    }
    return { success, data };
  });
};

export const editUser = (body, userId, actions) => (dispatch, getState) => {
  const { uuid } = getState().user;

  return fetchData({
    endpoint: `user/${userId || uuid}`,
    actions: actions || [userRequest, userSuccess, userFailure],
    httpMethod: 'patch',
    body,
  })(dispatch, getState);
};

export const impersonateUser = (uuid) => (dispatch, getState) =>
  fetchData({
    endpoint: `user/${uuid}/impersonate`,
    actions: [null, null, null],
  })(dispatch, getState).then(({ success, data }) => {
    if (success) {
      dispatch(userSetCredentials(data.data));
      dispatch(loadUser(data.data.uuid));
    }
    return { success, data };
  });

export const loadUserAppointments =
  ({ luid }) =>
  (dispatch, getState) => {
    const { uuid } = getState().user;
    const luidQs = luid ? `?location_uuid=${luid}` : '';
    return fetchData({
      endpoint: `user/${uuid}/appointments${luidQs}`,
      actions: [null, null, null],
    })(dispatch, getState);
  };

export const loadLastUserAppointment = () => (dispatch, getState) => {
  const { uuid } = getState().user;
  return fetchData({
    endpoint: `user/${uuid}/appointments/last`,
    actions: [
      lastUserAppointmentRequest,
      lastUserAppointmentSuccess,
      lastUserAppointmentFailure,
    ],
    displayErrorToast: false,
  })(dispatch, getState);
};

export const signWaiver = () => (dispatch, getState) => {
  const { uuid } = getState().user;
  dispatch(requestOngoing());
  fetchData({
    endpoint: 'user/waiver',
    actions: [null, null, null],
    httpMethod: 'post',
  })(dispatch, getState).then(async ({ success }) => {
    if (success) await dispatch(loadUser(uuid));
    dispatch(requestFulfilled());
    return { success };
  });
};

export const deleteWaiver = () => (dispatch, getState) => {
  const { uuid } = getState().user;
  dispatch(requestOngoing());
  fetchData({
    endpoint: 'user/waiver',
    actions: [null, null, null],
    httpMethod: 'delete',
  })(dispatch, getState).then(async ({ success }) => {
    if (success) await dispatch(loadUser(uuid));
    dispatch(requestFulfilled());
    return { success };
  });
};
