import {
  petAppointmentsFailure,
  petAppointmentsRequest,
  petAppointmentsSuccess,
} from 'store/reducers/petAppointments';
import { requestFulfilled, requestOngoing } from 'store/reducers/ui';

import { fetchData } from './fetchData';

export const createPet = (body, actions) => (dispatch, getState) =>
  fetchData({
    endpoint: 'animal',
    actions: actions || [requestOngoing, requestFulfilled, requestFulfilled],
    httpMethod: 'post',
    body,
  })(dispatch, getState);

export const editPet = (body, petId) => (dispatch, getState) =>
  fetchData({
    endpoint: `animal/${petId}`,
    actions: [null, null, null],
    httpMethod: 'patch',
    body,
  })(dispatch, getState);

export const uploadPetAvatar = (body, petUuid) => (dispatch, getState) =>
  fetchData({
    endpoint: `animal/${petUuid}/image/upload`,
    actions: [null, null, null],
    httpMethod: 'post',
    isMedia: true,
    body,
  })(dispatch, getState);

export const loadPetAppointments = (animal) => (dispatch, getState) => {
  const { uuid: auid } = getState().activePet || {};
  if (!animal?.uuid && !auid) {
    return;
  }
  return fetchData({
    endpoint: `animal/${animal?.uuid || auid}/appointments`,
    actions: [
      petAppointmentsRequest,
      petAppointmentsSuccess,
      petAppointmentsFailure,
    ],
  })(dispatch, getState);
};
