/* eslint-disable react/no-array-index-key */
import './Header.scss';

import { useSelector } from 'react-redux';

import DefaultAvatar from 'atoms/default-avatar';
import { prettifyDate } from 'util';

export default function Header({ data, isMobile }) {
  const { name, type = [], links } = data;
  const { dateStart } = useSelector((state) => state.requestAppointmentForm);

  return (
    <div className="header-component mb-20">
      <div className="avatar-section">
        <DefaultAvatar
          className="mb-15"
          avatar={links?.image?.desktop?.large}
        />
      </div>
      <h2>{name}</h2>
      {isMobile ? (
        <p className="text-grey-dark">{prettifyDate(dateStart)}</p>
      ) : (
        <p className="mb-5">{type.map((el) => el.value).join(', ')}</p>
      )}
    </div>
  );
}
