import { createSlice } from '@reduxjs/toolkit';

export const REQUEST_APPOINTMENT_FORM_STORAGE_KEY =
  'state:request-appointment-form';

const updateLocalStorage = (state) => {
  localStorage.setItem(
    REQUEST_APPOINTMENT_FORM_STORAGE_KEY,
    JSON.stringify(state),
  );
};

const initialState = {
  animal: null,
  location: null,
  locationUser: {},
  service: null,
  dateStart: null,
  provider: null,
  providerPath: null,
};
const requestAppointmentForm = createSlice({
  name: 'requestAppointmentForm',
  initialState,
  reducers: {
    editForm(state, { payload }) {
      const { key, value } = payload;
      state[key] = value;
      updateLocalStorage(state);
    },
    initializeForm(state, { payload }) {
      return payload;
    },
    clearForm(state) {
      updateLocalStorage(initialState);
      sessionStorage.removeItem('requestAppointmentForm');
      return initialState;
    },
  },
});

const { actions, reducer } = requestAppointmentForm;

export const { editForm, initializeForm, clearForm } = actions;

export default reducer;
