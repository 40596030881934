function Phone({ style }) {
  const { fill = '#150828' } = style || {};
  return (
    <svg
      width="22"
      height="24"
      viewBox="0 0 22 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.66245 7.23159L6.72415 9.07128C6.64586 9.14557 6.59873 9.23954 6.58497 9.33698C6.5713 9.43469 6.59072 9.53814 6.64559 9.63103C7.01472 10.2567 7.43355 10.8822 7.90129 11.5031C8.36159 12.1136 8.86818 12.7182 9.42041 13.3119C9.97812 13.9114 10.5464 14.4665 11.1207 14.9753C11.7033 15.4916 12.2967 15.9645 12.8962 16.3919C12.9856 16.4556 13.0893 16.4836 13.1905 16.4771C13.2905 16.4705 13.3887 16.4297 13.469 16.355L15.437 14.5244C15.603 14.3699 15.8082 14.2822 16.0197 14.2644C16.2294 14.2467 16.4457 14.2992 16.636 14.4241C17.3806 14.9129 18.1415 15.3484 18.9103 15.7284C19.6831 16.1101 20.462 16.4338 21.2389 16.6975C21.4489 16.769 21.6209 16.9041 21.7369 17.0778C21.8532 17.2515 21.9129 17.4618 21.8986 17.6824C21.8523 18.4075 21.8037 19.0924 21.7517 19.7607C21.7034 20.3813 21.6455 21.066 21.5772 21.8275C21.5355 22.2946 21.3123 22.7027 20.9835 22.9866C20.6538 23.2711 20.218 23.4328 19.7515 23.4063C17.4571 23.2757 15.0167 22.5691 12.642 21.3323C10.3635 20.1454 8.14126 18.4686 6.16352 16.3422C4.19095 14.2216 2.68126 11.8876 1.66159 9.53097C0.599294 7.0763 0.0689978 4.59573 0.100708 2.30323C0.10727 1.82929 0.303871 1.40126 0.616958 1.09181C0.929508 0.783276 1.35924 0.592059 1.83321 0.591522C2.55435 0.590337 3.24497 0.592981 3.92947 0.599543C4.6189 0.606106 5.29977 0.616679 5.99629 0.631535C6.21687 0.636093 6.41912 0.71065 6.58026 0.837525C6.74195 0.964673 6.86254 1.14349 6.91887 1.35641C7.12687 2.14099 7.39282 2.93041 7.71512 3.71728C8.03514 4.49813 8.41139 5.27842 8.84287 6.05043C8.95261 6.24703 8.98943 6.46441 8.95799 6.67158C8.92599 6.87903 8.82601 7.07646 8.66259 7.2313L8.66245 7.23159Z"
        fill={fill}
      />
    </svg>
  );
}

export default Phone;
