import { createSlice } from '@reduxjs/toolkit';

const location = createSlice({
  name: 'location',
  initialState: {
    services: [],
    inventory: {},
  },
  reducers: {
    locationRequest(state) {
      state.isLoading = true;
    },
    locationSuccess(state, { payload }) {
      return {
        ...state,
        ...payload.data,
        isLoading: false,
      };
    },
    locationFailure(state) {
      state.isLoading = false;
    },
    locationInventoryRequest(state) {
      state.isLoading = true;
    },
    locationInventorySuccess(state, { payload }) {
      state.inventory = payload.data;
      state.isLoading = false;
    },
    locationInventoryFailure(state) {
      state.isLoading = false;
    },
  },
});

const { actions, reducer } = location;

export const {
  locationRequest,
  locationSuccess,
  locationFailure,
  locationInventoryRequest,
  locationInventorySuccess,
  locationInventoryFailure,
} = actions;

export default reducer;
