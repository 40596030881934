import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import paymentRedirectionMiddleware from 'helpers/paymentRedirection';

import { loadAvailableGroomers } from 'actions/groomers';
import Button from 'atoms/button';
import useSignupSteps from 'hooks/useSignupSteps';
import ListLoader from 'organisms/Loaders/ListLoader';
import ProgressBar from 'organisms/ProgressBar';
import { editForm } from 'store/reducers/requestAppointmentForm';
import { getRandomGroomer, isEmpty } from 'util';

import AllGroomers from './AllGroomers';
import dummyData from './data.json';
import GroomersPerAvailability from './GroomersPerAvailability';
import {
  filterGroomersByAnimalType,
  filterGroomersWithoutAvailability,
} from './util';

function GroomerInfo() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { location, dateStart, locationUser, service } = useSelector(
    (state) => state.requestAppointmentForm,
  );
  const { type } = useSelector((state) => state.petAppointments.activePet);
  const { locationUser: lastBookedGroomer } = useSelector(
    (state) => state.lastUserAppointment,
  );
  const { type: petBeingCreatedType } = useSelector(
    (state) => state.createPetForm.data,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [allGroomers, setAllGroomers] = useState([]);
  const [currentAvailableGroomers, setCurrentDateGroomers] = useState([]);
  const [futureAvailableGroomers, setFutureDateGroomers] = useState([]);
  const userPetType = type || petBeingCreatedType;
  const [numberOfSteps] = useSignupSteps();
  const [hasPreSelectedDate, setHasPreSelectedDate] = useState(false);

  useEffect(() => {
    setHasPreSelectedDate(!!dateStart);
    const [date, time] = dateStart
      ? dateStart.split('T')
      : [dayjs().format('YYYY-MM-DD'), dayjs().format('HH:mm:ss')];

    setIsLoading(true);
    dispatch(
      loadAvailableGroomers({
        luid: location,
        date,
        time,
        suid: service?.uuid,
      }),
    ).then(({ success, data }) => {
      setIsLoading(false);
      if (success) {
        const groomersFilteredByAvailability =
          filterGroomersWithoutAvailability(data.data);

        const groomersFilteredByAnimalType = filterGroomersByAnimalType(
          groomersFilteredByAvailability,
          userPetType,
        );

        const groomers = groomersFilteredByAnimalType;

        const currentGroomers = [];
        const futureGroomers = [];
        groomers.forEach((groomer) => {
          if (groomer.canHandleRequestedSlot) {
            currentGroomers.push(groomer);
          } else futureGroomers.push(groomer);
        });

        if (
          (dateStart &&
            futureGroomers.length === 0 &&
            currentGroomers.length === 1) ||
          (!dateStart && groomers.length === 1)
        ) {
          const availableGroomer = groomers[0];
          const { nextAvailableDate, nextAvailableTime } =
            availableGroomer || {};
          dispatch(
            editForm({
              key: 'locationUser',
              value: availableGroomer,
            }),
          );
          dispatch(
            editForm({
              key: 'dateStart',
              value: `${nextAvailableDate}T${nextAvailableTime}`,
            }),
          );
          navigate('/booking-select-slot');
          return;
        }

        setAllGroomers(groomers);
        setFutureDateGroomers(futureGroomers);
        setCurrentDateGroomers(currentGroomers);

        // If lastBookedGroomer is in this provider, select it
        if (lastBookedGroomer?.uuid) {
          const groomer = groomers.filter(
            (groom) => groom.user.uuid === lastBookedGroomer.uuid,
          )[0];
          if (groomer) selectGroomer(groomer);
        }
      }
    });
  }, []);

  const selectGroomer = (groomer) => {
    dispatch(editForm({ key: 'locationUser', value: groomer || 'any' }));
  };

  const onNext = () => {
    if (locationUser !== 'any' && !locationUser.canHandleRequestedSlot) {
      const { nextAvailableDate, nextAvailableTime } = locationUser;
      dispatch(
        editForm({
          key: 'dateStart',
          value: `${nextAvailableDate}T${nextAvailableTime}`,
        }),
      );
      navigate('/booking-select-slot');
    }

    if (hasPreSelectedDate) {
      if (locationUser === 'any') {
        const randomGroomer = getRandomGroomer(allGroomers);
        selectGroomer(randomGroomer);
      }
    }

    navigate('/booking-select-slot');
  };

  return (
    <div className="booking-pet-container flex-column align-start justify-center">
      <div className="mobile-page-container w-100">
        <h1 className="mb-12">Groomer details</h1>
        <ProgressBar
          maxSteps={numberOfSteps}
          currentStep={3}
          className="mb-32 width-100"
        />
        {isLoading ? (
          <ListLoader />
        ) : (
          <div className="pb-100-mobile">
            {dateStart ? (
              <GroomersPerAvailability
                currentAvailableGroomers={currentAvailableGroomers}
                futureAvailableGroomers={futureAvailableGroomers}
                selectGroomer={selectGroomer}
                userPetType={userPetType}
              />
            ) : (
              <AllGroomers
                groomers={allGroomers}
                selectGroomer={selectGroomer}
                userPetType={userPetType}
              />
            )}
          </div>
        )}
      </div>
      {allGroomers.length > 0 && (
        <div className="mobile-main-action-btn">
          <Button
            text="Next"
            onClick={onNext}
            variant={isEmpty(locationUser) ? 'disabled' : 'default'}
          />
        </div>
      )}
    </div>
  );
}

export default GroomerInfo;
