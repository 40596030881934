import './RescheduleConfirmationModal.scss';

import ReactModal from 'react-modal';
import dayjs from 'dayjs';

import Button from 'atoms/button';

export default function RescheduleConfirmationModal({
  isOpen,
  setIsOpen,
  dateStart,
  onConfirm,
  onCancel,
}) {
  const handleClose = () => {
    setIsOpen(false);
    onCancel();
  };

  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      className="reschedule-appointment-modal"
    >
      <div className="text-center reschedule-appointment-modal-content">
        <h2 className="mb-15 f26">Reschedule appointment</h2>
        <p className="mb-32 f15">
          Please confirm your new appointment on{' '}
          <strong>{dayjs(dateStart).format('dddd, MMMM D, h:mm a')}</strong>.
          Once confirmed, your existing appointment will be removed.
        </p>
        <div className="flex-row justify-between reschedule-appointment-modal-content-actions margin-center">
          <Button
            size="medium"
            onClick={() => onConfirm(dateStart)}
            text="Yes, reschedule"
          />
          <Button
            size="medium"
            variant="reverse"
            onClick={handleClose}
            text="Not now"
          />
        </div>
      </div>
    </ReactModal>
  );
}
