/* eslint-disable react/no-unescaped-entities */
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { triggerPasswordChangeEmail } from 'actions/authentication';
import Button from 'atoms/button';
import Input from 'atoms/input';
import toast from 'atoms/toast';

export default function ResetPasswordInfo() {
  const dispatch = useDispatch();
  const { email: userEmail } = useSelector((state) => state.user.data);
  const [email, setEmail] = useState(userEmail);

  const onChangePassword = (e) => {
    e.preventDefault();
    dispatch(triggerPasswordChangeEmail({ email })).then(({ success }) => {
      if (success) {
        toast({
          text: 'Check your email and follow the link to change your password.',
        });
      }
    });
  };

  return (
    <div className="login">
      <h1 className="mb-10">Change password</h1>
      <p className="mb-20">
        We'll send you a link to follow to set your new password.
      </p>
      <form onSubmit={onChangePassword}>
        <Input
          id="email"
          label="Email"
          type="email"
          onChange={(value) => setEmail(value)}
          value={email}
        />
        <Button className="mt-25" type="submit" text="Send email" />
      </form>
    </div>
  );
}
