/* eslint-disable camelcase */
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { editUser } from 'actions/user';
import Button from 'atoms/button';
import LocationSearch from 'atoms/location-search';
import ProgressBar from 'organisms/ProgressBar';
import { editAdvancedSearch } from 'store/reducers/advancedSearch';

export default function AddAddressDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { address_1, locality, region, latitude, longitude, postalCode } =
    useSelector((state) => state.advancedSearch);
  const invalidAddress = address_1 === 'invalid_address';
  const canContinue = address_1 && !invalidAddress;

  const onSubmit = (e) => {
    e.preventDefault();

    if (!canContinue) return;

    dispatch(
      editUser({
        address: {
          address: address_1,
          country_id: 238,
          locality,
          region,
          postalCode,
          latitude,
          longitude,
        },
      }),
    ).then(({ success }) => {
      if (success) {
        navigate('/booking-payment');
      }
    });
  };

  return (
    <div className="create-pet">
      <div className="mobile-page-title">
        <h1 className="mb-0 f-14">Mobile grooming details</h1>
        <p className="mb-25">
          Please confirm your address below for your mobile appointment.
        </p>
        <ProgressBar maxSteps={6} currentStep={5} />
      </div>
      <form onSubmit={onSubmit} className="h-100">
        <div className="mobile-page-container">
          <LocationSearch
            label="Address for mobile service"
            editLocation={editAdvancedSearch}
            displayErrorCaption
            hasError={invalidAddress}
          />
        </div>
        <div className="mobile-main-action-btn">
          <Button
            variant={canContinue ? 'default' : 'disabled'}
            text="Next"
            type="submit"
            className="mt-25"
          />
        </div>
      </form>
    </div>
  );
}
