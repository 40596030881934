import { createTheme, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { GoogleMapsProvider } from 'config/GoogleMapsProvider';

import AppContainer from './config/AppContainer';

const theme = createTheme({
  typography: {
    fontFamily: ['Poppins', 'Helvetica', 'Arial', 'sans-serif'].join(','),
  },
});

function App() {
  return (
    <GoogleMapsProvider>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <AppContainer />
        </LocalizationProvider>
      </ThemeProvider>
    </GoogleMapsProvider>
  );
}

export default App;
