import { useEffect, useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import useWindowDimensions from 'helpers/useWindowDimensions';

import Button from 'atoms/button';
import Dropdown from 'atoms/dropdown';
import Input from 'atoms/input';
import SelectButton from 'atoms/select-button';
import ProgressBar from 'organisms/ProgressBar';
import { editForm } from 'store/reducers/createPetForm';
import { trackPetInfo } from 'util/tracking/events';

export default function PetDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const isMobile = width < 600;
  const { breed, name, size, type } = useSelector(
    (state) => state.createPetForm.data,
  );
  const { core } = useSelector((state) => state.main);
  const breedOptions = core.breed.filter((el) => el.animalTypeId === type?.id);
  const canSubmit = size && type && name && breed;
  const [showType, setTypeDisplay] = useState(true);
  const [showSize, setSizeDisplay] = useState(true);

  useEffect(() => {
    dispatch(trackPetInfo());
    if (size) setSizeDisplay(false);
    if (type) setTypeDisplay(false);
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!canSubmit) return;

    navigate('/booking-select-groomer');
  };

  function startsWithFilter(option, props) {
    const optionWords = option[props.labelKey].toLowerCase().split(/\s+/); // Split option label into words
    const inputWords = props.text.toLowerCase().split(/\s+/); // Split user input into words

    return inputWords.every((inputWord) =>
      optionWords.some((optionWord) => optionWord.startsWith(inputWord)),
    );
  }

  return (
    <div className="booking-signup">
      <div className="mobile-page-title">
        <h1 className="mb-12">Pet details</h1>
        <ProgressBar maxSteps={6} currentStep={2} className="mb-32" />
      </div>
      <form onSubmit={onSubmit} className="h-100">
        <div className="mobile-page-container">
          <Input
            id="name"
            label="Pet name"
            className={classNames('w-100 mb-25', !isMobile && 'mt-30')}
            onChange={(value) => dispatch(editForm({ key: 'name', value }))}
            value={name}
          />
          {showType && (
            <>
              <h3 className="mb-15">Pet type</h3>
              <div className="flex-row mb-30">
                {core.type.map((el, i) => (
                  <SelectButton
                    key={el.value}
                    label="Pet type"
                    className={i === 0 ? 'w-100 mr-15' : 'w-100'}
                    onClick={(value) => {
                      dispatch(editForm({ key: 'type', value }));
                      dispatch(editForm({ key: 'breed', value: null }));
                    }}
                    isActive={type?.value === el.value}
                    value={el}
                  >
                    <p>
                      {el.value === 'Dog' ? '🐶' : '🐱'} {el.value}
                    </p>
                  </SelectButton>
                ))}
              </div>
            </>
          )}
          {showSize && (
            <Dropdown
              label="Size"
              placeholder="Select"
              className="w-100 mt-25"
              items={core.size}
              onClick={(value) => dispatch(editForm({ key: 'size', value }))}
              value={size?.value}
            />
          )}

          <h3 className="mb-15">Breed</h3>
          <Typeahead
            id="breed"
            labelKey="value"
            options={breedOptions}
            placeholder="Select"
            filterBy={startsWithFilter}
            onChange={(value) =>
              dispatch(editForm({ key: 'breed', value: value[0] }))
            }
            selected={breed ? [breed] : []}
            className="w-100 input-field"
          />
        </div>
        <div className="mobile-main-action-btn">
          <Button
            text="Next"
            type="submit"
            className="mt-25"
            variant={canSubmit ? 'default' : 'disabled'}
          />
        </div>
      </form>
    </div>
  );
}
