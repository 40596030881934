import './LocationService.scss';

import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import ServiceBlock from 'atoms/service-block';
import { editForm } from 'store/reducers/requestAppointmentForm';

export function LocationService() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const locationData = useSelector((state) => state.location);
  const serviceInfo = locationData.services.filter(
    (service) => service.uuid === params.serviceId,
  )[0];

  const onSelectService = () => {
    dispatch(editForm({ key: 'service', value: serviceInfo }));
    navigate('/booking-select-groomer');
  };

  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href={`${process.env.REACT_APP_DOMAIN}${location.pathname}`}
        />
      </Helmet>
      <div className="provider-service flex-column">
        <ServiceBlock
          actionsOnHover
          displayFullDescripton
          onSelectService={onSelectService}
          className="services-service"
          key={serviceInfo?.uuid}
          service={serviceInfo}
          location={locationData}
        />
      </div>
    </>
  );
}
