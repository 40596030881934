import { Helmet } from 'react-helmet-async';

import Checkout from 'organisms/Checkout';

import SlotSelectInfo from './SlotSelectInfo';

export default function SlotSelect() {
  return (
    <>
      <Helmet title="Select a time for your booking | PawCare" />
      <Checkout className="p-0">
        <SlotSelectInfo />
      </Checkout>
    </>
  );
}
