function Cat({ style }) {
  const { width = 56, height = 56 } = style;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.9222 8.86667C14.9276 8.86667 4.35606 19.4387 4.35606 32.4333C4.35606 45.4279 14.9276 56 27.9222 56C40.9168 56 51.4889 45.4279 51.4889 32.4333C51.4889 19.4387 40.9168 8.86667 27.9222 8.86667ZM16.0595 25.1502C16.0595 23.64 17.2841 22.4157 18.7941 22.4157C20.3043 22.4157 21.5287 23.6402 21.5287 25.1502C21.5287 26.6604 20.3041 27.8848 18.7941 27.8848C17.2841 27.885 16.0595 26.6604 16.0595 25.1502ZM31.6348 47.5414C30.1003 47.5414 28.7682 46.8128 27.9252 45.7039C27.0895 46.8126 25.7741 47.5414 24.282 47.5414C21.6873 47.5414 19.6351 45.4886 19.6351 42.9657H21.6124C21.6124 44.3981 22.7773 45.5636 24.2103 45.5636C25.7148 45.5636 26.8803 44.3981 26.8803 42.9657V39.1428C26.4397 39.0022 26.0433 38.7782 25.7709 38.4497L25.4958 38.1181L23.0535 35.1758C22.1021 34.0296 23.2961 32.6004 25.2033 32.6034L30.6498 32.6128C32.557 32.6158 33.746 34.0488 32.791 35.1926L30.3481 38.1157L30.0625 38.458C29.7919 38.7815 29.4018 39.0031 28.9667 39.1431V42.9673C28.9667 44.3997 30.1315 45.5652 31.5646 45.5652C33.0691 45.5652 34.2345 44.3997 34.2345 42.9673H36.2124C36.2108 45.4895 34.1582 47.5415 31.6351 47.5415L31.6348 47.5414ZM37.0507 27.8849C35.5405 27.8849 34.3162 26.6603 34.3162 25.1503C34.3162 23.6401 35.5408 22.4158 37.0507 22.4158C38.5609 22.4158 39.7853 23.6403 39.7853 25.1503C39.7855 26.6603 38.5609 27.8849 37.0507 27.8849Z"
        fill="white"
      />
      <path
        d="M17.2713 8.1397L3.13351 1.47677C1.74083 0.820428 0.159887 1.91782 0.287799 3.45209L2.1715 26.0467C4.16459 18.0133 9.82748 11.4162 17.2713 8.1397Z"
        fill="white"
      />
      <path
        d="M38.5731 8.1397C46.0165 11.4163 51.6797 18.0123 53.6724 26.0462L55.5563 3.45216C55.6842 1.91787 54.1032 0.820463 52.7105 1.47683L38.5731 8.1397Z"
        fill="white"
      />
    </svg>
  );
}

export default Cat;
