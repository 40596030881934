import adminUser from './adminUser';
import advancedSearch from './advancedSearch';
import authentication from './authentication';
import createPetForm from './createPetForm';
import hardInventory from './hardInventory';
import invoice from './invoice';
import lastUserAppointment from './lastUserAppointment';
import location from './location';
import main from './main';
import petAppointments from './petAppointments';
import provider from './provider';
import providerCalendar from './providerCalendar';
import providers from './providers';
import providersSearch from './providersSearch';
import requestAppointmentForm from './requestAppointmentForm';
import signupForm from './signupForm';
import ui from './ui';
import user from './user';

const rootReducer = {
  adminUser,
  advancedSearch,
  authentication,
  createPetForm,
  hardInventory,
  invoice,
  lastUserAppointment,
  location,
  main,
  petAppointments,
  provider,
  providerCalendar,
  providers,
  providersSearch,
  requestAppointmentForm,
  signupForm,
  ui,
  user,
};

export default rootReducer;
