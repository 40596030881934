import './AboutUs.scss';

import { Helmet } from 'react-helmet-async';

import AboutImg from 'images/about.png';
import Kunal from 'images/kunal.png';
import StruckLogo from 'images/struck-studio-logo.webp';
import Team from 'images/team.webp';
import { Footer } from 'organisms/Footer';

import { cards } from './util';

export default function AboutUs() {
  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://mypawcare.com/about" />
      </Helmet>
      <div className="flex-column w-100">
        <div className="about-page w-100">
          <div className="about-page-container">
            <img className="w-100" src={AboutImg} alt="decorative" />
            <h1>
              Welcome to PawCare, your one-stop shop for all your pet grooming
              needs.
            </h1>
            <p className="mt-20">
              Our mission is to empower every pet owner with trusted and easily
              accessible pet care services. At PawCare, we understand that your
              pets are more than just animals - they’re family. That’s why we’ve
              built a network of experienced and compassionate groomers who are
              dedicated to providing the highest level of care for your furry
              friends. Our easy-to-use platform allows you to search for
              groomers near you, book appointments, and manage all your pet
              information in one place. With PawCare, you can rest assured that
              your pets are in good hands. Thank you for choosing PawCare.
            </p>
            <h2>
              At PawCare, we’re guided by a set of core values that define who
              we are and how we operate.
            </h2>
            <div className="about-page-container-cards flex-row flex-wrap">
              {cards.map((card) => (
                <div
                  key={card.id}
                  className="about-page-container-cards-card flex-column justify-between"
                >
                  <h3>{card.id}</h3>
                  <div>
                    <h4>{card.title}</h4>
                    <p>{card.text}</p>
                  </div>
                </div>
              ))}
            </div>
            <h2>Meet the team</h2>
            <div className="mt-20 flex-row team-section">
              <div
                className="kunal"
                style={{ backgroundImage: `url(${Kunal})` }}
              />
              <div className="team-section-content">
                <span>
                  At PawCare, we’re proud to have a team of dedicated and
                  passionate individuals who are committed to providing the best
                  possible service to our customers and their pets.
                </span>
                <h4>Kunal Chopra</h4>
                <p>CEO</p>
              </div>
            </div>
            <img src={Team} alt="team" className="w-100 mt-50" />
            <div className="struck-section flex-column">
              <p>Founded in partnership with</p>
              <img src={StruckLogo} alt="struck-logo" className="struck-logo" />
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}
