import { useDispatch, useSelector } from 'react-redux';

import { adminSignUp, signupDataValidate } from 'actions/authentication';
import Button from 'atoms/button';
import Checkbox from 'atoms/checkbox';
import Dropdown from 'atoms/dropdown';
import Input from 'atoms/input';
import toast from 'atoms/toast';
import { clearForm, editForm } from 'store/reducers/signupForm';

export default function SignupDetails() {
  const dispatch = useDispatch();
  const { isLoadingValidation } = useSelector((state) => state.signupForm);
  const { signupLoading } = useSelector((state) => state.authentication);
  const { isAdmin } = useSelector((state) => state.user.data);
  const { data } = useSelector((state) => state.signupForm);
  const countryIdOptions = useSelector((state) => state.main.geo.countryCode);
  const {
    countryCodeId,
    email,
    password,
    firstName,
    lastName,
    phoneNumber,
    isMarketable,
  } = data;
  const canContinue =
    countryCodeId &&
    email &&
    password &&
    firstName &&
    lastName &&
    phoneNumber &&
    isMarketable &&
    !signupLoading &&
    !isLoadingValidation;

  const onSignupValidate = (e) => {
    e.preventDefault();
    dispatch(
      signupDataValidate({ ...data, countryCodeId: countryCodeId.id }),
    ).then(({ success }) => {
      if (success) {
        if (isAdmin) {
          dispatch(
            adminSignUp({ ...data, countryCodeId: countryCodeId.id }),
          ).then(({ success }) => {
            if (success) {
              toast({ text: 'Account created!' });
              dispatch(clearForm());
            }
          });
        } else {
          toast({ text: "You don't have the rights to perform this action" });
        }
      }
    });
  };

  return (
    <div className="signup">
      <div className="flex-row justify-between">
        <h1 className="mb-0 f14">Sign up</h1>
      </div>
      <form onSubmit={onSignupValidate}>
        <div className="flex-row">
          <Input
            id="firstName"
            label="First name"
            className="mr-12 w-100"
            onChange={(value) =>
              dispatch(editForm({ key: 'firstName', value }))
            }
            value={firstName}
            autoFocus
          />
          <Input
            id="lastName"
            label="Last name"
            className="w-100"
            onChange={(value) => dispatch(editForm({ key: 'lastName', value }))}
            value={lastName}
          />
        </div>
        <Input
          id="email"
          label="Email"
          type="email"
          onChange={(value) => dispatch(editForm({ key: 'email', value }))}
          value={email}
        />
        <div className="phone-container flex-row mt-32">
          <Dropdown
            id="countryCode"
            label="Phone"
            className="country-code-input"
            items={countryIdOptions}
            onClick={(value) =>
              dispatch(editForm({ key: 'countryCodeId', value }))
            }
            value={countryCodeId}
          />
          <Input
            id="phone"
            className="mt-42 w-100"
            onChange={(value) =>
              dispatch(editForm({ key: 'phoneNumber', value }))
            }
            value={phoneNumber}
            isNumber
          />
        </div>
        <Input
          id="password"
          label="Password"
          type="password"
          onChange={(value) => dispatch(editForm({ key: 'password', value }))}
          value={password}
        />
        <div className="mt-25 mb-25">
          <Checkbox
            id="consent"
            value={isMarketable}
            onClick={({ isChecked }) =>
              dispatch(editForm({ key: 'isMarketable', value: isChecked }))
            }
            variant="consent"
          >
            <span>
              I have read and accept the{' '}
              <a
                href="https://www.mypawcare.com/terms"
                target="_blank"
                rel="noreferrer"
              >
                Terms of service
              </a>{' '}
              and{' '}
              <a
                href="https://www.mypawcare.com/privacy.html"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
              .
            </span>
          </Checkbox>
        </div>
        <Button
          variant={canContinue ? 'default' : 'disabled'}
          text="Next"
          type="submit"
        />
      </form>
    </div>
  );
}
