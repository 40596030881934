import 'organisms/Landing/Landing.scss';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import useWindowDimensions from 'helpers/useWindowDimensions';

import { servicePriceCheck } from 'actions';
import { loadProviderLocation } from 'actions/providers';
import DefaultAvatar from 'atoms/default-avatar';
import InvoiceDetails from 'organisms/InvoiceDetails';
import ProgressBar from 'organisms/ProgressBar';
import ServiceViewMoreModal from 'organisms/ServiceViewMoreModal';
import { capitalizeFirstLetter, isEmpty, prettifyDate } from 'util';

export default function AppointmentInfo({
  mobileTitle,
  mobileSubtitle,
  showProgressBar,
  progressBarData,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpen, openModal] = useState();
  const { service, dateStart, location, locationUser } = useSelector(
    (state) => state.requestAppointmentForm,
  );
  const { links, name } = useSelector((state) => state.location);
  const { invoice, policy, isLoading } = useSelector((state) => state.invoice);
  const { width } = useWindowDimensions();
  const isMobile = width < 600;
  const dateText = dateStart
    ? `on ${prettifyDate(dateStart)}`
    : 'No date selected';

  useEffect(() => {
    if (!service || isEmpty(service)) return navigate('/');
    if (!name && location) dispatch(loadProviderLocation(location));
    dispatch(servicePriceCheck({ suid: service.uuid }));
  }, []);

  if (!name) return null;

  return (
    <div className="appointment-info">
      {isMobile && mobileTitle && <h1 className="f26 mb-10">{mobileTitle}</h1>}
      {isMobile && mobileSubtitle && <p className="mb-25">{mobileSubtitle}</p>}
      {isMobile && showProgressBar && (
        <ProgressBar
          maxSteps={progressBarData?.maxSteps}
          currentStep={progressBarData?.currentStep}
          className={classNames('mb-32')}
        />
      )}
      <div className="flex-column user-container w-100">
        <DefaultAvatar
          avatar={links?.image?.desktop?.large}
          size={isMobile ? 'small' : 'big'}
        />
        <h2 className={classNames('mt-15', isMobile && 'f20')}>{name}</h2>
      </div>
      <div className="appointment-info-service mt-20 w-100">
        <p className="bold f18">{service?.name}</p>
        <p className="f15 text-grey-dark date">{dateText}</p>
        {locationUser && !isEmpty(locationUser) && locationUser !== 'any' && (
          <p className="f15 text-grey-dark date">
            with {capitalizeFirstLetter(locationUser?.user?.firstName)}
          </p>
        )}
      </div>
      <InvoiceDetails invoice={invoice} policy={policy} isLoading={isLoading} />
      {isOpen && (
        <ServiceViewMoreModal
          isOpen={isOpen}
          openModal={openModal}
          service={isOpen}
        />
      )}
    </div>
  );
}
