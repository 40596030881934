/* eslint-disable no-unsafe-optional-chaining */
import { useState } from 'react';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import DefaultAvatar from 'atoms/default-avatar';
import PetSelector from 'atoms/dropdown/pet-selector';
import CreatePetDetails from 'pages/CreatePet/CreatePetDetails';
import { clearForm } from 'store/reducers/createPetForm';
import { setActivePet } from 'store/reducers/petAppointments';
import { isNotEmptyArray } from 'util';

function LoggedIn() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { uuid } = useSelector((state) => state.user.data);
  const { activePet } = useSelector((state) => state.petAppointments);
  const { data: userData } = useSelector((state) => state.user);
  const pets = userData.animals;
  const [isAddPetModalOpen, setIsAddPetModalOpen] = useState(false);

  const hasMultiplePets = isNotEmptyArray(pets) && pets.length > 1;
  const hasNoPets = !isNotEmptyArray(pets) || pets.length === 0;

  // Define the URL where you want to hide the PetSelector
  const hideSelectorUrls = [
    '/booking-select-pet',
    '/booking-select-groomer',
    '/booking-select-slot',
    '/booking-payment',
    '/signup/address',
  ];

  // Check if the current URL matches the specified  or if the user does not have multiple pets
  const shouldHideSelector =
    hideSelectorUrls.includes(location.pathname) || !hasMultiplePets;

  const handlePetSelect = (selectedItem) => {
    if (selectedItem.id === 'add-pet') {
      toggleAddPetModal();
    } else {
      dispatch(setActivePet(selectedItem));
    }
  };

  const toggleAddPetModal = () => {
    dispatch(clearForm());
    setIsAddPetModalOpen((prevState) => !prevState);
  };

  return (
    <>
      <Link
        className="navbar-avatar"
        to={hasNoPets ? '/create-pet' : `/user/${uuid}/pet/${activePet?.uuid}`}
      >
        <DefaultAvatar
          size="small"
          avatar={activePet?.avatarUrls?.mobile?.small}
        />
      </Link>
      {!shouldHideSelector && (
        <PetSelector
          size="small"
          onClick={handlePetSelect}
          disableAddPetAction
        />
      )}
      {isAddPetModalOpen && (
        <ReactModal
          ariaHideApp={false}
          isOpen={isAddPetModalOpen}
          onRequestClose={() => toggleAddPetModal(false)}
        >
          <CreatePetDetails />
        </ReactModal>
      )}
    </>
  );
}

export default LoggedIn;
