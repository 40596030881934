import { Helmet } from 'react-helmet-async';

import Landing from 'organisms/Landing';

import AdditionalPetDetailsInfo from './AdditionalPetDetailsInfo';

export default function AdditionalPetDetails() {
  return (
    <>
      <Helmet title="Create pet | PawCare">
        <link
          rel="canonical"
          href="https://mypawcare.com/signup/additional-pet-details"
        />
      </Helmet>
      <Landing>
        <AdditionalPetDetailsInfo />
      </Landing>
    </>
  );
}
