import { createSlice } from '@reduxjs/toolkit';

const adminUser = createSlice({
  name: 'adminUser',
  initialState: {
    name: null,
    token: null,
    uuid: null,
  },
  reducers: {
    setAdminData(state, { payload }) {
      return payload;
    },
  },
});

const { actions, reducer } = adminUser;

export const { setAdminData } = actions;

export default reducer;
