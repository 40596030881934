import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { servicePriceCheck } from 'actions';
import Input from 'atoms/input';
import { promotionCode } from 'store/reducers/invoice';
import { requestFulfilled, requestOngoing } from 'store/reducers/ui';

export default function AddDiscount() {
  const { service } = useSelector((state) => state.requestAppointmentForm);
  const dispatch = useDispatch();
  const [displayDiscountField, toggleDiscountField] = useState();
  const [discountCode, setDiscountCode] = useState();
  const [successMessage, setSuccessMessage] = useState();

  const onApplyDiscount = async () => {
    dispatch(requestOngoing());
    const discountApplied = await dispatch(
      servicePriceCheck({ discount: discountCode, suid: service.uuid }),
    );
    if (discountApplied.success) {
      dispatch(promotionCode(discountCode));
      setSuccessMessage('Your promo code has been applied!');
    }
    dispatch(requestFulfilled());
  };

  return (
    <div>
      {!displayDiscountField ? (
        <button
          onClick={() => toggleDiscountField(true)}
          className="b-transparent border-none mt-15 fw-100 text-green pointer"
        >
          Add discount
        </button>
      ) : (
        <>
          <Input
            id="discount"
            className="mt-32"
            label="Discount code"
            onKeyDown={onApplyDiscount}
            onChange={(val) => {
              setDiscountCode(val);
              setSuccessMessage();
            }}
            autoFocus
            value={discountCode}
            displaySubmitButton={discountCode}
            submitButtonProps={{ text: 'Apply' }}
          />
          {successMessage && (
            <p className="text-green mt-10 f11">{successMessage}</p>
          )}
        </>
      )}
    </div>
  );
}
