import './RescheduleReviewModal.scss';

import { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import dayjs from 'dayjs';

import Button from 'atoms/button';
import RadioButtonGroup from 'atoms/radio-button-group';
import { isEmpty } from 'util';

export default function RescheduleReviewModal({
  isOpen,
  onClose,
  appointment,
  selectedSuggestedTime,
  onSubmitReviewedTime,
}) {
  const [selectedTime, setSelectedTime] = useState('');
  const [hasSetEmailValue, setHasSetEmailValue] = useState(false);

  const timeOptions = appointment.suggested?.[0]?.times?.map((time) => ({
    label: dayjs(time).format('dddd, MMMM D, h:mm a'),
    value: time,
  }));

  timeOptions.push({ label: 'Cancel appointment', value: 'cancel' });

  useEffect(() => {
    if (selectedSuggestedTime && !hasSetEmailValue) {
      setSelectedTime(selectedSuggestedTime);
      setHasSetEmailValue(true);
    }
  }, [timeOptions]);

  const handleSubmit = () => {
    onSubmitReviewedTime(selectedTime);
  };

  if (isEmpty(appointment)) return;

  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      className="reschedule-review-modal"
    >
      <div className="flex flex-column align-center">
        <h2 className="f26 mb-12">Suggested times</h2>
        <p className="f15 mb-32 text-center">
          {appointment.location.name} needs to reschedule, please select one of
          the time slots below.
        </p>
        <div className="mb-20">
          <RadioButtonGroup
            options={timeOptions}
            value={selectedTime}
            setValue={setSelectedTime}
          />
        </div>
        <Button
          className="align-self-end"
          onClick={handleSubmit}
          text="Confirm"
        />
      </div>
    </ReactModal>
  );
}
