import './ProviderSlots.scss';

import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import { appointmentFinder } from 'actions/appointments';
import Loader from 'atoms/loader';
import Slot from 'atoms/slot';
import { UserDetails } from 'organisms/UserDetails';
import { editForm } from 'store/reducers/requestAppointmentForm';
import { addDays } from 'util';

// today
const dateStart = dayjs().format('YYYY-MM-DD');
// 90 days from now
const dateEnd = dayjs(addDays(dateStart, 90)).format('YYYY-MM-DD');

export function ProviderSlots() {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    providers: [],
    calendar: [],
  });
  const [isLoading, setIsLoading] = useState();

  useEffect(() => {
    setIsLoading(true);
    dispatch(
      appointmentFinder({ dateStart, dateEnd, includeAllProviders: true }, [
        null,
        null,
        null,
      ]),
    ).then(({ success, data }) => {
      setIsLoading(false);
      if (success) {
        setState({
          providers: data.data.provider.location,
          calendar: data.data.calendar,
        });
      }
    });
  }, []);

  const onRequestAppointment = (data) => {
    if (!data) return;
    const { date, time } = data.slot;
    dispatch(editForm({ key: 'provider', value: data.provider }));
    dispatch(editForm({ key: 'dateStart', value: `${date}T${time}` }));
    dispatch(editForm({ key: 'location', value: data.provider.uuid }));
    navigate(`/chicago/p/${data.provider.slug}`);
  };

  const providerData = state.providers?.filter(
    (loc) => loc.uuid === params.luid,
  )[0];
  const datesWithAvailability = state.calendar?.filter(
    (el) => el.provider.location.uuid === params.luid,
  );

  if (isLoading) {
    return (
      <div className="provider-slots flex-column">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href={`https://mypawcare.com${location.pathname}`}
        />
      </Helmet>
      <div className="provider-slots flex-column">
        <UserDetails item={providerData} className="mb-15" />
        {datesWithAvailability.map((el) => (
          <div className="flex-column mb-15">
            <h2>{dayjs(el.date).format('MMMM DD')}</h2>
            <div>
              {el.time.map((time) => (
                <Slot
                  id={`${el.date}-${time}`}
                  key={Math.random() * 10000}
                  date={el.date}
                  location={providerData}
                  displayDate={false}
                  time={time}
                  mobileStyling
                  onClick={onRequestAppointment}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
