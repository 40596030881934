import './DaycareModal.scss';

import { useState } from 'react';

import ConfirmationScreen from './ConfirmationScreen';
import CreateAccount from './CreateAccount';

export default function DaycareModal({ openModal }) {
  const [accountCreated, setAccountCreated] = useState(false);

  return (
    <div className="flex-column align-center daycare-modal-container">
      {!accountCreated ? (
        <CreateAccount setAccountCreated={setAccountCreated} />
      ) : (
        <ConfirmationScreen openModal={openModal} />
      )}
    </div>
  );
}
