import { useDispatch } from 'react-redux';

import DefaultAvatar from 'atoms/default-avatar';
import Dropdown from 'atoms/dropdown';
import { setActivePet } from 'store/reducers/petAppointments';

export default function PetSelect({ activePet, pets }) {
  const dispatch = useDispatch();

  const dropdownItems = [
    ...(pets || []).map((pet) => ({
      ...pet,
      value: pet.name,
      code: (
        <div className="flex-row">
          <DefaultAvatar
            size="extra-small"
            avatar={pet.links?.image?.desktop?.large}
          />
          <span className="ml-8 f13">{pet.name}</span>
        </div>
      ),
    })),
  ];

  return (
    <Dropdown
      placeholder="Select"
      className="pet-select"
      size="small"
      shape="square"
      items={dropdownItems}
      onClick={(selectedItem) => dispatch(setActivePet(selectedItem))}
      value={activePet.name}
      renderValue={(item) => {
        if (!item) return 'Select a pet';
        return item.code;
      }}
    />
  );
}
