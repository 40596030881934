import { createSlice } from '@reduxjs/toolkit';

const advancedSearch = createSlice({
  name: 'advancedSearch',
  initialState: {
    latitude: null,
    longitude: null,
    time: null,
    userUuid: null,
    petUuid: null,
  },
  reducers: {
    editAdvancedSearch(state, { payload }) {
      const { key, value } = payload;
      state[key] = value;
    },
    clearForm() {
      return {
        postCode: null,
        userToken: null,
        userUuid: null,
        petUuid: null,
      };
    },
  },
});

const { actions, reducer } = advancedSearch;

export const { editAdvancedSearch, clearForm } = actions;

export default reducer;
