/* eslint-disable no-restricted-globals */
/* eslint-disable react/no-unused-prop-types */
import './Input.scss';

import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Button from 'atoms/button';
import Icon from 'atoms/icon';
import { prettifyNumber } from 'util';

const Input = React.forwardRef((inputProps, ref) => {
  const {
    placeholder,
    inputType = 'input',
    isMoney,
    isPercentage,
    id,
    className,
    error,
    label,
    maxLength = '',
    onPaste,
    readOnly,
    icon,
    iconColor = 'grey',
    variant,
    size,
    textAlignment = 'left',
    displaySubmitButton,
    submitButtonProps,
    autoComplete = 'one-time-code',
    ...props
  } = inputProps;

  const [inputValue, setValue] = useState('');
  const Component = inputType;

  useEffect(() => {
    let str = props.value || '';
    if (isPercentage) {
      str += '%';
    }
    setValue(str);
  }, [props.value]);

  const onChange = (e) => {
    const { value } = e.target;
    let formattedValue = value.replace(/%/g, '');

    if (isMoney || isPercentage) {
      if (isNaN(formattedValue)) return;
    }

    if (props.isNumber) {
      formattedValue = prettifyNumber(formattedValue);
    }

    if (isMoney) {
      formattedValue = formattedValue.trim().replace(/,/g, '');
    }

    if (isPercentage) {
      formattedValue += '%';
    }

    setValue(formattedValue);
    if (props.onChange) {
      props.onChange(formattedValue.trim().replace(/%/g, ''));
    }
  };

  const onKeyDown = (e) => {
    if (props.onKeyDown && e.key === 'Enter') {
      props.onKeyDown(e.target.value);
      setValue('');
    }
  };

  return (
    <div
      className={classNames(
        'input',
        className,
        `input--${variant}`,
        `input--${size}`,
      )}
    >
      {label && <p className="bold f18 mb-15 mt-0">{label}</p>}
      <div className="input-field" style={icon && { paddingLeft: 24 }}>
        {icon && (
          <Icon
            name={icon}
            className="mr-10"
            style={{ fill: `${iconColor}` }}
          />
        )}
        <div className="justify-center input-field-container">
          <span className="currency">{isMoney && '$'}</span>
          <Component
            ref={ref}
            className={classNames(
              'input-component',
              icon && 'input-component--disable-focus',
              isMoney && 'input-component--with-currency',
              textAlignment === 'center' && 'text-center',
              textAlignment === 'right' && 'text-right',
              props.disabled && 'c-secondary-purple',
            )}
            id={id}
            onChange={onChange}
            type={props.type || 'text'}
            value={inputValue}
            placeholder={placeholder}
            autoComplete={autoComplete}
            onKeyDown={onKeyDown}
            maxLength={maxLength}
            onPaste={onPaste}
            readOnly={readOnly}
            autoFocus={props.autoFocus}
            disabled={props.disabled}
          />
        </div>
        {displaySubmitButton && (
          <Button
            text={submitButtonProps?.text || undefined}
            shape={submitButtonProps?.shape || 'square'}
            size={submitButtonProps?.size || 'medium'}
            variant={submitButtonProps?.variant || 'green'}
            className="pos-absolute search-input-submit-button"
            onClick={() => props.onKeyDown(inputValue)}
          />
        )}
      </div>
    </div>
  );
});

Input.propTypes = {
  // Display dollar symbol
  isMoney: PropTypes.bool,
  // Display percentage symbol
  isPercentage: PropTypes.bool,
  // Alignment of input text. This doesn't include the dollar symbol, it always stays on the left.
  alignText: PropTypes.oneOf(['center', 'left', 'right']),
  id: PropTypes.any,
  className: PropTypes.string,
  error: PropTypes.bool,
  label: PropTypes.string,
  maxLength: PropTypes.number || PropTypes.string,
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  inputType: PropTypes.string,
  onPaste: PropTypes.func,
  placeholder: PropTypes.string,
  variant: PropTypes.oneOf(['white']),
  size: PropTypes.oneOf(['small']),
  readOnly: PropTypes.bool,
  displaySubmitButton: PropTypes.bool,
  submitButtonProps: PropTypes.shape({
    text: PropTypes.string,
    size: PropTypes.oneOf(['large', 'medium', 'small', 'extra-small']),
    shape: PropTypes.oneOf(['round', 'square']),
    variant: PropTypes.oneOf([
      'default',
      'reverse',
      'reverse-white',
      'reverse-orange',
      'reverse-disabled',
      'disabled',
      'white',
      'green',
      'green-disabled',
      'white-outlined',
      'reverse-white-green',
    ]),
  }),
  autoComplete: PropTypes.string,
};

export default Input;
