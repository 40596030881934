import { useDispatch } from 'react-redux';

import Dropdown from 'atoms/dropdown';
import { editForm } from 'store/reducers/createPetForm';

export default function SizeFilter({ core, size }) {
  const dispatch = useDispatch();

  return (
    <Dropdown
      placeholder="Select animal size"
      className="size-filter"
      size="small"
      items={core.size}
      onClick={(value) => dispatch(editForm({ key: 'size', value }))}
      value={size}
    />
  );
}
