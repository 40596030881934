/* eslint-disable camelcase */
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { loadBootstrap } from 'actions';
import { adminSignUp } from 'actions/authentication';
import { createPet } from 'actions/pet';
import { loadUser } from 'actions/user';
import Button from 'atoms/button';
import Dropdown from 'atoms/dropdown';
import Input from 'atoms/input';
import LocationSearch from 'atoms/location-search';
import ProgressBar from 'organisms/ProgressBar';
import { editAdvancedSearch } from 'store/reducers/advancedSearch';
import { setActivePet } from 'store/reducers/petAppointments';
import { editForm } from 'store/reducers/signupForm';
import { mobileGroomerId } from 'util/constants';

export default function UserDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data } = useSelector((state) => state.signupForm);
  const petForm = useSelector((state) => state.createPetForm.data);
  const { latitude, longitude, address_1, locality, region, postalCode } =
    useSelector((state) => state.advancedSearch);
  const countryIdOptions = useSelector((state) => state.main.geo.countryCode);
  const { countryCodeId, email, firstName, lastName, phoneNumber } = data;
  const { service } = useSelector((state) => state.requestAppointmentForm);
  const invalidAddress = address_1 === 'invalid_address';
  const isMobileService = service.type.id === mobileGroomerId;
  const canContinue =
    countryCodeId && email && firstName && lastName && phoneNumber;

  const onSignupValidate = async (e) => {
    e.preventDefault();

    if (!canContinue) return;

    if (isMobileService) {
      if (
        !locality ||
        !region ||
        !postalCode ||
        !latitude ||
        !longitude ||
        !address_1 ||
        address_1 === 'invalid_address'
      ) {
        return;
      }
    }

    let userDataBody = {
      firstName,
      lastName,
      email,
      phoneNumber,
      countryCodeId: countryCodeId.id,
    };

    // If they selected a mobile service, send user's address details
    if (isMobileService) {
      userDataBody = {
        ...userDataBody,
        address: {
          address: address_1,
          locality,
          region,
          postalCode,
          latitude,
          longitude,
          country_id: 238,
        },
      };
    }

    const userCreated = await dispatch(
      adminSignUp(userDataBody, null, [null, null, null]),
    );

    if (userCreated.success) {
      const { token: userToken, uuid: userUuid } = userCreated.data.data;
      sessionStorage.setItem('overrideLoginActions', true);
      localStorage.setItem('token', userToken);
      await dispatch(loadBootstrap());

      // Load user
      const userData = await dispatch(loadUser(userUuid));

      if (userData.success) {
        const petCreated = await dispatch(
          createPet(
            {
              breedId: petForm.breed.id,
              sizeId: petForm.size.id,
              typeId: petForm.type.id,
              name: petForm.name,
            },
            [null, null, null],
          ),
        );

        // Pet created successfully
        if (petCreated.success) {
          dispatch(setActivePet(petCreated.data.data));
          // Load user again to fetch pet information
          await dispatch(loadUser(userUuid));

          navigate('/signup/booking-payment');
        }
      }
    }
  };

  return (
    <div className="booking-signup">
      <div className="mobile-page-title">
        <h1 className="mb-12">Your information</h1>
        <ProgressBar maxSteps={6} currentStep={5} className="mb-32" />
      </div>
      <form onSubmit={onSignupValidate} className="h-100">
        <div className="mobile-page-container">
          <div className="flex-row">
            <Input
              id="firstName"
              label="First name"
              className="mr-12 w-100"
              onChange={(value) =>
                dispatch(editForm({ key: 'firstName', value }))
              }
              value={firstName}
              autoFocus
            />
            <Input
              id="lastName"
              label="Last name"
              className="w-100"
              onChange={(value) =>
                dispatch(editForm({ key: 'lastName', value }))
              }
              value={lastName}
            />
          </div>
          <Input
            className="mt-35"
            id="email"
            type="email"
            label="Email"
            onChange={(value) => dispatch(editForm({ key: 'email', value }))}
            value={email}
          />
          {isMobileService && (
            <LocationSearch
              label="Address for mobile service"
              editLocation={editAdvancedSearch}
              displayErrorCaption
              hasError={invalidAddress}
              defaultValue={
                invalidAddress ? `${locality}, ${region}` : address_1
              }
            />
          )}
          <div className="phone-container flex-row mt-32">
            <Dropdown
              id="countryCode"
              label="Phone"
              className="country-code-input"
              items={countryIdOptions}
              onClick={(value) =>
                dispatch(editForm({ key: 'countryCodeId', value }))
              }
              value={countryCodeId}
            />
            <Input
              id="phone"
              className="mt-42 w-100"
              onChange={(value) =>
                dispatch(editForm({ key: 'phoneNumber', value }))
              }
              value={phoneNumber}
              isNumber
            />
          </div>
        </div>
        <div className="mobile-main-action-btn">
          <Button
            text="Next"
            type="submit"
            className="mt-25"
            variant={canContinue ? 'default' : 'disabled'}
          />
        </div>
      </form>
    </div>
  );
}
