function Dog({ style }) {
  const { width = 64, height = 45 } = style;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 64 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M57.8605 6.76334L47.7749 3.42102C46.257 2.89765 44.6276 2.78593 43.0525 3.09707C44.1237 4.23287 45.0172 5.5239 45.703 6.92639L54.3903 24.5618C54.8627 25.5191 55.2356 26.5223 55.5032 27.5558C57.6823 26.9064 59.5265 25.4409 60.6512 23.465L62.8003 19.7439V19.7436C64.1466 17.4113 64.3721 14.5969 63.4144 12.0802C62.4565 9.56324 60.417 7.61081 57.8607 6.76343L57.8605 6.76334Z"
        fill="white"
      />
      <path
        d="M6.13871 6.54708C3.58235 7.39441 1.54304 9.34683 0.585304 11.864C-0.372208 14.381 -0.146429 17.1953 1.20033 19.5275L3.34827 23.2486L3.34805 23.2488C4.493 25.2328 6.35485 26.7024 8.5505 27.3549C8.81393 26.3922 9.16881 25.4568 9.61012 24.5619L18.2963 6.92646C19.018 5.44884 19.9707 4.096 21.1185 2.91862C20.4963 2.77623 19.8599 2.70483 19.2216 2.7057C18.2023 2.71049 17.1903 2.879 16.2246 3.20491L6.13871 6.54708Z"
        fill="white"
      />
      <path
        d="M52.9548 25.2687L44.2681 7.63335C41.914 2.8537 37.3275 0 31.9999 0C26.6723 0 22.0854 2.85353 19.7311 7.63335L11.045 25.2687C9.66991 28.0607 9.28825 31.2379 9.96274 34.2759C10.6372 37.3141 12.328 40.0316 14.7552 41.979C17.1825 43.9264 20.2015 44.9879 23.3137 44.9879H40.6861C43.7983 44.9879 46.8174 43.9264 49.2446 41.979C51.6719 40.0313 53.3626 37.3143 54.0371 34.2759C54.7116 31.2378 54.3299 28.0605 52.9548 25.2687ZM22.3178 20.2818C22.3178 20.5677 22.1654 20.8318 21.9178 20.9746C21.6703 21.1174 21.3655 21.1174 21.1179 20.9746C20.8704 20.8318 20.718 20.5677 20.718 20.2818V17.6421C20.718 17.3562 20.8704 17.0921 21.1179 16.9493C21.3655 16.8063 21.6703 16.8063 21.9178 16.9493C22.1654 17.0921 22.3178 17.3562 22.3178 17.6421V20.2818ZM35.9894 31.1912L33.7909 33.5136C33.3252 34.0058 32.6774 34.2845 31.9998 34.2845C31.3222 34.2845 30.6744 34.0058 30.2087 33.5136L28.0102 31.1912C26.522 29.6191 27.6366 27.0292 29.8014 27.0292H34.1981C36.363 27.0292 37.4776 29.6191 35.9894 31.1912ZM43.2816 20.2818C43.2816 20.5677 43.1292 20.8318 42.8817 20.9746C42.6341 21.1174 42.3293 21.1174 42.0818 20.9746C41.8342 20.8318 41.6818 20.5677 41.6818 20.2818V17.6421C41.6818 17.3562 41.8342 17.0921 42.0818 16.9493C42.3293 16.8063 42.6341 16.8063 42.8817 16.9493C43.1292 17.0921 43.2816 17.3562 43.2816 17.6421V20.2818Z"
        fill="white"
      />
    </svg>
  );
}

export default Dog;
