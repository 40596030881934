import './CancelAppointmentSuccess.scss';

import ReactModal from 'react-modal';

import Button from 'atoms/button';

export default function CancelAppointmentSuccess({
  isOpen,
  setIsOpen,
  onClose,
}) {
  const handleDone = () => {
    setIsOpen(false);
    onClose();
  };

  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={handleDone}
      className="cancel-success-modal"
    >
      <div className="text-center flex-column align-center">
        <h2 className="mb-15 f26">Appointment canceled</h2>
        <p className="mb-32 f15">
          Your appointment has been canceled. Please make a new appointment when
          you&apos;re ready.
        </p>
        <Button size="large" onClick={handleDone} text="Done" />
      </div>
    </ReactModal>
  );
}
