import './NotFound.scss';

import { Helmet } from 'react-helmet-async';

function NotFound() {
  return (
    <div className="not-found">
      <Helmet title="Not found | PawCare" />
      <h1>Oops! Page not found.</h1>
    </div>
  );
}

export default NotFound;
