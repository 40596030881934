import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: {
    breed: null,
    dateOfBirth: null,
    dateSterilized: null,
    gender: null,
    hairLength: null,
    name: '',
    size: null,
    isSterilized: null,
    type: null,
    vaccinations: [],
  },
  validated: false,
  validationErrors: null,
};

const createPetForm = createSlice({
  name: 'createPetForm',
  initialState,
  reducers: {
    signupDataValidateSuccess(state) {
      state.validated = true;
    },
    signupDataValidateFailure(state, { payload }) {
      state.validationErrors = payload;
    },
    editForm(state, { payload }) {
      const { key, value } = payload;
      state.data[key] = value;
    },
    initializeForm(state, { payload }) {
      state.data = payload;
    },
    clearForm(state) {
      state.data = initialState.data;
    },
  },
});

const { actions, reducer } = createPetForm;

export const {
  editForm,
  clearForm,
  initializeForm,
  signupDataValidateSuccess,
  signupDataValidateFailure,
} = actions;

export default reducer;
