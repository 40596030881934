import './BookingLoader.scss';

function BookingLoader() {
  return (
    <div className="booking-loader flex-row w-100 justify-between p-24 margin-center b-white radius-12">
      <div className="flex-row">
        <div className="avatar-loader loader-bg mr-25" />
        <div className="title-loader loader-bg" />
      </div>
      <div className="button-loader loader-bg" />
    </div>
  );
}

export default BookingLoader;
