import './Home.scss';

import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';

import BgImage from 'images/home.webp';
import { Footer } from 'organisms/Footer';

import BlogPreview from './BlogPreview';
import Booking from './Booking';
import { ProvidersList } from './ProvidersList';
import SearchByArea from './SearchByArea';
import { UtmKeywordRedirect } from './UtmKeywordRedirect';

export default function Home() {
  const navigate = useNavigate();
  const location = useLocation();

  // Deprecated route. Leave as is for now.
  useEffect(() => {
    if (location.pathname.includes('advanced-search')) {
      navigate('/');
    }
  }, []);

  return (
    <>
      <Helmet>
        <link rel="canonical" href={`${process.env.REACT_APP_DOMAIN}`} />
      </Helmet>

      <div className="home w-100">
        <div
          className="home-header"
          style={{ backgroundImage: `url(${BgImage})` }}
        >
          <div className="home-header-content w-100">
            <h1>Book top groomers near you</h1>
            <Booking />
          </div>
        </div>
        <ProvidersList />
        <BlogPreview />
        <SearchByArea />
        <Footer />
      </div>
      <UtmKeywordRedirect />
    </>
  );
}
