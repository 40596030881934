import { useMemo } from 'react';
import { LoadScript } from '@react-google-maps/api';

import { GoogleMapsContext } from './GoogleMapsContext';

export function GoogleMapsProvider({ children }) {
  const contextValue = useMemo(() => ({ isLoaded: true }), []);

  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
      libraries={['places']}
    >
      <GoogleMapsContext.Provider value={contextValue}>
        {children}
      </GoogleMapsContext.Provider>
    </LoadScript>
  );
}
