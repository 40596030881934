import './ProviderCard.scss';

import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import useWindowDimensions from 'helpers/useWindowDimensions';

import { Slots } from 'organisms/Slots';
import { UserDetails } from 'organisms/UserDetails';
import { editForm } from 'store/reducers/requestAppointmentForm';

export function ProviderCard({
  item,
  selectSlot,
  calendar,
  maxSlots,
  className,
  emptySlotAction,
  isActive,
  onViewMore,
  onSelectProvider,
  cardLink,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ref = useRef();
  const { width } = useWindowDimensions();
  const isMobile = width < 1175;

  useEffect(() => {
    if (isActive) {
      if (isMobile) {
        const offset = 290;
        const elementPosition = ref.current.getBoundingClientRect().top;
        const offsetPosition = elementPosition - offset;

        window.scrollBy({
          top: offsetPosition,
          behavior: 'smooth',
        });
      } else {
        ref.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [isActive]);

  const onClickCard = () => {
    dispatch(editForm({ key: 'dateStart', value: null }));
    onSelectProvider();
    navigate(cardLink);
  };

  return (
    <div
      className={classNames(
        'search-provider-card pointer',
        isActive && 'search-provider-card--active',
        className,
      )}
      ref={ref}
      onClick={onClickCard}
    >
      <div>
        <UserDetails item={item} />
      </div>
      <div className="search-provider-card-slots">
        <Slots
          calendar={calendar}
          item={item}
          onViewMore={onViewMore}
          selectSlot={selectSlot}
          maxSlots={maxSlots}
          emptySlotAction={emptySlotAction}
          viewMoreClassName="view-more-custom"
          stopPropagation
        />
      </div>
    </div>
  );
}
