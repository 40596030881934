import { InfoBlock } from 'atoms/info-block';

import { Details } from './Details';
import Header from './Header';

export default function ProfileInfoDesktop({ data }) {
  return (
    <InfoBlock>
      <div className="profile-info">
        <Header data={data} />
        <Details data={data} />
      </div>
    </InfoBlock>
  );
}
