import './Checkbox.scss';

import { useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export default function Checkbox({
  text,
  onClick,
  id,
  value,
  children,
  variant,
  size,
  className,
  disableInternalCheck,
  disabled,
  checkmarkColor = 'black',
  contentPosition = 'right',
}) {
  const [isChecked, check] = useState('');

  useEffect(() => {
    check(value);
  }, [value]);

  const onChange = () => {
    if (!disableInternalCheck) {
      check((current) => !current);
    }
    onClick({ isChecked: !isChecked, id, text });
  };

  return (
    <label
      htmlFor={id}
      className={classNames(
        'checkbox__container flex-row fw-100',
        className,
        disabled && 'checkbox__container--disabled',
        `checkbox__container--${size}`,
        `checkbox__container--${variant}`,
        isChecked && variant === 'block' && 'checkbox__container--checked',
      )}
    >
      {contentPosition === 'left' && (text || children)}
      <input
        type="checkbox"
        className={classNames('checkbox')}
        id={id}
        checked={isChecked}
        onChange={onChange}
        disabled={disabled}
      />
      <span
        className={classNames(
          'checkmark',
          `checkmark--${checkmarkColor}`,
          contentPosition === 'right' ? 'mr-10' : 'ml-10',
        )}
      />
      {contentPosition === 'right' && (text || children)}
    </label>
  );
}

Checkbox.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  id: PropTypes.string || PropTypes.number,
  value: PropTypes.bool,
  checkmarkColor: PropTypes.oneOf(['black', 'orange']),
  children: PropTypes.node,
  variant: PropTypes.oneOf(['consent', 'round', 'diabled', 'button', 'block']),
  size: PropTypes.oneOf(['small']),
  className: PropTypes.string,
  disableInternalCheck: PropTypes.bool,
  disabled: PropTypes.bool,
  contentPosition: PropTypes.oneOf(['left', 'right']),
};
