import '../Dropdown.scss';

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Add } from '@mui/icons-material';
import { Menu, MenuItem } from '@mui/material';
import classNames from 'classnames';
import useWindowDimensions from 'helpers/useWindowDimensions';

import DefaultAvatar from 'atoms/default-avatar';
import Icon from 'atoms/icon';
import UserPetsBottomModal from 'organisms/UserPetsBottomModal';

export default function PetSelector({
  onClick,
  className,
  size,
  disableAddPetAction,
  title,
}) {
  const location = useLocation();
  const { pathname } = location;
  const { width } = useWindowDimensions();
  const isMobile = width < 600;
  const { data: userData, token } = useSelector((state) => state.user);
  const pets = userData.animals;
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [isModalOpen, toggleModal] = useState(false);
  const isHomePage = pathname === '/';
  // Check if on homepage
  const knobDownColor = token && isHomePage ? '#fff' : '#63637B';

  const dropdownItems = pets
    ? [
        ...pets.map((pet) => ({
          ...pet,
        })),
        !disableAddPetAction
          ? {
              value: 'Add Pet',
              uuid: 'add-pet',
            }
          : null,
      ].filter(Boolean)
    : [];

  const handleClick = (event) => {
    if (isMobile) {
      toggleModal(true);
    } else {
      setMenuAnchorEl(event.currentTarget);
    }
  };

  const handleClose = (event) => {
    event?.stopPropagation();
    setMenuAnchorEl(false);
  };

  const onItemClick = (item) => () => {
    onClick(item);
    handleClose();
  };

  return (
    <>
      <button
        onClick={handleClick}
        className={classNames(
          className,
          'pet-selector pos-relative dropdown-container flex-row pointer b-transparent border-none',
        )}
      >
        {title && <h2 className="text-black">{title}</h2>}
        <Icon
          name="knobDown"
          style={{ stroke: knobDownColor }}
          className="mr-10 ml-10"
        />
      </button>

      <Menu
        className="pet-selector-menu"
        anchorEl={menuAnchorEl}
        open={!!menuAnchorEl}
        onClose={handleClose}
      >
        {dropdownItems.map((item) => (
          <MenuItem
            className={`pet-selector-menu-item pet-selector-menu-item--${size}`}
            onClick={onItemClick(item)}
            key={item.uuid || item.name}
          >
            {item.uuid === 'add-pet' ? (
              <div className="flex-row">
                <Add />
                <p>Add Pet</p>
              </div>
            ) : (
              <div className="flex-row">
                <DefaultAvatar
                  size="extra-small"
                  animal={item}
                  avatar={item.links?.image?.desktop?.large}
                />
                <p className="ml-8">{item.name}</p>
              </div>
            )}
          </MenuItem>
        ))}
      </Menu>
      <UserPetsBottomModal isOpen={isModalOpen} toggleModal={toggleModal} />
    </>
  );
}
