import './Payment.scss';

import { Helmet } from 'react-helmet-async';

import Checkout from 'organisms/Checkout';

import PaymentInfo from './PaymentInfo';

export default function Payment() {
  return (
    <>
      <Helmet title="Complete booking | PawCare" />
      <Checkout
        showProgressBar
        progressBarData={{ currentStep: 4, maxSteps: 4 }}
        hideInvoice={false}
        className="p-0"
        mobileTitle="Complete booking"
        mobileSubtitle="Your card will not be charged for the service until after your
          appointment."
      >
        <PaymentInfo />
      </Checkout>
    </>
  );
}
