/* eslint-disable prefer-destructuring */
import { createSlice } from '@reduxjs/toolkit';

const provider = createSlice({
  name: 'provider',
  initialState: {
    data: {
      services: [],
    },
    isLoading: false,
  },
  reducers: {
    providerLocationRequest(state) {
      state.isLoading = true;
    },
    providerLocationSuccess(state, { payload }) {
      state.providerUuid = payload.data.uuid;
      state.data = payload.data.locations[0];
      state.isLoading = false;
    },
    providerLocationFailure(state) {
      state.isLoading = false;
    },
  },
});

const { actions, reducer } = provider;

export const {
  providerLocationRequest,
  providerLocationSuccess,
  providerLocationFailure,
} = actions;

export default reducer;
