import { createSlice } from '@reduxjs/toolkit';

const providerCalendar = createSlice({
  name: 'providerCalendar',
  initialState: {
    isLoading: false,
    calendar: [],
  },
  reducers: {
    providerCalendarRequest(state) {
      state.isLoading = true;
    },
    providerCalendarSuccess(state, { payload }) {
      return {
        isLoading: false,
        ...payload.data,
      };
    },
    providerCalendarFailure(state) {
      state.isLoading = false;
    },
  },
});

const { actions, reducer } = providerCalendar;

export const {
  providerCalendarRequest,
  providerCalendarSuccess,
  providerCalendarFailure,
} = actions;

export default reducer;
