import { useSelector } from 'react-redux';

import AdvancedSearch from 'organisms/AdvancedSearch';
import BookingLoader from 'organisms/Loaders/BookingLoader';
import RebookBanner from 'organisms/RebookBanner';
import { isEmpty } from 'util';

/**
 * Display AdvancedSearch for users that have never booked an appointment before
 * Display RebookBanner for users that have booked an appointment before
 */
export default function Booking() {
  const { isLoading, location } = useSelector(
    (state) => state.lastUserAppointment,
  );

  if (isLoading) return <BookingLoader />;

  if (!isEmpty(location)) return <RebookBanner location={location} />;

  return <AdvancedSearch />;
}
