import { Typeahead } from 'react-bootstrap-typeahead';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'atoms/button';
import { DateInput } from 'atoms/date-input';
import Dropdown from 'atoms/dropdown';
import Input from 'atoms/input';
import { editForm } from 'store/reducers/createPetForm';
import { generateKey, isNotEmptyArray } from 'util';

import { startsWithFilter } from './util';

export default function Desktop({
  data,
  onSubmit,
  canContinue,
  breedOptions,
  hairLengthOptions,
}) {
  const dispatch = useDispatch();
  const { core } = useSelector((state) => state.main);
  const {
    breed,
    dateOfBirth,
    gender,
    hairLength,
    name,
    size,
    isSterilized,
    type,
    vaccinations,
  } = data;
  const vaccinationForAnimalType = core.vaccination.filter(
    (el) => el.animalTypeId === type?.id,
  );

  const vaccinationIdsList = vaccinations
    .map((vaccination) =>
      vaccination?.id && vaccination?.value ? vaccination.id : null,
    )
    .filter((vaccination) => !!vaccination);

  const onAddVaccine = () => {
    dispatch(editForm({ key: 'vaccinations', value: [...vaccinations, {}] }));
  };

  return (
    <>
      <h1 className="mb-0 f-14">Pet information</h1>
      <form onSubmit={onSubmit}>
        <Input
          id="name"
          label="Pet name"
          className="mt-25 w-100"
          onChange={(value) => dispatch(editForm({ key: 'name', value }))}
          value={name}
          autoFocus
        />
        <div className="flex-row align-end">
          <Dropdown
            label="Pet type"
            placeholder="Dog"
            className="w-100 mr-10 mt-32"
            items={core.type}
            onClick={(value) => {
              dispatch(editForm({ key: 'type', value }));
              dispatch(editForm({ key: 'dateOfBirth', value: null }));
              dispatch(editForm({ key: 'size', value: null }));
              dispatch(editForm({ key: 'breed', value: null }));
            }}
            value={type?.value}
          />

          <div className="w-100">
            <h3 className="mb-12">Breed</h3>
            <Typeahead
              id="breed"
              labelKey="value"
              options={breedOptions}
              placeholder="Select"
              filterBy={startsWithFilter}
              onChange={(value) =>
                dispatch(editForm({ key: 'breed', value: value[0] }))
              }
              selected={breed ? [breed] : []}
              className="w-100 input-field"
            />
          </div>
        </div>
        <div className="flex-row align-end">
          <Dropdown
            label="Size"
            placeholder="Select"
            className="w-100 mr-10 mt-32"
            items={core.size}
            onClick={(value) => dispatch(editForm({ key: 'size', value }))}
            value={size?.value}
          />
          <Dropdown
            label="Hair length"
            placeholder={
              hairLengthOptions[0]?.value ? 'Select' : 'Not applicable'
            }
            className="w-100 mt-32"
            items={hairLengthOptions}
            disableDropdown={!hairLengthOptions.length}
            onClick={(value) =>
              dispatch(editForm({ key: 'hairLength', value }))
            }
            value={hairLength?.value}
          />
        </div>
        <div className="flex-row align-end">
          <div className="flex-column w-100 mr-10">
            <h3 className="mb-15">Date of birth</h3>
            <DateInput
              placeholder="02/02/2020"
              maxDate={new Date()}
              onChange={(value) =>
                dispatch(editForm({ key: 'dateOfBirth', value }))
              }
              value={dateOfBirth}
            />
          </div>
          <Dropdown
            label="Gender"
            placeholder="Select"
            className="w-100 mr-10 mt-32"
            items={core.gender}
            onClick={(value) => dispatch(editForm({ key: 'gender', value }))}
            value={gender?.value}
          />
          <Dropdown
            label="Spay/Neuter"
            placeholder="Select"
            className="w-100 mt-32"
            items={[
              { id: true, value: 'Yes' },
              { id: false, value: 'No' },
            ]}
            onClick={({ id }) =>
              dispatch(editForm({ key: 'isSterilized', value: id }))
            }
            value={isSterilized ? 'Yes' : 'No'}
          />
        </div>
        {(type?.id === 1 || type?.id === 2) && (
          <div className="vaccination-form-container flex-column mt-32 mb-25">
            <h3 className="f26 mb-15 mt-10">Vaccinations</h3>

            {isNotEmptyArray(vaccinations) && (
              <div className="flex-row gap-12 mt-12">
                <h4 className="type-title f18 bold">Type</h4>
                <h4 className="f18 bold">Expiration date</h4>
              </div>
            )}

            {vaccinations.map((vaccination, index) => (
              <div className="flex-row gap-12 mt-12" key={generateKey()}>
                <Dropdown
                  placeholder="Vaccination"
                  className="vaccination-select"
                  items={vaccinationForAnimalType.filter(
                    (vaccinationOption) =>
                      vaccinationOption?.id === vaccination?.id ||
                      !vaccinationIdsList.includes(vaccinationOption?.id),
                  )}
                  size="medium"
                  onClick={(value) => {
                    const updatedVaccination = {
                      ...vaccinations[index],
                      ...value,
                    };
                    const vaccinationsCopy = vaccinations.slice(0);
                    vaccinationsCopy[index] = updatedVaccination;
                    return dispatch(
                      editForm({
                        key: 'vaccinations',
                        value: vaccinationsCopy,
                      }),
                    );
                  }}
                  value={
                    vaccination?.id && vaccination?.value ? vaccination : null
                  }
                />
                <DateInput
                  className="expiration-date-input"
                  placeholder="Expiration date"
                  onChange={(date) => {
                    const updatedVaccination = {
                      ...vaccinations[index],
                      expirationDate: date,
                    };
                    const vaccinationsCopy = vaccinations.slice(0);
                    vaccinationsCopy[index] = updatedVaccination;
                    return dispatch(
                      editForm({
                        key: 'vaccinations',
                        value: vaccinationsCopy,
                      }),
                    );
                  }}
                  value={
                    vaccination?.expirationDate
                      ? vaccination.expirationDate
                      : null
                  }
                  size="medium"
                />
              </div>
            ))}
            <div className="flex-row mt-12">
              <Button
                className="add-vaccine-button fw-400"
                text="Add vaccine"
                size="large"
                variant="light-gray"
                icon="add"
                iconProps={{ style: { fill: '#63637b' } }}
                iconPosition="start"
                onClick={onAddVaccine}
                type="button"
              />
            </div>
          </div>
        )}
        <Button
          variant={canContinue ? 'default' : 'disabled'}
          text="Next"
          type="submit"
          className="mt-25"
        />
      </form>
    </>
  );
}
