import './AdminSearch.scss';

import ConsumerSearch from 'organisms/UserSearch/ConsumerSearch';

function AdminSearch() {
  return (
    <div className="admin-search">
      <ConsumerSearch />
    </div>
  );
}

export default AdminSearch;
