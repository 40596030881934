import './PetProfile.scss';

import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useWindowDimensions from 'helpers/useWindowDimensions';

import { loadPetAppointments } from 'actions/pet';
import { loadUser } from 'actions/user';
import FullScreenLoader from 'atoms/full-screen-loader';
import toast from 'atoms/toast';
import AppointmentsLoader from 'organisms/Loaders/AppointmentsLoader';
import { userSetCredentials } from 'store/reducers/user';

import Actions from './components/Actions';
import Appointments from './components/Appointments';
import PetInformation from './components/PetInformation';

function PetProfile() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { width } = useWindowDimensions();
  const isMobile = width < 600;
  const { token: adminToken, uuid: adminUuid } = useSelector(
    (state) => state.adminUser,
  );
  const { data: user } = useSelector((state) => state.user);
  const { petAppointments, activePet, isLoading } = useSelector(
    (state) => state.petAppointments,
  );
  const { active = [], past = [], request = {} } = petAppointments;
  const pendingGeneralRequests = request?.general
    ? request.general.filter((el) => el.status.value === 'requested')
    : [];

  const noAppointments =
    !past.length && !active.length && !pendingGeneralRequests.length;

  useEffect(() => {
    if (params.userId !== user.uuid) {
      // we're on a different user's profile. This means we're an admin
      // and we're trying to impersonate. Load that user's data
      loadUserData();
    } else {
      dispatch(loadPetAppointments(activePet));
    }
  }, [activePet]);

  const loadUserData = async () => {
    const fetchUserData = await dispatch(loadUser(params.userId));
    if (fetchUserData.success) {
      const userHasPet = user.animals?.[0]?.uuid;
      if (userHasPet) {
        dispatch(loadPetAppointments(activePet));
      } else {
        await dispatch(
          userSetCredentials({ token: adminToken, uuid: adminUuid }),
        );
        dispatch(loadUser());
        navigate(`/user/${user.uuid}/pet`);
        toast({ text: "User doesn't have a pet yet" });
      }
    }
  };

  if (!activePet) return <FullScreenLoader />;

  return (
    <div className="flex-row w-100 pet-profile">
      <Helmet title="Pet profile | PawCare">
        <link
          rel="canonical"
          href={`${process.env.REACT_APP_DOMAIN}${location.pathname}`}
        />
      </Helmet>
      <PetInformation pet={{ animal: activePet, user }} />
      <div className="page-container pet-profile-container">
        <div className="pb-30 pet-profile-container-content">
          {isLoading ? (
            <AppointmentsLoader />
          ) : (
            <Appointments noAppointments={noAppointments} />
          )}
        </div>
      </div>
      {isMobile && <Actions />}
    </div>
  );
}

export default PetProfile;
