import './SearchLoader.scss';

import classNames from 'classnames';

function SearchLoader({ variant = 'default' }) {
  return (
    <div className="search-loader">
      {[1, 2, 3, 4, 5, 6].map((el) => (
        <div key={el} className={classNames(`row flex-row row-${variant}`)}>
          <span className="row-avatar" />
          <div className="flex-column w-100 content">
            <span className="title" />
            <span className="description mt-5" />
            <span className="description mt-5" />
          </div>
        </div>
      ))}
    </div>
  );
}

export default SearchLoader;
