import './Blog.scss';

import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';

import { getBlogPosts } from 'actions';
import { Footer } from 'organisms/Footer';
import BlogLoader from 'organisms/Loaders/BlogLoader';
import { postsList } from 'util';

export default function Blog() {
  const dispatch = useDispatch();
  const [posts, setPosts] = useState(null);

  useEffect(() => {
    dispatch(getBlogPosts()).then(({ success, data }) => {
      if (success) {
        setPosts(data.data);
      }
    });
  }, []);

  return (
    <div className="flex-column w-100">
      <Helmet title="Blog | PawCare">
        <link rel="canonical" href="https://mypawcare.com/blog" />
      </Helmet>
      <div className="blog-preview w-100">
        <h2 className="mb-25">Tips from PawCare</h2>
        <div className="blog-preview-container">
          {!posts ? <BlogLoader /> : postsList(posts.slice(0, 3))}
        </div>
        {posts?.[3] && (
          <a
            href={posts[3].url}
            target="_blank"
            className="blog-preview-container-cta flex-row justify-between"
            rel="noreferrer"
          >
            <h2>{posts[3].title}</h2>
            <div style={{ backgroundImage: `url(${posts[3].image})` }} />
          </a>
        )}
        <div className="flex-row blog-preview-second-container">
          {postsList(posts?.slice(4, 7))}
        </div>
      </div>
      <Footer />
    </div>
  );
}
