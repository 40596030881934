import { fetchData } from './fetchData';

export const loadAvailableGroomers =
  ({ luid, date, time, suid }) =>
  (dispatch, getState) => {
    const { dateStart } = getState().requestAppointmentForm;
    const dateQuery = `?date_start=${date}&time_start=${time}`;
    const serviceQuery = suid ? `&service_uuid=${suid}` : '';
    const sortQuery = dateStart ? '' : '&sort_soonest_available=false';
    return fetchData({
      endpoint: `location/${luid}/user/availability/consumer${dateQuery}${serviceQuery}${sortQuery}`,
      actions: [null, null, null],
    })(dispatch, getState);
  };
