import { useState } from 'react';
import { useSelector } from 'react-redux';

import Toggle from 'atoms/toggle';

function AdminButton({ setState, isImpersonating }) {
  const { firstName, lastName } = useSelector((state) => state.user.data);
  const [displayTooltip, setDisplayTooltip] = useState(false);

  return (
    <>
      <div
        className={`admin-nav-button admin-button admin-button--${
          isImpersonating && 'impersonating'
        } bold flex-row`}
        onMouseEnter={() => setDisplayTooltip(true)}
        onMouseLeave={() => setDisplayTooltip(false)}
      >
        <span className="mr-10">Super admin</span>
        {isImpersonating && (
          <Toggle setState={setState} enabled={isImpersonating} />
        )}
      </div>
      {isImpersonating && displayTooltip && (
        <span className="impersonation-tooltip">{`${firstName} ${lastName}`}</span>
      )}
    </>
  );
}

export default AdminButton;
