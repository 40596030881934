import { createSlice } from '@reduxjs/toolkit';

const authentication = createSlice({
  name: 'authentication',
  initialState: {
    signupLoading: false,
  },
  reducers: {
    signupRequest(state) {
      state.signupLoading = true;
    },
    signupSuccess(state, { payload }) {
      state.signupLoading = false;
    },
    signupFailure(state) {
      state.signupLoading = false;
    },
  },
});

const { actions, reducer } = authentication;

export const { signupRequest, signupSuccess, signupFailure } = actions;

export default reducer;
