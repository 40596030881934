import './SelectButton.scss';

import classNames from 'classnames';
import PropTypes from 'prop-types';

export default function SelectButton({
  children,
  onClick,
  value,
  isActive,
  className,
  size,
}) {
  return (
    <button
      className={classNames(
        'select-button flex-row align-center justify-center text-black',
        isActive && 'select-button--active',
        className,
        `select-button--${size}`,
      )}
      onClick={(e) => {
        onClick(value, e);
      }}
    >
      {children}
    </button>
  );
}

SelectButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['small']),
  value: PropTypes.any,
};
