import { createSlice } from '@reduxjs/toolkit';

const signupForm = createSlice({
  name: 'signupForm',
  initialState: {
    data: {
      countryCodeId: { code: '1', country: 'United States', id: 232 },
      email: '',
      password: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      isMarketable: false,
    },
    validated: false,
    validationErrors: null,
    isLoadingValidation: null,
  },
  reducers: {
    signupDataValidateRequest(state) {
      state.isLoadingValidation = true;
    },
    signupDataValidateSuccess(state) {
      state.validated = true;
      state.isLoadingValidation = false;
    },
    signupDataValidateFailure(state, { payload }) {
      state.validationErrors = payload;
      state.isLoadingValidation = false;
    },
    editForm(state, { payload }) {
      const { key, value } = payload;
      state.data[key] = value;
    },
    clearForm(state) {
      state.data = {
        countryCodeId: { code: '1', country: 'United States', id: 232 },
        email: '',
        password: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
        isMarketable: false,
      };
    },
  },
});

const { actions, reducer } = signupForm;

export const {
  editForm,
  clearForm,
  signupDataValidateRequest,
  signupDataValidateSuccess,
  signupDataValidateFailure,
} = actions;

export default reducer;
