import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import useWindowDimensions from 'helpers/useWindowDimensions';

import { mfaInit, mfaVerify } from 'actions/authentication';
import Button from 'atoms/button';
import Input from 'atoms/input';
import toast from 'atoms/toast';
import { clearForm } from 'store/reducers/signupForm';
import { prettifyPhoneNumber } from 'util';

export default function FinishSignupMFAInfo() {
  const itemsRef = useRef([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const signupFormData = useSelector((state) => state.signupForm.data);
  const { width } = useWindowDimensions();
  const isMobile = width < 600;
  const { countryCodeId, phoneNumber } = signupFormData;
  const [code, setCode] = useState(['', '', '', '', '', '']);
  const [pastedFocusIndex, setPastedFocusIndex] = useState(null);

  const onResendCode = async (e) => {
    e.preventDefault();
    const res = await dispatch(
      mfaInit({ countryCodeId: countryCodeId.id, phoneNumber }),
    );
    if (res.success) {
      toast({
        text: `Verification code resent to
      ${prettifyPhoneNumber(phoneNumber, countryCodeId?.code)}.
    `,
      });
    }
  };

  const onPhoneValidation = async (e) => {
    e.preventDefault();

    const verified = await dispatch(
      mfaVerify({
        code: code.join(''),
        countryCodeId: countryCodeId.id,
        phoneNumber,
      }),
    );

    if (verified.success) {
      dispatch(clearForm());
      navigate('/');
    }
  };

  const onChange = (digit, index) => {
    const isEmptyDigit = digit === '';
    const updatedCode = code.map((el, i) => {
      if (i === index) {
        return digit;
      }
      return el;
    });
    setCode(updatedCode);
    if (index === 5 && isEmptyDigit) {
      itemsRef.current?.[index - 1]?.focus();
    }
    if (index <= 4) {
      const focusIndex =
        pastedFocusIndex || (isEmptyDigit ? index - 1 : index + 1);
      itemsRef.current?.[focusIndex]?.focus();
      setPastedFocusIndex(null);
    }
  };

  const onPaste = (event) => {
    const copiedCode = event.clipboardData.getData('text');
    const updatedCode = copiedCode.split('').splice(0, 6);
    setPastedFocusIndex(updatedCode.length);
    setCode(updatedCode);
  };

  return (
    <div className="mfa">
      <div className="mobile-page-title">
        <h1>Phone verification</h1>
      </div>
      <form onSubmit={onPhoneValidation} className="h-100">
        <div className="mobile-page-container">
          <p className="bold mb-15">Verification code</p>
          <div className="flex-row w-100 justify-between mfa-code">
            <Input
              onPaste={onPaste}
              type={isMobile ? 'number' : 'text'}
              ref={(ref) => itemsRef.current.push(ref)}
              id="digit-0"
              maxLength="1"
              onChange={(value) => onChange(value, 0)}
              value={code[0]}
            />
            <Input
              onPaste={onPaste}
              type={isMobile ? 'number' : 'text'}
              ref={(ref) => itemsRef.current.push(ref)}
              id="digit-1"
              maxLength="1"
              onChange={(value) => onChange(value, 1)}
              value={code[1]}
            />
            <Input
              onPaste={onPaste}
              type={isMobile ? 'number' : 'text'}
              ref={(ref) => itemsRef.current.push(ref)}
              id="digit-2"
              maxLength="1"
              onChange={(value) => onChange(value, 2)}
              value={code[2]}
            />
            <div className="separator text-center">-</div>
            <Input
              onPaste={onPaste}
              type={isMobile ? 'number' : 'text'}
              ref={(ref) => itemsRef.current.push(ref)}
              id="digit-3"
              maxLength="1"
              onChange={(value) => onChange(value, 3)}
              value={code[3]}
            />
            <Input
              onPaste={onPaste}
              type={isMobile ? 'number' : 'text'}
              ref={(ref) => itemsRef.current.push(ref)}
              id="digit-4"
              maxLength="1"
              onChange={(value) => onChange(value, 4)}
              value={code[4]}
            />
            <Input
              onPaste={onPaste}
              type={isMobile ? 'number' : 'text'}
              ref={(ref) => itemsRef.current.push(ref)}
              id="digit-5"
              maxLength="1"
              onChange={(value) => onChange(value, 5)}
              value={code[5]}
            />
          </div>
          <div className="mt-25">
            We’ve sent a text message to{' '}
            {prettifyPhoneNumber(phoneNumber, countryCodeId?.code)}. You have 10
            minutes to enter the code. Having trouble?{' '}
            <button
              onClick={onResendCode}
              className="text-orange pointer no-style-btn f16"
            >
              Resend code
            </button>
            .
          </div>
        </div>
        <div className="mobile-main-action-btn">
          <Button
            className="mt-35 mb-20"
            text="Next"
            type="submit"
            variant={code.includes('') ? 'disabled' : 'default'}
          />
        </div>
      </form>
    </div>
  );
}
