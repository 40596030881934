import { Typeahead } from 'react-bootstrap-typeahead';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { editPet, loadPetAppointments } from 'actions/pet';
import { loadUser } from 'actions/user';
import Button from 'atoms/button';
import { DateInput } from 'atoms/date-input';
import Dropdown from 'atoms/dropdown';
import Input from 'atoms/input';
import { editForm } from 'store/reducers/createPetForm';
import { generateKey, isNotEmptyArray } from 'util';

export default function EditPetModal({ petId, openModal }) {
  const dispatch = useDispatch();
  const params = useParams();
  const { data } = useSelector((state) => state.createPetForm);
  const { core } = useSelector((state) => state.main);
  const {
    breed,
    dateOfBirth,
    gender,
    hairLength,
    name,
    size,
    isSterilized,
    type,
    vaccinations,
  } = data;

  const breedOptions = core.breed.filter((el) => el.animalTypeId === type?.id);
  const hairLengthOptions = core.hair.length.filter(
    (el) => el.animalTypeId === type?.id,
  );
  const vaccinationForAnimalType = core.vaccination.filter(
    (el) => el.animalTypeId === type?.id,
  );

  const vaccinationIdsList = vaccinations
    .map((vaccination) =>
      vaccination?.id && vaccination?.value ? vaccination.id : null,
    )
    .filter((vaccination) => !!vaccination);

  const onAddVaccine = () => {
    dispatch(editForm({ key: 'vaccinations', value: [...vaccinations, {}] }));
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const body = {
      name,
      dateOfBirth,
      breedId: breed?.id,
      genderId: gender?.id,
      hairLengthId: hairLength?.id,
      sizeId: size?.id,
      isSterilized: isSterilized?.id,
      typeId: type?.id,
      vaccinations,
    };

    dispatch(editPet(body, petId)).then(({ success }) => {
      if (success) {
        openModal(false);
        dispatch(loadUser(params.userId));
        dispatch(loadPetAppointments());
      }
    });
  };
  function startsWithFilter(option, props) {
    const optionWords = option[props.labelKey].toLowerCase().split(/\s+/); // Split option label into words
    const inputWords = props.text.toLowerCase().split(/\s+/); // Split user input into words

    return inputWords.every((inputWord) =>
      optionWords.some((optionWord) => optionWord.startsWith(inputWord)),
    );
  }
  return (
    <div className="edit-pet-modal">
      <h1 className="mb-25">Edit pet information</h1>
      <form onSubmit={onSubmit}>
        <Input
          id="name"
          label="Pet name"
          className="mr-12 w-100 mb-25"
          onChange={(value) => dispatch(editForm({ key: 'name', value }))}
          value={name}
          autoFocus
        />
        <div className="flex-row align-end mb-25 responsive">
          <Dropdown
            label="Pet type"
            placeholder="Select"
            className="w-100 mr-10"
            items={core.type}
            onClick={(value) => {
              dispatch(editForm({ key: 'type', value }));
              dispatch(editForm({ key: 'dateOfBirth', value: null }));
              dispatch(editForm({ key: 'size', value: null }));
              dispatch(editForm({ key: 'breed', value: null }));
            }}
            value={type?.value}
          />

          <Typeahead
            id="breed"
            labelKey="value"
            options={breedOptions}
            placeholder="Select"
            filterBy={startsWithFilter}
            onChange={(value) =>
              dispatch(editForm({ key: 'breed', value: value[0] }))
            }
            selected={breed ? [breed] : []}
            className="w-100 input-field"
          />
        </div>
        <div className="flex-row align-end mb-25 responsive">
          <Dropdown
            label="Size"
            placeholder="Select"
            className="w-100 mr-10"
            items={core.size}
            onClick={(value) => dispatch(editForm({ key: 'size', value }))}
            value={size?.value}
          />
          <Dropdown
            label="Hair length"
            placeholder={
              hairLengthOptions[0]?.value ? 'Select' : 'Not applicable'
            }
            className="w-100"
            items={hairLengthOptions}
            disableDropdown={!hairLengthOptions.length}
            onClick={(value) =>
              dispatch(editForm({ key: 'hairLength', value }))
            }
            value={hairLength?.value}
          />
        </div>
        <div className="flex-row align-end mb-25 responsive">
          <div className="flex-column w-100 mr-10">
            <h3 className="mb-15">Date of birth</h3>
            <DateInput
              placeholder="02/02/2020"
              maxDate={new Date()}
              onChange={(value) =>
                dispatch(editForm({ key: 'dateOfBirth', value }))
              }
              value={dateOfBirth}
              variant="grey"
            />
          </div>
          <Dropdown
            label="Gender"
            placeholder="Select"
            className="w-100 mr-10"
            items={core.gender}
            onClick={(value) => dispatch(editForm({ key: 'gender', value }))}
            value={gender?.value}
          />
          <Dropdown
            label="Spay/Neuter"
            placeholder="Select"
            className="w-100"
            items={[
              { id: true, value: 'Yes' },
              { id: false, value: 'No' },
            ]}
            onClick={(value) =>
              dispatch(editForm({ key: 'isSterilized', value }))
            }
            value={isSterilized?.value}
          />
        </div>
        {(type?.id === 1 || type?.id === 2) && (
          <div className="flex-column mt-32 mb-25">
            <h3 className="f26 mb-15 mt-10">Vaccinations</h3>

            {isNotEmptyArray(vaccinations) && (
              <div className="flex-row gap-12 mt-12">
                <h4 className="type-title f18 bold">Type</h4>
                <h4 className="f18 bold">Expiration date</h4>
              </div>
            )}

            {vaccinations.map((vaccination, index) => (
              <div className="flex-row gap-12 mt-12" key={generateKey()}>
                <Dropdown
                  placeholder="Vaccination"
                  className="vaccination-select"
                  items={vaccinationForAnimalType.filter(
                    (vaccinationOption) =>
                      vaccinationOption?.id === vaccination?.id ||
                      !vaccinationIdsList.includes(vaccinationOption?.id),
                  )}
                  size="medium"
                  onClick={(value) => {
                    const updatedVaccination = {
                      ...vaccinations[index],
                      ...value,
                    };
                    const vaccinationsCopy = vaccinations.slice(0);
                    vaccinationsCopy[index] = updatedVaccination;
                    return dispatch(
                      editForm({
                        key: 'vaccinations',
                        value: vaccinationsCopy,
                      }),
                    );
                  }}
                  value={
                    vaccination?.id && vaccination?.value ? vaccination : null
                  }
                />
                <DateInput
                  className="expiration-date-input"
                  placeholder="Expiration date"
                  onChange={(date) => {
                    const updatedVaccination = {
                      ...vaccinations[index],
                      expirationDate: date,
                    };
                    const vaccinationsCopy = vaccinations.slice(0);
                    vaccinationsCopy[index] = updatedVaccination;
                    return dispatch(
                      editForm({
                        key: 'vaccinations',
                        value: vaccinationsCopy,
                      }),
                    );
                  }}
                  value={
                    vaccination?.expirationDate
                      ? vaccination.expirationDate
                      : null
                  }
                  size="medium"
                />
              </div>
            ))}
            <div className="flex-row mt-12">
              <Button
                className="add-vaccine-button fw-400"
                text="Add vaccine"
                size="large"
                variant="light-gray"
                icon="add"
                iconProps={{ style: { fill: '#63637b' } }}
                iconPosition="start"
                onClick={onAddVaccine}
                type="button"
              />
            </div>
          </div>
        )}
        <Button
          variant="default"
          text="Update"
          type="submit"
          className="mt-25"
        />
      </form>
    </div>
  );
}
