const supportedIconNames = {
  add: require('./Add'),
  'arrow-down': require('./KnobDown'),
  'apple-store': require('./AppleStore'),
  amex: require('./Amex'),
  arrowLeft: require('./ArrowLeft'),
  bin: require('./Bin'),
  burger: require('./Burger'),
  camera: require('./Camera'),
  calendar: require('./Calendar'),
  calendarFilled: require('./CalendarFilled'),
  cat: require('./Cat'),
  card: require('./Card'),
  clock: require('./Clock'),
  close: require('./Close'),
  email: require('./Email'),
  exotic: require('./Exotic'),
  filter: require('./Filter'),
  'google-play': require('./GooglePlay'),
  knobDown: require('./KnobDown'),
  knobRight: require('./KnobRight'),
  largeAnimal: require('./LargeAnimal'),
  lock: require('./Lock'),
  account: require('./Account'),
  neutered: require('./Neutered'),
  'map-drop': require('./MapDrop'),
  pawcare: require('./PawCare'),
  paw: require('./Paw'),
  pawFilled: require('./PawFilled'),
  pawFilledReverse: require('./PawFilledReverse'),
  pawcareEmpty: require('./PawCareEmpty'),
  pencil: require('./Pencil'),
  phone: require('./Phone'),
  pocketPet: require('./PocketPet'),
  search: require('./Search'),
  user: require('./User'),
  upload: require('./Upload'),
  'vertical-dots': require('./VerticalDots'),
  dog: require('./Dog'),
  discover: require('./Discover'),
  elo: require('./Elo'),
  hiper: require('./Hiper'),
  maestro: require('./Maestro'),
  mastercard: require('./Mastercard'),
  mir: require('./Mir'),
  paypal: require('./Paypal'),
  unionpay: require('./Unionpay'),
  visa: require('./Visa'),
  world: require('./World'),
};

export default supportedIconNames;
