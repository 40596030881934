import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { resetPassword, updatePassword } from 'actions/authentication';
import Button from 'atoms/button';
import Input from 'atoms/input';
import toast from 'atoms/toast';

export default function UpdatePassword({ onSuccess }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [confirmPassword, setConfirmPassword] = useState('');
  const [password, setPassword] = useState('');
  const data = useSelector((state) => state.user.data);
  const uuid = data?.uuid;

  const onChangePassword = (e) => {
    e.preventDefault();
    dispatch(updatePassword(uuid, { confirmPassword, password })).then(
      ({ success }) => {
        if (success) {
          toast({ text: 'Password changed successfully' });
          onSuccess();
          if (process.env.REACT_APP_MODE !== 'development') {
            return window.location.assign('https://mypawcare.com/link');
          }
        }
      },
    );
  };

  return (
    <div className="login">
      <h1>Reset password</h1>
      <form onSubmit={onChangePassword}>
        <Input
          id="password"
          label="New password"
          type="password"
          onChange={(value) => setPassword(value)}
          value={password}
        />
        <div className="mt-35">
          <Input
            id="confirmPassword"
            label="Confirmed password"
            type="password"
            onChange={(value) => setConfirmPassword(value)}
            value={confirmPassword}
          />
        </div>

        <Button className="mt-25" type="submit" text="Update" />
      </form>
    </div>
  );
}
