import './PaymentMethodModal.scss';

import ReactModal from 'react-modal';
import { useDispatch } from 'react-redux';

import Icon from 'atoms/icon';
import toast from 'atoms/toast';
import AddNewCard from 'organisms/AddNewCard';
import { requestFulfilled } from 'store/reducers/ui';

export default function PaymentMethodModal({
  isOpen,
  openModal,
  modalTitle = 'Add Payment',
}) {
  const dispatch = useDispatch();

  const onAddCard = () => {
    openModal(false);
    dispatch(requestFulfilled());
    toast({ text: 'Card added successfully' });
  };

  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isOpen}
      className="mw-450 payment-method-modal"
      onRequestClose={() => openModal(false)}
    >
      <div className="flex-column">
        <div className="flex-row justify-between align-center mb-30">
          <h2 className="payment-method-modal-title">{modalTitle}</h2>
          <Icon
            name="close"
            style={{ fill: 'black' }}
            onClick={() => openModal(false)}
            className="pointer p-10"
          />
        </div>
        <AddNewCard
          primaryButtonText="Save"
          onSubmit={onAddCard}
          canSubmit
          hideCopy
          hideDiscount
          label={false}
          hasSignedWaiver
        />
      </div>
    </ReactModal>
  );
}
