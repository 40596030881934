import { createSlice } from '@reduxjs/toolkit';

const invoice = createSlice({
  name: 'invoice',
  initialState: {
    isLoading: null,
    promotionCode: null,
    isPromoBannerOpen: false,
    promoCodeData: null,
    promoCodeLoading: false,
    promoCodeError: null,
    promoBannerText: null, // Holds banner text for promo code
  },
  reducers: {
    invoiceRequest(state) {
      state.isLoading = true;
    },
    invoiceSuccess(state, { payload }) {
      return {
        ...state,
        ...payload.data,
        isLoading: false,
      };
    },
    invoiceFailure(state, { payload }) {
      // If invalid, remove promo code, originally fetched from URL.
      if (payload.error === 'promotion_code does not exist') {
        sessionStorage.removeItem('promoCode');
        state.isPromoBannerOpen = false;
        state.promotionCode = null;
      }
      state.isLoading = false;
    },
    promotionCode(state, { payload }) {
      state.promotionCode = payload;
    },
    displayPromoBanner(state, { payload }) {
      state.isPromoBannerOpen = payload;
    },
    promoCodeRequest(state) {
      state.promoCodeLoading = true;
      state.promoCodeError = null;
    },
    promoCodeSuccess(state, { payload }) {
      state.promoCodeData = payload;
      state.promoBannerText = payload?.banner_copy;
      state.promoCodeLoading = false;
      state.isPromoBannerOpen = true;
    },
    promoCodeFailure(state, { payload }) {
      const errorMessage =
        payload.detail?.length > 0
          ? payload.detail[0].banner_copy
          : 'An unknown error occurred';

      state.promoCodeError = errorMessage;
      state.promoCodeLoading = false;
    },
  },
});

const { actions, reducer } = invoice;

export const {
  invoiceRequest,
  invoiceSuccess,
  invoiceFailure,
  promotionCode,
  displayPromoBanner,
  promoCodeRequest,
  promoCodeSuccess,
  promoCodeFailure,
} = actions;

export default reducer;
